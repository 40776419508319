import { HeroBlock } from "./blocks/hero-block";
import { AboutUsBlock } from "./blocks/about-us-block";
import { HowItWorksBlock } from "./blocks/how-it-works-block";
import { EarthBlock } from "./blocks/earth-block";
import { AboutTheCompetitionBlock } from "./blocks/about-the-competition-block";
import { useScrollingType } from "../../hooks/use-scrolling-type";
import { BlockIndicator, useVisibleBlock } from "../general/block-indicator";
import { Block, BlockData, BlockType } from "../../types/block";
import { MoonBlock } from "./blocks/moon-block";
import { VirtualCompetitionBlock } from "./blocks/virtual-competition-block";
import { Component, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import starPng from "../../assets/starTexture2.jpg";
import { useFallingStars } from "../../hooks/use-falling-stars";
import { sendBackendRequest } from "../../services/backend";
import { LoadingIcon, useLanguage, useModal } from "@litbase/alexandria";
import { CompetitionsBlock } from "./blocks/competitions-block";
import { useLocation, useParams } from "react-router-dom";
import { RegistrationModal } from "./registration-modal";
import { PasswordResetModal } from "./auth/password-reset-modal";
import { Popup } from "../general/popup";

export function LandingPage() {
  const { showModal } = useModal();
  const params = useParams();
  const location = useLocation();
  useScrollingType("snapping");

  const lang = useLanguage();

  const [page, setPage] = useState(null);
  useEffect(() => {
    fetchLandingPage().then((page) => {
      setPage(page);
      if (location.hash.includes("#versenyek")) {
        document.querySelector("#versenyek")?.scrollIntoView();
      }
    });
  }, [lang]);

  useEffect(() => {
    if (location.pathname.startsWith("/user/registrationpassword/")) {
      showModal(<RegistrationModal pathname={location.pathname} />);
    } else if (location.pathname.startsWith("/user/reset/")) {
      showModal(<PasswordResetModal pathname={location.pathname} />);
    }
  }, [params, location.pathname]);

  if (!page) {
    return <LoadingIcon />;
  }

  const blocks = page.blocks;

  return (
    <>
      <Popup />
      <BlockIndicator blocks={blocks} />
      <FallingStars />
      {blocks.map((block, index) => (
        <BlockRenderer key={block.id} block={block} blockIndex={index} />
      ))}
    </>
  );
}

function FallingStars() {
  const [visible, setVisible] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  useFallingStars(ref, visible);
  const [index] = useVisibleBlock();
  useEffect(() => {
    setVisible(false);
    setTimeout(() => setVisible(true), 500);
  }, [index]);
  if (index > 4 || !visible) return null;
  return <FallingStarsBody style={{ opacity: index > 4 ? 0 : 1 }} ref={ref} />;
}

const FallingStarsBody = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
`;

function BlockRenderer({
  block,
  blockIndex,
}: {
  block: Block;
  blockIndex: number;
}) {
  const Component = BlockComponents[block.type];
  return <Component blockIndex={blockIndex} block={block} />;
}

const BlockComponents: Record<
  BlockType,
  (props: { blockIndex: number; block: BlockData<any> }) => JSX.Element
> = {
  [BlockType.hero]: HeroBlock,
  [BlockType.aboutUs]: AboutUsBlock,
  [BlockType.howItWorks]: HowItWorksBlock,
  [BlockType.aboutCompetition]: AboutTheCompetitionBlock,
  [BlockType.events]: CompetitionsBlock,
  [BlockType.earth]: EarthBlock,
  [BlockType.moon]: MoonBlock,
  [BlockType.virtualCompetition]: VirtualCompetitionBlock,
};

const Stars = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  // background-image: url("${starPng}");
  background-size: 150%;
  overflow: hidden;
  pointer-events: none;
`;

interface PageResponse {
  type: string;
  id: string;
  links: {
    self: {
      href: string;
    };
  };
  attributes: {
    title: string;
  };
  relationships: {
    field_tartalom_paragrafusok: {
      data: { type: string; id: string }[];
    };
  };
}

interface PagesResponse {
  data: PageResponse[];
}

interface SinglePageResponse<T extends BlockType = BlockType> {
  data: PageResponse;
  included: {
    type: T;
    id: string;
    attributes: BlockData<T>;
  }[];
}

async function fetchLandingPage() {
  const pagesResponse = await sendBackendRequest(
    "/jsonapi/node/page"
  ).json<PagesResponse>();
  const landingPageInfo = pagesResponse.data.find((elem) =>
    [
      "Ez lesz a kezdőoldal végleges",
      "Ez lesz a kezdőoldal végleges angol",
    ].includes(elem.attributes.title)
  );
  if (!landingPageInfo) {
    throw new Error(
      "Nem létezik oldal 'Ez lesz a kezdőoldal végleges' címmel!"
    );
  }
  const landingPageResponse = await sendBackendRequest(
    `/jsonapi/node/page/${landingPageInfo.id}?include=field_tartalom_paragrafusok`
  ).json<SinglePageResponse>();
  const page = landingPageResponse.data;
  return {
    ...page,
    blocks: page.relationships.field_tartalom_paragrafusok.data.map(
      (relationship) => ({
        ...relationship,
        ...landingPageResponse.included.find(
          (elem) =>
            elem.type === relationship.type && elem.id === relationship.id
        ),
      })
    ),
  };
}
