import React from "react";
import ReactDOM from "react-dom";
import "./replace-children-polyfill";
import "./index.css";
import App from "./App";
import { loadTranslations } from "./utils/translations";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
