import { Card, CardTitle } from "./common-styles";
import styled from "styled-components";
import {
  Column,
  FieldType,
  ListPage,
  ModelConfig,
  Resource,
  Table,
} from "@litbase/admin";
import { Share, X } from "@styled-icons/boxicons-regular";
import {
  Card as TableBackground,
  Cell,
  down,
  GridRow,
  HeadCell,
  spacing1,
  spacing2,
  spacing4,
  spacing64,
  T,
} from "@litbase/alexandria";
import { formatDuration } from "../../../utils/format-duration";
import { fetchResource, useResources } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import { currentUser$ } from "../../../hooks/use-user";
import { Training } from "../../../types/training";
import { verticalDown } from "../../../styles/theme-types";
import { ActivityType } from "../../../types/activity-type";
import { getImageUrl } from "../../../utils/get-image-url";
import { round } from "lodash-es";
import { getEndsAt, getStartsAt } from "../../../types/competition";
import { format } from "date-fns";

function MyTrainingsComponent({ className }: { className?: string }) {
  const [, , pictograms] = useResources<ActivityType>(EntityType.activityType, {
    filters: { include: "field_piktorgam" },
  });

  return (
    <Resource config={trainingConfig}>
      <Body className={className}>
        <CardTitle>
          <T>Legutóbbi edzéseim</T>
        </CardTitle>
        <ListPage>
          <Table limit={50}>
            <Column displayName={<T>Tevékenység</T>}>
              {(item) => {
                // @ts-ignore
                const pictogram = pictograms.find(
                  (elem) =>
                    elem.id ===
                    item.activityType?.relationships.field_piktorgam.data?.id
                )?.attributes.uri.url;

                return (
                  <LeftCell>
                    {!item.attributes.field_ervenyes_teljesites && (
                      <X style={{ color: "red" }} />
                    )}
                    <IconContainer>
                      <img
                        style={{
                          objectFit: "contain",
                          opacity: pictogram ? 1 : 0,
                        }}
                        src={getImageUrl({
                          src: pictogram,
                          isAdmin: true,
                          height: 50,
                          width: 50,
                        })}
                      />
                    </IconContainer>
                    {item.name}
                  </LeftCell>
                );
              }}
            </Column>
            <Column
              displayName={<T>Megtett táv</T>}
              columnSpace={"min-content"}
            >
              {(item) => (
                <Cell>
                  {round(item.distance / 1000, 2).toLocaleString("hu")}km
                </Cell>
              )}
            </Column>
            <Column displayName={<T>Arányosított táv</T>}>
              {(item) => (
                <Cell>
                  {round(
                    item.attributes.field_aranyositott_tavolsag / 1000,
                    2
                  ).toLocaleString("hu")}
                  km
                </Cell>
              )}
            </Column>
            <Column displayName={<T>Arányszám</T>}>
              {(item) => {
                console.log({ item });
                return (
                  <Cell>{item.activityType?.attributes.field_aranyszam}x</Cell>
                );
              }}
            </Column>
            <Column displayName={<T>Időpont</T>} columnSpace={"1fr"}>
              {(item) => (
                <Cell>{new Date(item.date).toLocaleDateString("hu")}</Cell>
              )}
            </Column>
            <Column displayName={<T>Időtartam</T>}>
              {(item) => <Cell>{formatDuration(item.duration)}</Cell>}
            </Column>
            {false && (
              <Column
                displayName={<T>Megosztás</T>}
                columnSpace={"min-content"}
              >
                {() => (
                  <RightCell style={{ opacity: 0, pointerEvents: "none" }}>
                    <ShareIcon />
                  </RightCell>
                )}
              </Column>
            )}
          </Table>
        </ListPage>
      </Body>
    </Resource>
  );
}

const Body = styled(Card)`
  overflow: auto;
  padding-top: 0;
  min-height: ${spacing64};
  ${CardTitle} {
    margin-top: ${spacing4};
    ${verticalDown("lg")} {
      margin-top: ${spacing2};
      margin-bottom: 0;
    }
  }
  ${TableBackground} {
    background: transparent;
    color: white;
    padding-left: 0;
  }
  ${Cell} {
    background: transparent;
    padding-top: 0;
    padding-bottom: ${spacing1};
    white-space: nowrap;
  }
  ${HeadCell} {
    color: white;
    white-space: nowrap;
    text-transform: none;
    font-family: ${({ theme }) => theme.headingsFontFamily};
    font-size: 14px;
    padding-bottom: ${spacing1};
    margin-bottom: ${spacing2};
    top: 0;
    padding-top: ${spacing4};
    background: black;
    ${down("md")} {
      padding-right: ${spacing2};
      padding-left: ${spacing2};
    }
  }
  ${HeadCell}:first-child {
    padding-left: 0;
  }
  ${GridRow} {
    > ${Cell} {
      background: transparent !important;
    }
  }
`;

const ShareIcon = styled(Share)`
  font-size: ${({ theme }) => theme.textLg};
  transform: scaleX(-1);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s;
  &:hover {
    color: white;
  }
`;

const RightCell = styled(Cell)`
  margin-left: auto;
  margin-right: auto;
  ${down("md")} {
    margin: 0;
  }
`;

const LeftCell = styled(Cell)`
  padding-left: 0;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  font-size: 24px;
  margin-right: ${spacing2};
  > img {
    height: 24px;
    width: 24px;
  }
`;

export const MyTrainings = styled(MyTrainingsComponent)``;

export async function getMyTrainings() {
  const user = currentUser$.getValue();
  const { hits, includes } = await fetchResource<Training>({
    type: EntityType.training,
    limit: 1000,
    query: "",
    page: 1,
    limit: 50,
    filters: {
      "[field_versenyzo.drupal_internal__uid]": user.drupal_internal__uid,
      include: "field_mozgasforma",
      sort: "-created",

      "[created_lt_filter][condition][path]": "created",
      "[created_lt_filter][condition][operator]": ">",
      "[created_lt_filter][condition][value]": Math.round(
        getStartsAt(user.currentRace) / 1000
      ),
      "[created_gt_filter][condition][path]": "created",
      "[created_gt_filter][condition][operator]": "<",
      "[created_gt_filter][condition][value]": Math.round(
        getEndsAt(user.currentRace) / 1000
      ),
    },
  });

  return hits.map((elem: Training) => ({
    ...elem,
    name: elem.attributes.title,
    distance: elem.attributes.field_tav,
    date: elem.attributes.created,
    duration: elem.attributes.field_idotartam,
    attributes: {
      ...elem.attributes,
    },
    activityType: includes.find(
      (type) => elem.relationships.field_mozgasforma?.data?.id === type.id
    ),
  }));
}

const trainingConfig: ModelConfig = {
  name: "trainings",
  getId: (item) => item.id,
  fetcher: async (args) => {
    if (args.after || args.skip) {
      return [];
    }
    return getMyTrainings();
  },
  fields: {
    name: { type: FieldType.string },
    distance: { type: FieldType.number },
    date: { type: FieldType.date },
    duration: { type: FieldType.number },
  },
};
