import styled from "styled-components";

export const AppCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export const AppRow = styled.div`
  display: flex;
  min-height: 100vh;
`;
