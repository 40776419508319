import styled from "styled-components";
import backgroundImg from "./assets/block-2-bg.png";
import {
  BlockBody,
  BlueTitle,
  SignUpButton,
  TextBlock,
} from "../../../styles/common-styles";
import {
  Button,
  down,
  FontWeight,
  spacing12,
  spacing16,
  spacing20,
  spacing24,
  spacing28,
  spacing32,
  spacing4,
  Restricted,
  spacing6,
  spacing8,
  useModal,
} from "@litbase/alexandria";
import { Container } from "../../container";
import { BlockData, BlockType } from "../../../types/block";
import { Interweave } from "interweave";
import { montserrat, verticalDown } from "../../../styles/theme-types";
import { Logo } from "../../general/logo";
import { RegistrationModal } from "../registration-modal";

export function AboutUsBlock({
  blockIndex,
  block,
}: {
  blockIndex: number;
  block: BlockData<BlockType.aboutUs>;
}) {
  const { showModal } = useModal();
  return (
    <Body
      blockIndex={blockIndex}
      backgroundImage={backgroundImg}
      hasFallingStars
    >
      <Container>
        <Grid>
          <FirstColumn>
            <StyledBlueTitle>{block.attributes.field_title}</StyledBlueTitle>
            {block.attributes.field_content.map((elem) => (
              <TextBlock key={elem.value}>
                <Interweave noHtml content={elem.value} />
              </TextBlock>
            ))}
            <TextBlockWithBorder>
              {block.attributes.field_highlighted}
            </TextBlockWithBorder>
          </FirstColumn>

          <SecondColumn>
            <StyledLogo />

            <Subtitle>{block.attributes.field_button_supertext}</Subtitle>
            <Restricted>
              <SignUpButton
                onClick={() =>
                  document.querySelector("#versenyek")?.scrollIntoView()
                }
              >
                {block.attributes.field_text_button}
              </SignUpButton>
            </Restricted>
            <Restricted guest>
              <SignUpButton onClick={() => showModal(<RegistrationModal />)}>
                {block.attributes.field_text_button}
              </SignUpButton>
            </Restricted>
          </SecondColumn>
        </Grid>
      </Container>
    </Body>
  );
}

const Subtitle = styled.div`
  text-align: center;
`;

const StyledBlueTitle = styled(BlueTitle)`
  ${verticalDown("lg")} {
    margin-top: 0;
  }
`;

const Body = styled(BlockBody)`
  color: white;
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  background-size: cover;
  background-position: center;

  ${down("md")} {
    padding: ${spacing6};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing32};
  padding-top: ${spacing12};
  ${verticalDown("lg")} {
    grid-template-columns: 4fr 3fr;
  }
  ${down("lg")} {
    grid-template-columns: 100%;
  }
`;

const FirstColumn = styled.div``;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg {
    margin-bottom: ${spacing28};

    ${down("md")} {
      margin-bottom: ${spacing6};
    }
  }

  > :nth-child(2) {
    margin-bottom: ${spacing20};
  }

  > div {
    ${montserrat};
    font-size: 24px;
    font-weight: ${FontWeight.SemiBold};
  }

  ${down("xl")} {
    margin-top: ${spacing8};
  }
`;

const TextBlockWithBorder = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.blue};
  border-top: 2px solid ${({ theme }) => theme.blue};
  font-weight: bold;
  padding-top: ${spacing4};
  padding-bottom: ${spacing4};
  ${down("md")} {
    text-align: center;
  }
`;

const StyledLogo = styled(Logo)`
  color: white;
  max-width: 356px;
  ${down("md")} {
    margin-top: ${spacing16};
  }
`;
