import { maxBy } from "lodash-es";
import { RefObject, useMemo, useState } from "react";
import styled from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down, T, useMeasure } from "@litbase/alexandria";
import { formatDistance } from "./utils";

export function SineWave({
  amplitude = 50,
  milestones,
  progress,
  sectionsRef,
}: {
  amplitude?: number;
  frequency?: number;
  milestones: { distance: number }[];
  progress: number;
  sectionsRef: RefObject<HTMLDivElement>;
}) {
  const isSmall = useBreakpoint(down("md"));
  const [innerHeight, setInnerHeight] = useState(0);

  useMeasure((bounds) => {
    setInnerHeight(bounds.height);
  }, sectionsRef);

  // const [innerHeight] = useObservable(
  //   () =>
  //     fromEvent(window, "resize").pipe(
  //       startWith(null),
  //       map(() => {
  //         const height = window.outerHeight;
  //
  //         if (window.innerWidth < 1200) {
  //           return Math.round(height * 2.5);
  //         }
  //         return height;
  //       }),
  //       first()
  //     ),
  //   0
  // );
  const padding = 240;
  const strokeWidth = 3;

  const segments = [
    { start: 0, frequency: 0, km: 0 },
    { start: innerHeight, frequency: 1, km: 100 },
    { start: 2 * innerHeight, frequency: 2, km: 1000 },
    { start: 3 * innerHeight, frequency: 3, km: 10000 },
    { start: 4 * innerHeight, frequency: 4, km: 100000 },
    { start: 4.5 * innerHeight, frequency: 16, km: 450000 },
    { start: 4.5 * innerHeight + 1, frequency: 0, km: 450001 },
    { start: 5 * innerHeight, frequency: 0, km: 450002 },
  ];
  const totalPoints = Math.round(
    Math.max(...segments.map((elem) => elem.start))
  );

  const { svgD1, svgD2, path2StartX, path2StartY } = useMemo(() => {
    const pathCoords = Array(totalPoints)
      .fill(0)
      .map((elem, index) => getXCoord(index, amplitude, strokeWidth, segments));
    const progressY = findProgressY({ segments, progress });
    const [path1Coords, path2Coords] = [
      pathCoords.filter(([, y]) => y <= progressY),
      pathCoords.filter(([, y]) => y > progressY),
    ];
    const [path2StartX, path2StartY] = path1Coords.slice(-1)[0];
    return {
      svgD1: [
        ["M", amplitude + strokeWidth + padding, 0].join(" "),
        ...path1Coords.map(([x, y]) => ["L", x + padding, y].join(" ")),
      ],
      svgD2: [
        ["M", path2StartX + padding, path2StartY].join(" "),
        ...path2Coords.map(([x, y]) => ["L", x + padding, y].join(" ")),
      ],
      path2StartX,
      path2StartY,
    };
  }, [totalPoints, amplitude, innerHeight]);

  const progressIndicatorLineLength = isSmall ? 0 : 50;

  return (
    <Sin
      width={Math.ceil(amplitude * 2) + strokeWidth * 2 + padding * 2} //each side needs length of 1/4 totalPoints + 2 * offset
      height={totalPoints}
    >
      <path
        id="whiteWave"
        stroke="white"
        opacity="1"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="none" //fill the inside of the shape
        d={svgD1.join(" ")}
      />
      <path
        id="grayWave"
        stroke="white"
        opacity="0.5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="none" //fill the inside of the shape
        d={svgD2.join(" ")}
      />
      <circle
        id={"progressIndicator"}
        fill={"white"}
        cx={path2StartX + padding}
        cy={path2StartY}
        r={10}
      />
      <text
        className="progress-name"
        fill={"white"}
        y={path2StartY - 5}
        x={path2StartX + padding + progressIndicatorLineLength + 40}
      >
        <T>Itt tartunk</T>
      </text>
      <text
        className="progress-value"
        fill={"white"}
        fontWeight={"bold"}
        y={path2StartY + 26}
        x={path2StartX + padding + progressIndicatorLineLength + 40}
      >
        {formatDistance(progress)}
      </text>
      <rect
        fill={"white"}
        x={path2StartX + padding + 25}
        y={path2StartY}
        width={progressIndicatorLineLength}
        height={3}
      />
    </Sin>
  );
}

function findProgressY({
  segments,
  progress,
}: {
  segments: { km: number; start: number }[];
  progress: number;
}) {
  const index = segments.findIndex(
    (elem, index) => elem.km <= progress && segments[index + 1].km > progress
  );
  if (index === -1) return segments.slice(-1)[0].start;
  const d = segments[index + 1].km - segments[index].km;
  const ratio = (progress - segments[index].km) / d;
  return (
    (1 - ratio) * segments[index].start + ratio * segments[index + 1].start + 20
  );
}

function getXCoord(
  point: number,
  amplitude: number,
  strokeWidth = 3,
  segments: { start: number; frequency: number }[]
) {
  const currentSegment = maxBy(
    segments.filter((elem) => elem.start <= point),
    (elem) => elem.start
  );
  if (!currentSegment) throw new Error("No segments");
  const currentSegmentIndex = segments.findIndex(
    (elem) =>
      elem.start === currentSegment.start &&
      elem.frequency === currentSegment.frequency
  );
  const frequency = currentSegment.frequency;
  const multiplier = 1 / 30;
  let offset = calculateOffsets(segments, multiplier)[currentSegmentIndex];
  const xyPoint = {
    y: point,
    x:
      amplitude +
      Math.sin(
        frequency * point * multiplier + (frequency !== 0 ? offset : 0)
      ) *
        amplitude +
      strokeWidth,
  };
  return [xyPoint.x, xyPoint.y];
}

function calculateOffsets(
  segments: { start: number; frequency: number }[],
  multiplier
) {
  const offsets = [0];
  for (let i = 1; i < segments.length; i++) {
    offsets.push(
      segments[i - 1].frequency * segments[i].start * multiplier +
        offsets[i - 1] -
        segments[i].frequency * segments[i].start * multiplier
    );
  }
  return offsets;
}

const Sin = styled.svg`
  margin: 0 auto;
  position: absolute;

  .progress-value {
    font-size: 24px;
  }
`;
