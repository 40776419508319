export interface Competition {
  type: "node--versenyek";
  id: string;
  relationships: {
    field_arak: {};
    field_mozgasformak: {};
    field_strava_activity: {};
    node_type: {};
  };
  attributes: {
    changed: string;
    created: string;
    default_langcode: boolean;
    drupal_internal__nid: number;
    drupal_internal__vid: number;
    field_osszes_megtett_tavolsag: number | null;
    field_osszes_megtett_tavolsag_ar: number | null;
    field_regisztracios_idoszak: {
      value: string;
      end_value: string;
    };
    field_leiras?: { value: string; processed: string };
    field_verseny_idoszaka: { value: string; end_value: string };
    langcode: "hu";
    path: { alias: null; pid: null; langcode: "hu" };
    promote: boolean;
    revision_log: null;
    revision_timestamp: string;
    revision_translation_affected: boolean;
    status: boolean;
    sticky: boolean;
    title: string;
  };
}

export function getStartsAt(event: Competition) {
  const date = new Date(event.attributes.field_verseny_idoszaka.value);
  date.setHours(0);
  date.setMinutes(0);
  return date;
}

export function getEndsAt(event: Competition) {
  const date = new Date(event.attributes.field_verseny_idoszaka.end_value);
  date.setHours(23);
  date.setMinutes(59);
  return date;
}
