import styled, { css } from "styled-components";
import { spacing3, spacing6 } from "@litbase/alexandria";
import { verticalDown } from "../../../styles/theme-types";

export const cardStyles = css`
  border: 1px solid white;
  border-radius: ${({ theme }) => theme.roundedLg};
  background: rgba(0, 0, 0, 0.6);
  padding: ${spacing6};
  ${verticalDown("lg")} {
    padding: ${spacing3};
  }
`;

export const Card = styled.div`
  ${cardStyles};
  color: white;
`;

export const CardTitle = styled.h2`
  font-size: 24px;
  color: white;
  font-weight: bold;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  text-transform: none;
  ${verticalDown("lg")} {
    font-size: 18px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  text-transform: none;
  color: white;
  letter-spacing: 0.1em;
  line-height: 1em;
  ${verticalDown("lg")} {
    font-size: 28px;
  }
`;
