import simpleLogo from "../pages/profile/my-team/assets/simple-logo.png";
import otpLogo from "../pages/profile/my-team/assets/otp-logo.png";
import styled from "styled-components";
import { spacing2, spacing4 } from "@litbase/alexandria";
import { useController } from "react-hook-form";

export function SimplePayButton() {
  const controller = useController({ name: "fizetes_valaszt" });

  return (
    <PaymentCard
      style={
        controller.field.value === "bankkartya"
          ? { transform: "scale(1.1)", border: "2px solid #6534ff" }
          : { border: "2px solid transparent" }
      }
      onClick={() => controller.field.onChange("bankkartya")}
    >
      <img src={simpleLogo} />
      <span>Simplepay</span>
    </PaymentCard>
  );
}

export function OTPButton() {
  const controller = useController({ name: "fizetes_valaszt" });
  return (
    <SZEPContainer>
      <PaymentCard
        onClick={() => controller.field.onChange("szep")}
        style={
          controller.field.value === "szep"
            ? { transform: "scale(1.1)", border: "2px solid #6534ff" }
            : { border: "2px solid transparent" }
        }
      >
        <img src={otpLogo} />
        <span>SZÉP kártya</span>
      </PaymentCard>
    </SZEPContainer>
  );
}

const SZEPContainer = styled.div`
  margin-left: ${spacing4};
`;

const Error = styled.span`
  color: red;
`;

const PaymentCard = styled.div`
  transition: transform 0.3s;
  cursor: pointer;
  border: 2px solid white;
  border-radius: ${({ theme }) => theme.roundedLg};
  width: 110px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  > span {
    text-align: center;
    font-family: ${({ theme }) => theme.headingsFontFamily};
    padding: ${spacing2};
    font-size: 14px;
  }
  & + & {
    margin-left: ${spacing4};
  }
`;
