import { HookSelectField, HookSelectFieldProps } from "@litbase/alexandria";
import styled from "styled-components";
import { theme } from "../../styles/theme-types";

export function SelectField<T = string>({
  name,
  styles: propStyles,
  options: propOptions,
  hasStringValue,
  ...props
}: {
  name: string;
  styles: Record<string, any>;
  options: T[];
  hasStringValue?: boolean;
} & HookSelectFieldProps<T>) {
  const options = propOptions.map((elem) =>
    typeof elem === "string" ? { value: elem, label: elem } : elem
  );

  const styles = {
    ...selectStyles,
    borderColor: props.hasError ? "red" : "transparent",
    border: props.hasError
      ? "2px solid red"
      : "2px solid rgba(255,255,255,0.5)",
    ...propStyles,
  };

  return (
    <StyledSelect
      name={name}
      styles={styles}
      options={options}
      parse={hasStringValue ? (value) => value.value || value : undefined}
      transform={
        hasStringValue
          ? (value) => options.find((option) => option.value === value)
          : undefined
      }
      {...props}
    />
  );
}

export const selectStyles = {
  menu: (provided) => ({ ...provided, zIndex: 21 }),
  valueContainer: (provided) => ({
    ...provided,
    border: "none",
    background: "transparent",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingLeft: 0,
    padding: 0,
  }),
  container: (provided) => ({
    ...provided,
    background: "transparent",
    flex: 1,
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "transparent",
    border: "2px solid rgba(255,255,255,0.5)",
    outline: "none",
    boxShadow: "none",
    background: "transparent",
    fontSize: "14px",
    height: "36px",
    maxHeight: "36px",
    minHeight: "0",
    color: "white",
  }),
  singleValue: (provided) => ({ ...provided, color: "white" }),
  input: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
  indicatorsContainer: (provided) => ({ ...provided, padding: "0px" }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.blue : "black",
    color: "white",
    "&:hover": {
      backgroundColor: theme.lightBlue,
      color: "black",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "black",
    border: "1px solid white",
    borderRadius: "5px",
    padding: "0",
  }),
};

const StyledSelect = styled(HookSelectField)`
  min-width: 240px;
  height: 36px;
`;
