import { HookForm, T, useModal } from "@litbase/alexandria";
import { InputField } from "../../form/input-field";
import { LoginModal, MoonshotModal, Title } from "../login-modal";
import {
  getFormDataFromHtml,
  hackilySubmitForm,
  parseHtml,
  sendBackendRequest,
} from "../../../services/backend";
import {
  Button,
  SubmitButton,
  SubmitButtonWrapper,
} from "../../../styles/common-styles";
import { Line, StepCircle } from "../../general/steps";
import { useEffect, useState } from "react";
import { switchValue } from "../../../utils/switch-value";
import { refreshLoggedInUserData } from "../../../hooks/use-user";
import {
  LargeModalLoadingIcon,
  ModalParagraph,
  ModalStepWrapper,
  ModalSubtitle,
} from "../../../styles/modal-styles";
import { useNavigate } from "react-router-dom";
import { PasswordField } from "../../form/password-field";
import { object, string } from "yup";

export function PasswordResetModal({ pathname }: { pathname?: string }) {
  const [stepNumber, setStepNumber] = useState<number>(pathname ? 3 : 1);

  return (
    <MoonshotModal>
      <Title>
        <T>Új jelszó igénylése</T>
      </Title>
      <ModalStepWrapper>
        <StepCircle $isActive>1</StepCircle>
        <Line />
        <StepCircle $isActive={1 < stepNumber}>2</StepCircle>
        <Line />
        <StepCircle $isActive={2 < stepNumber}>3</StepCircle>
      </ModalStepWrapper>
      {switchValue(stepNumber, [
        [1, <PasswordResetStepOne onNext={() => setStepNumber(2)} />],
        [2, <PasswordResetStepTwo />],
        [3, <PasswordResetStepThree pathname={pathname} />],
      ])}
    </MoonshotModal>
  );
}

function PasswordResetStepOne({ onNext }: { onNext: () => void }) {
  return (
    <>
      <ModalParagraph>
        <T>
          Kérlek add meg a fiókodhoz tartozó e-mail címet az új jelszó
          igényléséhez:
        </T>
      </ModalParagraph>
      <HookForm
        defaultValues={{ name: "" }}
        onSubmit={async (values) => {
          await hackilySubmitForm("/user/password", values, {
            formSelector: "form.user-pass",
          });

          onNext();
        }}
      >
        <InputField
          label={<T>Email cím</T>}
          type="email"
          name="name"
          required
        />
        <SubmitButtonWrapper>
          <SubmitButton>
            <T>Igénylés elküldése</T>
          </SubmitButton>
        </SubmitButtonWrapper>
      </HookForm>
    </>
  );
}

function PasswordResetStepTwo() {
  const { dismissModal } = useModal();

  return (
    <div>
      <ModalParagraph $align="justify">
        <ModalSubtitle>Email elküldve</ModalSubtitle>
        <p>
          Amennyiben a megadott email címhez tartozik regisztrált fiók,
          elküldtük rá az új jelszó beállításához szükséges következő lépéseket.
        </p>
        <p>Kérjük ellenőrizd levélfiókodat!</p>
      </ModalParagraph>
      <SubmitButtonWrapper>
        <Button onClick={() => dismissModal()}>Bezárás</Button>
      </SubmitButtonWrapper>
    </div>
  );
}

function PasswordResetStepThree({ pathname }: { pathname?: string }) {
  const navigate = useNavigate();
  const { dismissModal, showModal } = useModal();
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [formResponse, setFormResponse] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    async function handleStepThree() {
      try {
        const response = await processPasswordReset(pathname);
        setFormResponse(response);
      } catch (error) {
        setError(error as Error);
        // Clear the registration code from the url
        navigate("/", { replace: true });
      } finally {
        setIsProcessing(false);
      }
    }

    handleStepThree();
  }, []);

  if (errors.length) {
    console.log({ errors });
    return (
      <div>
        <ModalParagraph>Sikertelen jelszóvisszaállítás</ModalParagraph>
        {errors.map((elem) => (
          <li>{elem}</li>
        ))}
        <p>Próbálj meg új jelszó viszállító e-mailt kérni!</p>
      </div>
    );
  }

  if (success) {
    return (
      <div>
        <ModalParagraph>
          <ModalSubtitle>Siker!</ModalSubtitle>
          <div>Most már be tudsz jelentkezni az új jelszavaddal!</div>
          <Button onClick={() => showModal(<LoginModal />)}>
            A bejelentkezéshez
          </Button>
        </ModalParagraph>
      </div>
    );
  }

  if (formResponse) {
    return (
      <div>
        <ModalParagraph>
          <ModalSubtitle>Add meg az új jelszót!</ModalSubtitle>
          <HookForm
            defaultValues={{ pass: "", passAgain: "" }}
            yupSchema={yupSchema}
            onSubmit={async (values) => {
              try {
                await setNewPassword(values.pass, formResponse);
                setSuccess(true);
              } catch (e) {
                setErrors(e.message);
              }
            }}
          >
            <PasswordField name={"pass"} label={"Új jelszó"} />
            <PasswordField name={"passAgain"} label={"Új jelszó még egyszer"} />
            <SubmitButton>Jelszó megváltoztatása</SubmitButton>
          </HookForm>
        </ModalParagraph>
      </div>
    );
  }

  if (isProcessing) {
    return (
      <div>
        <ModalParagraph $align="center">Feldolgozás alatt...</ModalParagraph>
        <LargeModalLoadingIcon />
      </div>
    );
  }

  return (
    <div>
      {!error ? (
        <ModalParagraph>
          <ModalSubtitle>Sikeres belépés!</ModalSubtitle>
          <div>
            Sikeresen beléptettünk a fiókodba! <br />
            Kérjük használd ezt az alkalmat arra, hogy megváltoztasd a
            jelszavadat egy általad ismert értékre.
          </div>
        </ModalParagraph>
      ) : (
        <ModalParagraph>
          <ModalSubtitle>Hiba történt</ModalSubtitle>
          <div>{error.message || String(error)}</div>
        </ModalParagraph>
      )}

      <SubmitButtonWrapper>
        <Button onClick={() => dismissModal()}>Bezárás</Button>
      </SubmitButtonWrapper>
    </div>
  );
}

async function processPasswordReset(pathname?: string) {
  if (!pathname) {
    throw new Error("Ismeretlen hiba.");
  }

  const response = await sendBackendRequest(pathname).text();

  if (!response.includes("Ez egy egyszer használható belépési mód")) {
    throw new Error(
      "Az egyszeri beléptető hivatkozás már fel lett használva, vagy érvényessége lejárt."
    );
  }

  const { formData, form } = getFormDataFromHtml(
    response,
    "form.user-pass-reset"
  );

  // Only use the pathname, strip the domain part
  const formActionUrl = new URL(form.action);
  const loginResponse = await sendBackendRequest(formActionUrl.pathname, {
    method: "POST",
    body: formData,
  }).text();

  if (
    !loginResponse.includes(
      "Erre az egyszeri bel\\u00e9p\\u00e9si hivatkoz\\u00e1sra nincs sz\\u00fcks\\u00e9g a k\\u00e9s\\u0151bbi bejelentkez\\u00e9sekn\\u00e9l"
    )
  ) {
    throw new Error(
      "Az egyszeri beléptető hivatkozás már fel lett használva, vagy érvényessége lejárt."
    );
  }

  return loginResponse;
}

async function setNewPassword(newPassword: string, GETResponse: string) {
  const document = parseHtml(GETResponse);
  const formData = new FormData();
  formData.set("pass[pass1]", newPassword);
  formData.set("pass[pass2]", newPassword);
  formData.set("form_id", "user_form");
  formData.set("op", "Mentés");

  const formToken = document.querySelector("input[name=form_token]").value;
  const formBuildId = document.querySelector("input[name=form_build_id]").value;

  formData.set("form_build_id", formBuildId);
  formData.set("form_token", formToken);

  const url = document.querySelector("form").action.match("/user.+$")[0];

  const response = await sendBackendRequest(url, {
    method: "POST",
    body: formData,
  });
  const text = await response.text();
  const doc2 = parseHtml(text);
  const errorMessages = doc2.querySelector(".messages__item")?.innerText;
  if (errorMessages) {
    throw new Error([errorMessages]);
  }
}

const yupSchema = object().shape({
  passAgain: string().test(
    "passAgain",
    "A két jelszónak egyeznie kell!",
    function (value) {
      console.log(this);
      return value === this.parent.pass;
    }
  ),
});
