import { SimpleModal, useModal } from "@litbase/alexandria";
import { useEffect } from "react";
import { useResources } from "../../hooks/use-resources";
import { EntityType } from "../../types/entity";
import { getImageUrl } from "../../utils/get-image-url";
import styled from "styled-components";

interface PopupInterface {
  attributes: {
    field_eltunes: number;
    field_link: string;
  };
  relationships: {
    field_popup_kep: {
      data: {
        id: string;
      };
    };
  };
}

let wasShown = false;

export function Popup() {
  const { showModal, dismissModal } = useModal();
  const [popups, loading, images] = useResources<PopupInterface>(
    EntityType.popup,
    { filters: { include: "field_popup_kep" } }
  );

  const popup = popups[0];

  const image = images?.find(
    (elem) => elem.id === popup?.relationships?.field_popup_kep?.data?.id
  )?.attributes?.uri?.url;

  useEffect(() => {
    if (
      !loading &&
      popup &&
      location.pathname === "/" &&
      !location.search &&
      !location.hash &&
      !wasShown
    ) {
      const timeout = setTimeout(() => {
        dismissModal();
      }, popup.attributes.field_eltunes * 1000);
      wasShown = true;
      showModal(
        <StyledModal>
          <a href={popup.attributes.field_link} target={"_blank"}>
            <Img src={getImageUrl({ src: image, isAdmin: true })} />
          </a>
        </StyledModal>
      ).then(() => {
        clearTimeout(timeout);
      });
    }
  }, [loading, popup]);

  return null;
}

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const StyledModal = styled(SimpleModal)`
  padding: 0;
  overflow: hidden;
  > div {
    padding: 0;
    overflow: hidden;
    > div {
      overflow: hidden;
    }
  }
`;
