import styled from "styled-components";
import { BlockBody, Button } from "../../styles/common-styles";
import { Title } from "./login-modal";
import heavy from "../../assets/heavy.jpg";
import inProgress_spaceship from "../../assets/inProgress_spaceship.jpg";
import failed_spaceship from "../../assets/failed_spaceship.jpg";
import { getImageUrl } from "../../utils/get-image-url";
import { useEffect, useState } from "react";
import {
  LoadingIcon,
  spacing1,
  spacing2,
  spacing3,
  spacing4,
  spacing8,
  T,
} from "@litbase/alexandria";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../../utils/paths";
import { CheckCircle, XCircle } from "@styled-icons/boxicons-regular";
import { sendBackendRequest } from "../../services/backend";

const bg = getImageUrl({ src: heavy, width: 1920, height: 1080 });
const inProgressBg = getImageUrl({
  src: inProgress_spaceship,
  width: 1920,
  height: 1080,
});
const failedBg = getImageUrl({
  src: failed_spaceship,
  width: 1920,
  height: 1080,
});

enum PageType {
  success = "success",
  failed = "failed",
  inProgress = "inProgress",
}

interface Response {
  data: string;
  amount: number;
  transaction_id: string;
}

export function Success() {
  const [count, setCount] = useState(20);
  const [isSuccess, setIsSuccess] = useState(PageType.inProgress);
  const [responseItem, setResponseItem] = useState<Response>();
  const navigate = useNavigate();
  const url = "/moonshot/pay-back";
  const location = useLocation();

  useEffect(() => {
    async function teszt() {
      try {
        const response = await sendBackendRequest(
          url + location.search,
          {}
        ).json<Response>();
        if (response?.data === "success") {
          setIsSuccess(PageType.success);
          setResponseItem(response);
        } else {
          setIsSuccess(PageType.failed);
          setResponseItem(response);
        }
      } catch (e) {
        setIsSuccess(PageType.failed);
      }
    }
    teszt();
  }, []);

  useEffect(() => {
    if (isSuccess !== PageType.inProgress) {
      const timeout = setTimeout(() => setCount((prev) => prev - 1), 1000);
      return () => clearTimeout(timeout);
    }
  });

  if (count <= 0) {
    isSuccess === PageType.success ? navigate("/profilom") : navigate("/");
  }

  if (isSuccess === PageType.inProgress) {
    return (
      <Body blockIndex={0} backgroundImage={inProgressBg}>
        <Card>
          <Title>
            <T>Fizetés feldogozása folyamatban...</T>
          </Title>
          <StyledLoadingIcon />
        </Card>
      </Body>
    );
  }

  if (isSuccess === PageType.failed) {
    return (
      <Body blockIndex={0} backgroundImage={failedBg}>
        <Card>
          <Title>
            <T>Sikertelen fizetés</T> <XCircle />
          </Title>
          <div></div>
          <Span>
            <T>Automatikus átirányítás a főoldalra...</T>
          </Span>
          <Counter>
            <Span>{count}</Span>
          </Counter>
          <StyledLink onClick={() => navigate(Paths.MAIN)}>
            <T>Vigyél oda most</T>
          </StyledLink>
        </Card>
      </Body>
    );
  }

  return (
    <Body blockIndex={0} backgroundImage={bg}>
      <Card>
        <Title>
          <T>Sikeres fizetés</T> <CheckCircle />
        </Title>
        <div></div>
        <TextWrapper>
          <Text>
            {" "}
            <T>Tranzakció azonosító</T>: {responseItem?.transaction_id}{" "}
          </Text>
          <Text>
            {" "}
            <T>Összeg</T>: {responseItem?.amount} Ft{" "}
          </Text>
        </TextWrapper>
        <Span>
          <T>Automatikus átirányítás a 'profilom' oldalra...</T>
        </Span>
        <Counter>
          <Span>{count}</Span>
        </Counter>
        <StyledLink onClick={() => navigate(Paths.PROFILE)}>
          <T>Vigyél oda most</T>
        </StyledLink>
      </Card>
    </Body>
  );
}

const Span = styled.span`
  color: white;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  margin-top: ${spacing4};
  margin-bottom: ${spacing4};
`;

const Text = styled.div`
  margin-top: ${spacing1};
  margin-bottom: ${spacing1};
`;

const StyledLink = styled(Button)`
  margin: 0 auto;
  color: white;
`;

const Body = styled(BlockBody)`
  color: white;
  min-height: 100vh;
  background-position: center;
  text-shadow: 2px 2px black;
  text-align: center;
  ${Title} {
    color: white;
    text-align: center;
    margin-bottom: ${spacing3};
  }
`;

const Card = styled.div`
  background-color: rgb(0, 0, 0, 0.7);
  padding-left: ${spacing8};
  padding-right: ${spacing8};
  padding-top: ${spacing4};
  padding-bottom: ${spacing8};
  border-radius: 12px;
`;

const Counter = styled.div`
  margin-top: ${spacing2};
  margin-bottom: ${spacing2};
  font-size: 24px;
`;

const StyledLoadingIcon = styled(LoadingIcon)`
  margin-top: ${spacing8};
  color: white;
  font-size: 46px;
`;
