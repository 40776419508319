import {
  down,
  FontWeight,
  spacing1,
  spacing12,
  spacing16,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  T,
  up,
} from "@litbase/alexandria";
import styled from "styled-components";
import { BlockBody, BlueTitle } from "../../../styles/common-styles";
import {
  breakPointsPixels,
  theme,
  verticalDown,
} from "../../../styles/theme-types";

export function MoonBlock({ blockIndex }: { blockIndex: number }) {
  return (
    <Body blockIndex={blockIndex}>
      <BlueTitle>
        <T>A Hold</T>
      </BlueTitle>
      <Grid>
        <Col>
          <Title>
            <T>Hőmérséklet</T>
          </Title>
          <Label>
            <T>Nappali hőmérséklet</T>
          </Label>
          <Value>105°C</Value>
          <WhiteLine />
          <Label>
            <T>Éjjeli hőmérséklet</T>
          </Label>
          <Value>-183°C</Value>
        </Col>
        <Col>
          <Title>
            <T>Holdraszállás</T>
          </Title>
          <Label>
            <T>Indulás</T>
          </Label>
          <Value>
            07.16.1969, <br />
            13:32:00 UTC
          </Value>
          <Subtitle>
            <T>Kennedy űrállomás</T>
          </Subtitle>
          <WhiteLine />
          <Label>
            <T>Leszállás</T>
          </Label>
          <Value>
            07.20.1969, <br />
            20:18:04 UTC
          </Value>
          <Subtitle>Mare Transquilitas</Subtitle>
        </Col>
        <CenterElemWrapper>
          <CenterElem>
            <WhiteLine />
            <MidTitle>
              <T>A Hold átlagos keringési sebessége</T>
            </MidTitle>
            <span>
              <BigValue>1</BigValue>
              <SmallValue>km/s</SmallValue>
              <StyledP>
                <T>orbitalVelocity</T>
              </StyledP>
            </span>
            <Text>
              <T>darkSide</T>
            </Text>
            <WhiteLine />
            <MidTitle>
              <T>Holdnap</T>
            </MidTitle>
            <span>
              <BigValue>1</BigValue>
              <SmallValue>
                <T>nap</T>
              </SmallValue>
              <StyledP>
                <T>moonDay</T>
              </StyledP>
            </span>
            <WhiteLine />
            <MidTitle>
              <T>A világosság a Holdon</T>
            </MidTitle>
            <span>
              <BigValue>2</BigValue>
              <SmallValue>
                <T>hétig tart</T>
              </SmallValue>
              <StyledP>
                <T>darkness</T>
              </StyledP>
            </span>
          </CenterElem>
        </CenterElemWrapper>
        <Col>
          <Title>
            <T>Átmérő</T>
          </Title>
          <DiameterGrid>
            <SmallTitle className={"gray"}>
              <T>Hold</T>
            </SmallTitle>
            <SmallTitle className={"blue"}>
              <T>Föld</T>
            </SmallTitle>
            <SmallTitle className={"yellow"}>
              <T>Nap</T>
            </SmallTitle>
            <P>3,745km</P>
            <P>12,682km</P>
            <P>1,392,000km</P>
            <P>
              0,27 x <T>Föld</T>
            </P>
            <P></P>
            <P>
              109 x <T>Föld</T>
            </P>
          </DiameterGrid>
        </Col>
        <Col>
          <Title>
            <T>theDarkSide</T>
          </Title>
          <P>
            <T>bothSide</T>
          </P>
        </Col>
      </Grid>
    </Body>
  );
}

const SmallTitle = styled.span`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: ${FontWeight.SemiBold};

  &.blue {
    color: ${theme.lightBlue};
  }

  &.yellow {
    color: ${theme.lightYellow};
  }

  &.gray {
    color: ${theme.darkGray};
  }
`;

const DiameterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: ${spacing2};
  > p {
    margin: 0;
    white-space: nowrap;
    font-size: 12px;
  }
`;

const Text = styled.span`
  font-family: Montserrat;
  border-top: 1px solid white;
  padding-top: ${spacing2};
  grid-column: span 3;
`;

const P = styled.p`
  font-size: 14px;
  text-align: start;
  font-family: Montserrat;
  ${verticalDown("lg")} {
    font-size: 12px;
  }
`;

const StyledP = styled(P)`
  color: #767678;
`;

const BigValue = styled.span`
  font-size: 64px;
  font-family: Montserrat;
  margin-right: ${spacing2};
  ${verticalDown("lg")} {
    font-size: 48px;
  }
`;

const SmallValue = styled.span`
  font-size: 24px;
  font-family: Montserrat;
  ${verticalDown("lg")} {
    font-size: 18px;
  }
`;

const MidTitle = styled.span`
  font-size: 18px;
`;

const WhiteLine = styled.div`
  height: 1px;
  width: 100%;
  background: white;
  margin-top: ${spacing6};
  margin-bottom: ${spacing2};
  ${verticalDown("lg")} {
    margin-top: ${spacing3};
    margin-bottom: ${spacing1};
  }
`;

const Subtitle = styled.span`
  color: #767678;
  font-size: 12px;
  letter-spacing: 0.1em;
  font-family: Montserrat;
`;

const CenterElemWrapper = styled.div`
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  ${down("xl")} {
    grid-column: 1 / -1;
    grid-row: 2;
  }
  display: flex;
  justify-content: center;
`;

const CenterElem = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, min-content);
  height: fit-content;
  display: grid;
  grid-auto-flow: column dense;
  grid-column-gap: ${spacing6};
  padding-top: 28px;
  ${down("xl")} {
  }
  ${down("md")} {
    display: flex;
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  &:nth-child(2) {
    grid-column: 1;
  }
  &:nth-child(4) {
    grid-column: 4;
  }
  &:nth-child(5) {
    grid-column: 4;
  }
  ${down("xl")} {
    grid-column: 1;
    &:nth-child(2) {
      grid-column: 2;
    }
    &:nth-child(4) {
      grid-column: 1;
    }
    &:nth-child(5) {
      grid-column: 2;
    }
  }
`;

const Title = styled.h3`
  color: white;
  font-size: 24px;
  padding-bottom: ${spacing4};
  border-bottom: 1px solid white;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  letter-spacing: 0.1em;
  ${verticalDown("lg")} {
    font-size: 18px;
  }
`;

const Label = styled.span`
  font-size: 12px;
  color: white;
  font-family: Montserrat;
  letter-spacing: 0.1em;
`;

const Value = styled.span`
  font-size: 24px;
  color: white;
  ${verticalDown("lg")} {
    font-size: 18px;
  }
`;

const Body = styled(BlockBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing16};
  max-width: ${breakPointsPixels.xl}px;
  margin: 0 auto;
  z-index: 2;
  ${down("xl")} {
    padding: ${spacing6};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  grid-auto-flow: column;
  grid-row-gap: ${spacing12};
  grid-column-gap: ${spacing12};
  color: white;
  ${down("xl")} {
    grid-template-columns: 1fr 1fr;
    max-width: 650px;
  }
  ${down("md")} {
    display: flex;
    flex-direction: column;
  }
`;
