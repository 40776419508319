import styled from "styled-components";

import { Link, useLocation } from "react-router-dom";
import {
  down,
  Restricted,
  spacing4,
  spacing6,
  up,
  useModal,
  setLanguageSettings,
  T,
  useLanguage,
} from "@litbase/alexandria";
import { logout } from "../../hooks/use-user";
import { Paths } from "../../utils/paths";
import { LoginModal } from "../pages/login-modal";
import { jumpToElement } from "../../links";
import reszveteli from "../../assets/reszveteli.pdf";
import { useMemo } from "react";

export function NavLinks() {
  const { showModal } = useModal();
  const loc = useLocation();
  const lang = useLanguage();
  const languageLink = useMemo(() => {
    const origLink = lang === "hu" ? process.env.EN_URL : process.env.HU_URL;
    const url = new URL(origLink);
    const currentUrl = new URL(location.href);
    const resultUrl = new URL(currentUrl.toString());
    for (const [key, value] of url.searchParams.entries()) {
      resultUrl.searchParams.set(key, value);
    }
    resultUrl.host = url.host;
    return resultUrl.toString();
  }, [lang, loc]);

  return (
    <>
      <Restricted guest>
        <StyledLink
          to={""}
          className={"navbar-login-button"}
          onClick={() => showModal(<LoginModal />)}
        >
          <T>Belépés</T>
        </StyledLink>
      </Restricted>
      <Restricted>
        <MobileLink to={Paths.PROFILE}>
          <T>Profilom</T>
        </MobileLink>
      </Restricted>
      <StyledLink
        to={"/#versenyek"}
        onClick={() => {
          setTimeout(() => jumpToElement("#versenyek"), 1000);
        }}
      >
        <T>Versenyek</T>
      </StyledLink>
      <StyledLink to={Paths.STATS}>
        <T>Közös eredmény</T>
      </StyledLink>
      <StyledLink to={Paths.POLICY}>
        <T>Versenyleírás</T>
      </StyledLink>
      <StyledLink as="a" target="_blank" href={reszveteli}>
        <T>Általános Részvételi Szabályzat</T>
      </StyledLink>
      <StyledLink as={"a"} href={languageLink}>
        {lang === "hu" ? <EnFlag /> : <HuFlag />}
        <T>English</T>
      </StyledLink>
      <Restricted>
        <StyledLink to="/" onClick={() => logout()}>
          <T>Kijelentkezés</T>
        </StyledLink>
      </Restricted>
    </>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  padding: 0 ${spacing6};
  transition: color 0.3s;
  margin-bottom: ${spacing4};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary400};
  }

  ${down("sm")} {
    font-size: 24px;
  }

  &.navbar-login-button {
    ${up("sm")} {
      display: none;
    }
  }

  svg {
    width: 32px;
    height: 16px;
    margin-right: 0.5rem;
    margin-bottom: 4px;
    border-radius: 4px;
  }
`;

const MobileLink = styled(StyledLink)`
  display: none;
  ${down("md")} {
    display: initial;
  }
`;

function HuFlag() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1200"
      height="600"
      viewBox="0 0 6 3"
    >
      <rect fill="#477050" width="6" height="3" />
      <rect fill="#FFF" width="6" height="2" />
      <rect fill="#CE2939" width="6" height="1" />
    </svg>
  );
}

function EnFlag() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 30"
      width="1200"
      height="600"
    >
      <clipPath id="s">
        <path d="M0,0 v30 h60 v-30 z" />
      </clipPath>
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <g clipPath="url(#s)">
        <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path
          d="M0,0 L60,30 M60,0 L0,30"
          clipPath="url(#t)"
          stroke="#C8102E"
          strokeWidth="4"
        />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
      </g>
    </svg>
  );
}
