import { CardTitle, spacing2, spacing4 } from "@litbase/alexandria";
import styled, { css } from "styled-components";

export const WideContainer = styled.div`
  grid-column: span 2;
  > * {
    width: 100%;
    max-width: 100%;
  }
  button {
    width: fit-content;
  }
`;

export const formBodyStyles = css`
  display: grid;
  grid-row-gap: ${spacing2};
  grid-column-gap: ${spacing4};
  grid-template-columns: 1fr 1fr;

  ${CardTitle} {
    grid-column: span 2;
  }

  && {
    > div {
      margin-top: 0;
    }
  }
`;
