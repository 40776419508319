import {
  HookFieldWrapper,
  spacing1,
  spacing2,
  useControllerEx,
} from "@litbase/alexandria";
import NumberFormat from "react-number-format";
import styled from "styled-components";

export function NumberFormatInputField({
  name,
  label,
  format,
  mask,
  placeholder,
  className,
}: {
  name: string;
  label?: string;
  format: string;
  mask?: string;
  placeholder?: string;
  className?: string;
}) {
  const {
    field: { value, onChange },
  } = useControllerEx<string>({ name });

  return (
    <StyledHookFieldWrapper name={name} label={label} className={className}>
      <Field
        format={format}
        mask={mask}
        onValueChange={(value) => onChange(value.formattedValue)}
        value={value}
        placeholder={placeholder}
      />
    </StyledHookFieldWrapper>
  );
}

const Field = styled(NumberFormat)`
  width: 100%;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.375rem;
  color: white;
  padding: ${spacing1} ${spacing2};
  font-size: 14px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  &::placeholder {
    color: gray;
  }
`;

const StyledHookFieldWrapper = styled(HookFieldWrapper)``;
