export enum Paths {
  MAIN = "/",
  POLICY = "/policy",
  LOGIN = "/login",
  REGISTER = "/register",
  ADMIN_WELCOME = "/admin/welcome",
  STATS = "/stats",
  PROFILE = "/profilom",
  EVENT_SIGNUP = "/verseny-jelentkezes",
  ARSZ = "/arsz",
  STRAVA_POLICY = "/strava-tajekoztato",
  GYIK = "/gyik",
}
