import { MainStatsCard } from "./main-stats-card";
import { getMyTrainings, MyTrainings } from "./my-trainings";
import { BasicStatsCard, StatLabel, StatValue } from "./basic-stats-card";
import { CardTitle, Title } from "./common-styles";
import styled from "styled-components";
import {
  down,
  HeadCell,
  spacing2,
  spacing3,
  spacing4,
  useModal,
  T,
} from "@litbase/alexandria";
import { User, useUser } from "../../../hooks/use-user";
import { fetchResource, useResources } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import { useEffect, useMemo } from "react";
import { round, sumBy } from "lodash-es";
import { Training } from "../../../types/training";
import { formatDuration } from "../../../utils/format-duration";
import { verticalDown } from "../../../styles/theme-types";
import { StravaCallbackModal } from "./strava-confirmation-modal";
import { useLocation } from "react-router-dom";
import { Row } from "../event-signup/common-styles";
import { useObservable } from "@litbase/use-observable";
import { switchMap } from "rxjs/operators";
import { sendBackendRequest } from "../../../services/backend";
import { formatKilometer } from "../../../utils/format-kilometer";
import { Team } from "../../../types/team";

export function Dashboard() {
  const { showModal } = useModal();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/user/login/strava")) {
      showModal(<StravaCallbackModal location={location} />);
    }
  }, [location]);

  return (
    <>
      <Row>
        <RowTitle>
          <T>Teljesítmény</T>
        </RowTitle>
      </Row>
      <MainContentGrid>
        <MainStatsCard />
        <MyStatsCard />
        <TeamStatsCard />
        <MyTrainings />
      </MainContentGrid>
    </>
  );
}

function MyStatsCard() {
  const user = useUser();
  const kilometer = formatKilometer(user.field_osszes_megtett_tavolsag_ar);
  const [trainings] = useObservable(
    (inputs$) => inputs$.pipe(switchMap(() => getMyTrainings())),
    []
  );

  const timeSum = useMemo(() => {
    return sumBy(trainings, (elem: Training) => elem.duration);
  }, [trainings]);

  return (
    <StyledBasicStatsCard>
      <CardTitle>
        <T>Egyéni teljesítmény</T>
      </CardTitle>
      <StatValue>
        <T>Megtett km-ek:</T>
      </StatValue>
      <StatLabel>{kilometer} KM</StatLabel>
      <StatValue>
        <T>Sporttal töltött idő:</T>
      </StatValue>
      <StatLabel>{formatDuration(timeSum)}</StatLabel>
      <StatValue>
        <T>Elvégzett edzések:</T>
      </StatValue>
      <StatLabel>{user.field_osszes_tevekenyseg}</StatLabel>
    </StyledBasicStatsCard>
  );
}

function TeamStatsCard() {
  const user = useUser();
  const team = user.team;
  const [teamTrainings] = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(async () => {
          const json = await sendBackendRequest(
            "/moonshot_distance/stats/get_number_of_trainings_by_my_team"
          ).json();
          return json.count || 0;
        })
      ),
    0,
    []
  );
  const position = usePositionInMyTeam();

  return (
    <BasicStatsCard>
      <CardTitle>
        <T>Csapatos teljesítmény</T>
      </CardTitle>
      {team ? (
        <>
          <StatValue>
            <T>Megtett km-ek:</T>
          </StatValue>
          <StatLabel>
            {round(
              (team.attributes.field_osszes_megtett_tavolsag_ar || 0) / 1000,
              2
            ).toLocaleString("hu")}{" "}
            KM
          </StatLabel>
          <StatValue>
            <T>Csapaton belüli helyezésed:</T>
          </StatValue>
          <StatLabel>{position}</StatLabel>
          <StatValue>
            <T>Elvégzett edzések:</T>
          </StatValue>
          <StatLabel>{teamTrainings}</StatLabel>
        </>
      ) : (
        <>
          <StatValue>
            <T>Még nem csatlakoztál csapathoz</T>
          </StatValue>
        </>
      )}
    </BasicStatsCard>
  );
}

export function usePositionInMyTeam(inputTeam?: Team) {
  const user = useUser();
  const team = inputTeam || user?.team;

  const [teammates] = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(async ([team]) => {
          if (!team?.id) {
            return [];
          }

          const { includes } = await fetchResource({
            type: EntityType.team + "/" + team.id,
            filters: { include: "field_csapattagok" },
          });
          return includes || [];
        })
      ),
    [],
    [team]
  );

  return useMemo(() => {
    return (
      [...teammates]
        .sort(
          (a, b) =>
            (b.attributes.field_osszes_megtett_tavolsag_ar || 0) -
            (a.attributes.field_osszes_megtett_tavolsag_ar || 0)
        )
        .findIndex(
          (elem) =>
            elem.attributes.drupal_internal__uid === user.drupal_internal__uid
        ) + 1
    );
  }, [teammates]);
}

const RowTitle = styled(Title)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacing3};
  flex-grow: 1;
`;

const MainContentGrid = styled.div`
  min-height: 0;
  height: auto;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${spacing4};
  grid-column-gap: ${spacing4};

  width: 100%;
  ${MainStatsCard} {
    grid-column: span 2;
  }
  ${MyTrainings} {
    grid-column: span 2;
  }
  ${verticalDown("lg")} {
    grid-auto-flow: dense;
    grid-template-columns: 3fr 2fr;
    ${MyTrainings} {
      grid-column: 1 / span 1;
      grid-row: span 2;
    }
    ${BasicStatsCard} {
      grid-column: 2;
    }
    ${HeadCell} {
      padding-right: ${spacing2};
    }
  }

  ${down("lg")} {
    grid-template-columns: 1fr;
    ${MainStatsCard} {
      grid-column: auto;
    }
    ${MyTrainings} {
      grid-column: auto;
    }
  }
`;

const StyledBasicStatsCard = styled(BasicStatsCard)``;
