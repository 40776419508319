import {
  down,
  Restricted,
  spacing12,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
  useMeasure,
  useModal,
} from "@litbase/alexandria";
import styled, { css } from "styled-components";
import { Menu } from "@styled-icons/boxicons-regular";
import { animated, useSpring } from "react-spring";
import { CSSProperties, useRef, useState } from "react";
import { NavLinks } from "./nav-links";
import { Button } from "../../styles/common-styles";
import { LoginModal } from "../pages/login-modal";
import { Logo } from "./logo";
import { Container } from "../container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { User } from "@styled-icons/boxicons-solid";
import { useSubscription } from "@litbase/use-observable";
import { filter, fromEvent } from "rxjs";
import { useVisibleBlock } from "./block-indicator";

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [activeBlock] = useVisibleBlock();
  const location = useLocation();

  return (
    <OpacityBackground
      style={{
        background:
          activeBlock === 0 && location.pathname === "/"
            ? "rgba(0, 0, 0, 0)"
            : "rgba(0, 0, 0, 0.8)",
      }}
    >
      {process.env.NODE_ENV === "development" && <TestDiv />}
      <BodyContainer>
        <Body>
          <LogoWrapper onClick={() => window.scrollTo(0, 0)} as={Link} to={"/"}>
            <StyledLogo
              style={{
                opacity: activeBlock === 0 && location.pathname === "/" ? 0 : 1,
              }}
            />
          </LogoWrapper>
          <LeftItemsWrapper>
            <Restricted guest>
              <StyledLoginButton onClick={() => showModal(<LoginModal />)}>
                <T>Belépés</T>
              </StyledLoginButton>
            </Restricted>
            <Restricted>
              <StyledLoginButton onClick={() => navigate("/profilom")}>
                <StyledUser /> <T>Profilom</T>
              </StyledLoginButton>
            </Restricted>
            <MenuButton
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="mobile-menu-area"
            />
          </LeftItemsWrapper>
        </Body>
        <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      </BodyContainer>
    </OpacityBackground>
  );
}

function MobileMenu({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useMeasure((bounds) => {
    setHeight(bounds.height);
  }, ref);

  const [height, setHeight] = useState(0);
  const styles = useSpring({
    maxHeight: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    pointerEvents: (isOpen ? "all" : "none") as CSSProperties["pointerEvents"],
  });

  // If the menu is open, subscribe to all click events, and dismiss the menu when a click happens outside the menu.
  // All elements that should be considered part of the menu have the mobile-menu-area class
  useSubscription(() => {
    if (!isOpen) return;

    return fromEvent(document, "click", { capture: true })
      .pipe(
        filter(
          (event) => !(event.target as HTMLElement).closest(".mobile-menu-area")
        )
      )
      .subscribe((event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        onClose();
      });
  }, [isOpen]);

  return (
    <>
      <MenuBackground
        style={{ opacity: styles.opacity, pointerEvents: styles.pointerEvents }}
        className="mobile-menu-area"
      />
      <MobileMenuBody
        style={{ maxHeight: styles.maxHeight }}
        onClick={onClose}
        className="mobile-menu-area"
      >
        <StyledRefDiv ref={ref}>
          <MobileMenuContents>
            <NavLinks />
          </MobileMenuContents>
        </StyledRefDiv>
      </MobileMenuBody>
    </>
  );
}

const StyledRefDiv = styled.div`
  height: 100%;
`;

const MenuBackground = styled(animated.div)`
  position: fixed;
  width: 25%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.55);
  z-index: 0;
  ${down("lg")} {
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
  }
`;

const BodyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  z-index: 10;
`;

const activeNavbarStyles = css`
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  position: fixed;
  height: 85px;
  ${down("md")} {
    height: 70px;
  }
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(2px);
`;

const OpacityBackground = styled.div`
  ${activeNavbarStyles};
`;

const MobileMenuContents = styled.div`
  height: 100%;
  text-align: end;
  display: flex;
  flex-direction: column;
  padding: ${spacing6};
  > * + * {
    margin-top: ${spacing3};
  }
  ${down("lg")} {
    text-align: center;
  }
`;

const MobileMenuBody = styled(animated.div)`
  width: 25%;
  overflow: hidden;
  color: white;
  background: transparent;
  z-index: 1;
  ${down("lg")} {
    width: 100%;
  }
`;

const MenuButton = styled(Menu)`
  font-size: ${({ theme }) => theme.text4xl};
  cursor: pointer;
  color: white;
`;

const Body = styled(Container)`
  display: flex;
  align-items: center;
  padding: ${spacing4};
  background: transparent;
  z-index: 2;
  flex-direction: row;
`;

const LogoWrapper = styled.div``;

const LeftItemsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StyledLoginButton = styled(Button)`
  margin-right: ${spacing8};
  font-size: ${({ theme }) => theme.textBase};

  ${down("sm")} {
    display: none;
  }
`;

const StyledLogo = styled(Logo)`
  height: ${spacing12};
  color: white;
  height: 40px;
`;

const StyledUser = styled(User)`
  margin-right: ${spacing2};
`;
const TestDiv = styled.div`
  background-color: red;
  height: ${spacing4};
  width: 100%;
`;
