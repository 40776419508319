import styled from "styled-components";
import { useController } from "react-hook-form";
import { InputField } from "./input-field";
import {
  down,
  FieldLabel,
  spacing4,
  spacing6,
  T,
  useTranslate,
} from "@litbase/alexandria";
import { zip } from "lodash-es";
import { NumberFormatInputField } from "./number-format-input-field";

export function BirthdayField({
  name,
  label,
  className,
}: {
  name: string;
  label: string;
  className?: string;
}) {
  const controller = useController({ name });
  const translate = useTranslate();
  return (
    <Row className={className}>
      <Div>
        <FieldLabel>{label}</FieldLabel>
        <NumberFormatInputField
          name={name + ".year"}
          format={"####"}
          placeholder={translate("év")}
        />
      </Div>
      <StyledNumberFormatInputField
        name={name + ".month"}
        format={"##"}
        placeholder={translate("hónap")}
      />
      <NumberFormatInputField
        name={name + ".day"}
        format={"##"}
        placeholder={translate("nap")}
      />
      <Error>{controller.fieldState?.error?.message}</Error>
    </Row>
  );
}

export function transformToFormUsableByBirthdayField(date: Date | string) {
  return Object.fromEntries(
    zip(
      ["year", "month", "day"],
      new Date(date)
        .toLocaleDateString("hu") // looks like 1998.11.26
        .split(".")
        .map((elem) => Number(elem))
    ) // Sorry the birthday picker I ended up throwing together expects data to be in this format and I was too
    // lazy to make it reusable in a more comfortable fashion
  );
}

export function birthdayFieldValueToDate({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}) {
  const date = new Date(
    `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`
  );
  if (
    date.getMonth() !== month - 1 ||
    day != date.getDate() ||
    date.getFullYear() != year ||
    date > new Date() ||
    year < 1900
  ) {
    console.log(
      { year, month, day },
      { month: date.getMonth(), day: date.getDate(), year: date.getFullYear() },
      date
    );
    return null;
  }
  return date;
}

const Div = styled.div`
  white-space: nowrap;
`;

const Error = styled.span`
  color: red;
  font-size: 13px;
`;

const StyledNumberFormatInputField = styled(NumberFormatInputField)`
  margin-top: ${spacing6};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  > * + * {
    margin-left: ${spacing4};
  }

  ${down("md")} {
    display: block;
    > * + * {
      margin-left: 0;
    }
  }
`;
