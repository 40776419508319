import { ComponentProps, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  Carousel,
  down,
  spacing6,
  spacing8,
  T,
  transition,
} from "@litbase/alexandria";
import styled from "styled-components";
import { useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { useUser } from "../../../../hooks/use-user";
import { useController } from "react-hook-form";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";

export function ProfilePictureCarouselComponent({
  className,
}: {
  className?: string;
}) {
  const user = useUser();
  const [, , profilePictures] = useResources(EntityType.profilePicture, {
    filters: { include: "field_picture" },
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const controller = useController({ name: "field_profil_kep" });

  useEffect(() => {
    controller.field.onChange(profilePictures[activeIndex]?.attributes.uri.url);
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(
      Math.max(
        profilePictures.findIndex(
          (elem) => elem.attributes.uri.url === user.field_profil_kep
        ),
        0
      )
    );
  }, [profilePictures]);

  return (
    <Body className={className}>
      <label><T>Profilkép</T></label>
      <Row>
        <CarouselArrow
          as={ChevronLeft}
          onClick={() => setActiveIndex(Math.max(activeIndex - 1, 0))}
        />
        <StyledCarousel index={activeIndex} onIndexChange={setActiveIndex}>
          {profilePictures.map((picture, index) => (
            <SelectablePicture
              key={index}
              index={index}
              activeIndex={activeIndex}
              onClick={() => setActiveIndex(index)}
              src={process.env.BACKEND_URL + picture.attributes.uri.url}
            />
          ))}
        </StyledCarousel>
        <CarouselArrow
          as={ChevronRight}
          onClick={() => setActiveIndex(Math.min(activeIndex + 1, 12))}
        />
      </Row>
    </Body>
  );
}

export const ProfilePictureCarousel = styled(ProfilePictureCarouselComponent)``;

function SelectablePicture({
  index,
  activeIndex,
  src,
  ...props
}: {
  index: number;
  activeIndex: number;
  src: string;
} & ComponentProps<typeof SelectablePictureBody>) {
  const diff = Math.abs(index - activeIndex);
  const size = diff === 0 ? 80 : diff === 1 ? 60 : 40;

  const styles = useSpring({
    height: size,
    width: size,
    opacity: 1,
  });

  return (
    <ImageContainer>
      <ImageWrapper>
        <SelectablePictureBody
          {...props}
          $isActive={diff === 0}
          src={src}
          style={styles}
        />
      </ImageWrapper>
    </ImageContainer>
  );
}

const Body = styled.div`
  max-width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ImageContainer = styled.div`
  height: 100px;
  & + & {
    margin-left: ${spacing6};
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 550px;
  margin: 0 ${spacing8};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectablePictureBody = styled(animated.img)<{ $isActive?: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid
    ${({ theme, $isActive }) => ($isActive ? theme.blue : "white")};
  cursor: pointer;
  user-select: none;
  transition: ${({ theme }) =>
    transition(["border-color"], theme.defaultTransitionTiming)};
`;

const CarouselArrow = styled(ChevronLeft)`
  cursor: pointer;
  font-size: 40px;
  height: 40px;
  color: ${({ theme }) => theme.blue};

  &:hover {
    color: ${({ theme }) => theme.lightBlue};
  }

  ${down("md")} {
    display: none;
  }
`;
