import { HookInputField, spacing1, spacing2 } from "@litbase/alexandria";
import { ComponentProps } from "react";
import styled from "styled-components";

export function InputField(props: ComponentProps<typeof HookInputField>) {
  return <Field {...props} />;
}

const Field = styled(HookInputField)`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: white;
  padding: ${spacing1} ${spacing2};
  font-size: 14px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  &::placeholder {
    color: gray;
  }
`;
