import { hackilySubmitForm, sendBackendRequest } from "../services/backend";
import { BehaviorSubject } from "rxjs";
import { useObservable } from "@litbase/use-observable";
import { Team } from "../types/team";
import { fetchResource } from "./use-resources";
import { EntityType } from "../types/entity";
import { formatKilometer } from "../utils/format-kilometer";
import { CompetitionSignup } from "../types/competition-signup";
import { Competition, getEndsAt, getStartsAt } from "../types/competition";
import { maxBy } from "lodash-es";

export interface UserNode {
  attributes: {
    name: string;
    uid: string;
    field_osszes_megtett_tavolsag: number | null;
    drupal_internal__uid: number;
    field_adoszam: string | null;
    field_ajto: string | null;
    field_company: string | null;
    field_education: string | null;
    field_emelet: string | null;
    field_gender: string | null;
    field_hazszam: string | null;
    field_iranyitoszam: string | null;
    field_kozterulet_jellege: string | null;
    field_kozterulet_neve: string | null;
    field_lepcsohaz: string | null;
    field_name: "Arthur944";
    field_nationality: string | null;
    field_profession: string | null;
    field_source: string | null;
    field_strava_access_token: string | null;
    field_strava_refresh_token: string | null;
    field_strava_token_expires_at: string | null;
    field_szamlazasi_nev: string | null;
    field_telepules: string | null;
    field_country: string | null;
    field_phone: string | null;
    field_date_of_birth: string | null;
    field_osszes_tevekenyseg: number | null;
    created: string;
    field_osszes_megtett_tavolsag_ar: number | null;
  };
}

type UserAttributes = UserNode["attributes"];

export interface User extends UserAttributes {
  team?: Team;
  allTeams?: Team[];
  id: string;
  currentRace?: Competition;
}

export const emptyUser: User = {
  name: "empty@empty.empty",
  uid: "",
};

const guestUser: User = {
  name: "guest@guest.guest",
  uid: "",
};

export const currentUser$ = new BehaviorSubject<User>(emptyUser);

export function getCurrentUser() {
  return currentUser$.getValue();
}

export async function refreshLoggedInUserData() {
  try {
    const userData = await fetchCurrentUserData();
    currentUser$.next(userData);
    return;
  } catch (e) {
    console.error(e);
  }
  currentUser$.next(guestUser);
}

async function fetchCurrentUserData(): Promise<User> {
  const json = await sendBackendRequest("/jsonapi").json<any>();
  const id = json.meta?.links.me.meta.id;

  if (!id) {
    return guestUser;
  }

  const userData = await sendBackendRequest(
    `/jsonapi/user/user/${id}?include=field_csapataim`
  ).json<any>();
  const teamResponse = await fetchResource({
    type: EntityType.team,
    filters: {
      "[field_csapattagok.drupal_internal__uid]":
        userData.data.attributes.drupal_internal__uid,
      "[field_statusz]": "fizetett",
      include: "field_verseny",
    },
  });
  const teams: (Team & { competition: Competition })[] = teamResponse.hits.map(
    (team) => ({
      ...team,
      competition: teamResponse.includes.find(
        (competition) =>
          competition.id === team.relationships.field_verseny.data.id
      ),
    })
  );

  const currentTeam = teams.find(
    (elem) => getEndsAt(elem.competition) > new Date()
  );
  const raceResponse = await fetchResource({
    type: EntityType.competition,
  });

  const currentRace = raceResponse?.hits?.find(
    (elem: Competition) =>
      getStartsAt(elem) < new Date() && getEndsAt(elem) > new Date()
  );
  const data = userData.data;

  return {
    id,
    ...data.attributes,
    email: data.attributes.mail,
    team: currentTeam,
    allTeams: teams,
    currentRace,
  };
}

export function useUser(): User {
  const [user] = useObservable(() => currentUser$, emptyUser);
  return user;
}

export function isUserEmpty(user: User) {
  return user.name === emptyUser.name;
}

export function isUserGuest(user: User) {
  return user.name === guestUser.name;
}

export async function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  await hackilySubmitForm("/node/12", {
    name: email,
    pass: password,
  });

  await refreshLoggedInUserData();

  // return response;
}

export async function logout() {
  currentUser$.next(guestUser);

  await sendBackendRequest("/user/logout");
  localStorage.removeItem("name");
  localStorage.removeItem("uid");
}

// Refresh current user once when the site loads
refreshLoggedInUserData();

export function isUsersBalanceSettled(
  user: UserNode,
  signups: CompetitionSignup[]
) {
  const userSignups = signups.filter(
    (signup) =>
      signup.relationships.field_egyeni_versenyzo?.data?.id === user?.id
  );

  const latestSignup = maxBy(
    userSignups,
    (signup) => signup.attributes.created
  );

  return latestSignup?.attributes.field_statusz === "fizetett";
}

export function getUserDistance(user: UserNode) {
  return formatKilometer(user.attributes.field_osszes_megtett_tavolsag_ar);
}

export function isUserLeaderOfTeamPaymentCapableTeam(user: User) {
  // TODO: Rendesen
  return true;
}
