import { useEffect, useRef, useState } from "react";
import { useGesture } from "react-use-gesture";
import { useSpring } from "react-spring";
import useBreakpoint from "use-breakpoint";
import { noop } from "lodash-es";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, map, throttleTime } from "rxjs/operators";
import { Lethargy } from "lethargy";
import { ModalBackdrop, useMeasure } from "@litbase/alexandria";
import { findClosest } from "../utils/find-closes";
import {
  breakPointNumbers,
  breakPointsPixels,
  verticalBreakpoints,
} from "../styles/theme-types";
import { useMediaQuery } from "react-responsive";

export type ScrollingType = "normal" | "snapping";

// Initialize Lethargy for detecting inertial scrolling
// sensitivity is the speed the user must scroll for it to be detected as intentional. The default of 120 is too high
const lethargy = new Lethargy(null, 40);

// The last time the user tried to scroll over a scrollable element
let lastEncounteredScrollableElementAt = 0;

// The class applied to all FullHeightBlocks for easy lookup
export const fullHeightBlockClass = "full-height-block";

// Returns true if the given event can trigger a scroll snap
function canTargetElementScroll(event: Event, direction: number) {
  let target = event.target as HTMLElement;

  // If there was a keydown on the body, then the user just pressed a directional key in the browser. Change the
  // target to the currently displayed block (find the block at the center of the screen)
  if (event.type === "keydown" && target.tagName === "BODY") {
    // Get the element at the center of the screen
    const centerElement = document.elementFromPoint(
      window.innerWidth / 2,
      window.innerHeight / 2
    );
    // Find the block the element belongs to
    const currentBlock = centerElement?.closest("." + fullHeightBlockClass);

    if (currentBlock) target = currentBlock as HTMLElement;
  }

  // Check if the event target element is inside a scrollable element. If it is, we can't scroll the body as the
  // element needs to be scrolled instead. We only consider elements that can be further scrolled in the current
  // direction.
  const scrollableElement = findClosest(target, (el) => {
    // html and body tags don't count
    if (el.tagName === "HTML" || el.tagName === "BODY") return false;

    // If we're inside a modal, scroll snapping must be prevented
    if (el.classList.contains(ModalBackdrop.toString().slice(1))) return true;

    // If overflow is visible or hidden, the element can't be scrolled
    const elementOverflowY = window.getComputedStyle(el).overflowY;
    if (elementOverflowY === "visible" || elementOverflowY === "hidden")
      return false;

    // If the content is less tall, than the element, then the element can't be scrolled
    if (el.scrollHeight <= el.clientHeight) return false;

    let isScrollable = false;

    if (-1 === direction && 1 < el.scrollTop) {
      // If we're scrolling up and the element is not fully scrolled up, it can scroll
      isScrollable = true;
    } else if (
      1 === direction &&
      el.scrollTop < el.scrollHeight - el.clientHeight - 1
    ) {
      // If we're scrolling down and the element still has some height to scroll, it can scroll
      isScrollable = true;
    }

    return isScrollable;
  });

  if (scrollableElement) {
    // If the event happened in a scrolled element, store the timestamp to throttle future events a bit
    lastEncounteredScrollableElementAt = event.timeStamp;

    // If the event was a keypress and it happened in a scrollable element, scroll the element a bit as a
    // consequence. This way. the user will eventually be able to scroll snap further.
    if (
      event.type === "keydown" &&
      scrollableElement.matches("." + fullHeightBlockClass)
    ) {
      scrollableElement.scrollBy({
        top: direction === -1 ? -66 : +66,
        behavior: "auto",
      });
    }

    return true;
  } else {
    // Prevent scrolling for a bit after encountering a scrollable element
    return event.timeStamp - lastEncounteredScrollableElementAt <= 600;
  }
}

// Returns true if we're inside an input element
function isInsideTextField(event: Event) {
  const target = event.target as HTMLElement;

  return target.tagName === "INPUT" || target.tagName === "TEXTAREA";
}

// Returns the current scroll top rounded along screen height
function getSnappedScrollTop() {
  return Math.round(window.scrollY / window.innerHeight) * window.innerHeight;
}

// Moves the current scroll position to a scroll snap allowed one
function fixSnapPosition() {
  window.scrollTo({ top: getSnappedScrollTop(), behavior: "auto" });
}

export function useScrollingType(type: ScrollingType) {
  const { breakpoint } = useBreakpoint(breakPointNumbers, "sm");
  const isAnimatingRef = useRef(false);
  const isVerticallySmall = useMediaQuery({
    query: `(max-height: ${verticalBreakpoints.sm}px)`,
  });
  console.log({ isVerticallySmall });

  // Only enable on desktop sized screens
  const isScrollSnappingEnabled =
    type === "snapping" &&
    ["xl", "xxl"].includes(String(breakpoint)) &&
    !isVerticallySmall;

  const [, animate] = useSpring(
    () => ({
      scrollTop: 0,
      onStart() {
        isAnimatingRef.current = true;
      },
      onChange(value: { value: { scrollTop: number } }) {
        window.scrollTo(0, value.value.scrollTop);
      },
      onRest() {
        isAnimatingRef.current = false;
      },
    }),
    []
  );

  function handleScrollEvent(
    event: Event,
    directionY: number,
    forceScroll = false
  ) {
    // Ignore events during animation
    if (isAnimatingRef.current) return;

    // Check if we're over a scrollable element or not
    if (!forceScroll && canTargetElementScroll(event, directionY)) return;

    // Get the current scroll top (rounded for safety)
    let targetScroll = getSnappedScrollTop();

    // Move the scroll by a screen
    if (0 < directionY) {
      targetScroll += window.innerHeight;
    } else {
      targetScroll -= window.innerHeight;
    }

    // If the cursor is currently focused in a scrollable element, remove that focus, so the element doesn't get
    // scrolled off-screen
    document.getSelection()?.removeAllRanges();

    // Actually animate the scroll. This is smoother than calling window.scrollTo().
    animate({
      async to(next) {
        document.documentElement.classList.add("snapping-auto-scroll-behavior");

        // First make sure we're at the right spot...
        await next({ to: { scrollTop: window.scrollY }, immediate: true });

        // ... then scroll!
        await next({ to: { scrollTop: targetScroll } });

        document.documentElement.classList.remove(
          "snapping-auto-scroll-behavior"
        );
      },
    });
  }

  useGesture(
    {
      onWheel({ event }) {
        const scrollDirection = lethargy.check(event as WheelEvent);

        if (false === scrollDirection) {
          // Wheel event was identified as an inertia scroll, not a real one
          return;
        }

        handleScrollEvent(event as Event, -scrollDirection);
      },
      onKeyDown({ event }) {
        if (isInsideTextField(event)) return;

        const keyboardEvent = event as KeyboardEvent;

        let directionY = 0;
        let forceScroll = false;

        // Convert key pressed into their directions
        switch (keyboardEvent.key) {
          case "PageUp":
            directionY = -1;
            forceScroll = true;
            break;
          case "ArrowUp":
            directionY = -1;
            break;
          case "PageDown":
            directionY = 1;
            forceScroll = true;
            break;
          case "ArrowDown":
            directionY = 1;
            break;
          default:
            return;
        }

        handleScrollEvent(event, directionY, forceScroll);
      },
    },
    {
      domTarget: document,
      enabled: isScrollSnappingEnabled,
      eventOptions: { capture: true },
    }
  );

  const lastIsScrollSnappingEnabledRef = useRef(isScrollSnappingEnabled);
  useEffect(() => {
    // Only disable scrolling when we're on the right screen size
    if (isScrollSnappingEnabled) {
      document.documentElement.classList.add("scroll-snapping-enabled");

      // If the user just changed the screen size and scroll snapping got enabled, fix the scroll position, if
      // it's not right at block boundaries
      if (!lastIsScrollSnappingEnabledRef.current) {
        fixSnapPosition();
      }

      lastIsScrollSnappingEnabledRef.current = true;

      // If the user resizes the window's height, fix the scroll position according to tne new height
      const subscription = fromEvent(window, "resize", { passive: true })
        .pipe(
          // Don't update too often, as this can cause lag and freezes during resizing
          throttleTime(250, undefined, { leading: false, trailing: true }),
          map(() => window.innerHeight),
          distinctUntilChanged()
        )
        .subscribe(() => fixSnapPosition());

      return () => {
        document.documentElement.classList.remove("scroll-snapping-enabled");

        subscription.unsubscribe();
      };
    } else {
      lastIsScrollSnappingEnabledRef.current = false;
      return noop;
    }
  }, [isScrollSnappingEnabled]);
}
