import styled from "styled-components";
import { Card } from "./common-styles";
import { useResources } from "../../../hooks/use-resources";
import {
  Competition,
  getStartsAt,
  getEndsAt,
} from "../../../types/competition";
import { EntityType } from "../../../types/entity";
import { formatInterval } from "../../../utils/format-interval";
import { verticalDown } from "../../../styles/theme-types";
import { down, spacing4, T, useTranslate } from "@litbase/alexandria";

function MainStatsCardComponent({ className }: { className?: string }) {
  const results = useCurrentCompetitionData();
  if (!results) {
    return (
      <Body className={className}>
        <Label>
          <T>Nem található kiírt verseny</T>
        </Label>
      </Body>
    );
  }
  const { isOngoing, timeLeft, distance } = results;
  return (
    <Body className={className}>
      {/* Ez fordításnál próbléma lesz, régen "Aktuális versenyen megtett kilométerek" voltak ideírva, ehez is lett készítve
          a fordítás. Ha az bemergelődik, ezt majd meg kell változtatni. */}
      {isOngoing && (
        <Label>
          <T>Aktuális versenyen együtt megtett kilométerek</T>
        </Label>
      )}
      <Label>
        {isOngoing ? (
          <T>Aktuális versenyből hátravan</T>
        ) : (
          <T>Következő versenyig hátravan</T>
        )}
      </Label>
      {isOngoing && (
        <Value>
          {(Math.round((distance / 1000) * 100) / 100).toLocaleString("hu")} KM
        </Value>
      )}
      <Value>{timeLeft}</Value>
    </Body>
  );
}

export const MainStatsCard = styled(MainStatsCardComponent)``;

const Value = styled.span`
  font-size: 36px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  font-weight: bold;
  ${verticalDown("lg")} {
    font-size: 28px;
  }
`;

const Label = styled.span`
  font-size: 18px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  & + & {
    margin-left: ${spacing4};
  }
`;

const Body = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  grid-auto-rows: 1fr;
  ${down("sm")} {
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    ${Value}:not(:last-child) {
      margin-bottom: ${spacing4};
    }
    ${Label}:nth-child(2) {
      grid-row: 3;
      margin-left: 0;
    }
  }
`;

export function useCurrentCompetitionData(): {
  isOngoing: boolean;
  distance: number;
  timeLeft: string;
} | null {
  const [events, loading] = useResources<Competition>(EntityType.competition);
  const translate = useTranslate();
  if (loading) {
    return null;
  }
  const currentEvent = getCurrentEvent(events);
  const isOngoing = !!currentEvent;
  const event =
    currentEvent || events.sort((a, b) => getStartsAt(a) - getStartsAt(b))[0];
  if (!event) {
    return null;
  }
  return {
    isOngoing,
    distance: event.attributes.field_osszes_megtett_tavolsag_ar || 0,
    timeLeft: formatInterval(
      getStartsAt(event) < new Date() ? getEndsAt(event) : getStartsAt(event),
      translate
    ),
  };
}

function getCurrentEvent(events: Competition[]) {
  return events.find(
    (elem) =>
      getStartsAt(elem) < new Date() &&
      new Date(elem.attributes.field_verseny_idoszaka.end_value) > new Date()
  );
}
