export function getImageUrl({
  src,
  width,
  height,
  gravity,
  isAdmin,
}: {
  src: string;
  width?: number;
  height?: number;
  gravity?: string;
  isAdmin?: boolean;
}) {
  if (import.meta.env.DEV) return (isAdmin ? "/api" : "") + src;

  const params = new URLSearchParams();
  params.set("url", `https://${location.host}${isAdmin ? "/api" : ""}` + src);

  if (width) {
    params.set("width", width + "");
  }

  if (height) {
    params.set("height", height + "");
  }

  if (gravity) {
    params.set("gravity", gravity);
  }

  params.set("type", "auto");

  const operation = width || height ? "crop" : "convert";

  return `https://thumb.litbase.hu/${operation}?${params.toString()}`;
}
