import { EyeWrapper, StyledHookInputField } from "../pages/login-modal";
import { Hide, Show } from "@styled-icons/boxicons-solid";
import styled from "styled-components";
import { useState } from "react";
import { HookInputFieldProps, spacing6 } from "@litbase/alexandria";

export function PasswordField(props: HookInputFieldProps) {
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  return (
    <PasswordFieldWrapper>
      <StyledHookInputField
        type={isPasswordHidden ? "password" : "text"}
        {...props}
      />
      <EyeWrapper
        to=""
        onClick={(event) => {
          event.preventDefault();
          setIsPasswordHidden(!isPasswordHidden);
        }}
      >
        {isPasswordHidden ? <Show /> : <Hide />}
      </EyeWrapper>
    </PasswordFieldWrapper>
  );
}

const PasswordFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${spacing6};
  margin-bottom: ${spacing6};
`;
