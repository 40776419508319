import { ReactElement } from "react";

export function switchValue(
  value: unknown,
  entries: Record<string, ReactElement>
): ReactElement | null;
export function switchValue(
  value: unknown,
  entries: [unknown, ReactElement][]
): ReactElement | null;
export function switchValue(
  value: unknown,
  entries: Record<string, unknown> | [unknown, ReactElement][]
) {
  if (Array.isArray(entries)) {
    for (const [entryValue, entryElement] of entries) {
      if (value === entryValue) {
        return entryElement;
      }
    }
    return null;
  } else {
    return entries[String(value)] || null;
  }
}
