import styled from "styled-components";
import profileBg from "../../../assets/profile_bg.png";
import { Container, Page } from "../../container";
import { Sidebar } from "./sidebar";
import { down, spacing12, spacing6 } from "@litbase/alexandria";
import { Outlet } from "react-router-dom";
import { verticalDown } from "../../../styles/theme-types";
import { ReactNode } from "react";
import { getImageUrl } from "../../../utils/get-image-url";

export function MyProfilePage({ children }: { children?: ReactNode }) {
  return (
    <Body>
      <ContainerRow>
        <Sidebar />
        <Col>{children}</Col>
      </ContainerRow>
    </Body>
  );
}

const Col = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  ${down("md")} {
    display: block;
  }
`;

const ContainerRow = styled(Container)`
  display: flex;
  flex-direction: row;
  overflow: hidden !important;
  height: fit-content;
  > div + div {
    margin-left: ${spacing12};
  }
  ${down("lg")} {
    display: block;
    overflow: auto;
    flex-direction: column;
    padding: ${spacing6};
    padding-left: 2rem;
    padding-right: 2rem;
    > div + div {
      margin-left: 0;
    }
  }
  padding-right: 2rem;
`;

const bg = getImageUrl({ src: profileBg, width: 1920, height: 1080 });

const Body = styled(Page)`
  background: url("${bg}");
  height: 100%;
  padding-bottom: ${spacing12};

  ${verticalDown("lg")} {
    max-height: 100%;
  }

  ${down("md")} {
    padding-bottom: 0;
  }
`;
