import { RefObject, useContext, useEffect, useState } from "react";
import { useMeasure } from "@litbase/alexandria";
import { ConnectingArrow } from "./connecting-arrow";
import { SvgContext } from "./connection-container";
import { fromEvent, merge, Observable } from "rxjs";
import { share, startWith, throttleTime } from "rxjs/operators";
import { useSubscription } from "@litbase/use-observable";

const bodyHeight$ = new Observable((observer) => {
  const resizeObserver = new ResizeObserver(([entry]) => {
    if (entry) {
      observer.next(entry);
    }
  });

  resizeObserver.observe(document.body);

  return () => resizeObserver.disconnect();
}).pipe(share({ resetOnRefCountZero: false }));

const obsi = merge(
  fromEvent(window, "resize"),
  fromEvent(window, "load"),
  bodyHeight$
).pipe(
  startWith(null),
  throttleTime(500, undefined, { leading: false, trailing: true }),
  share()
);

export function DivConnection({
  fromDiv,
  toDiv,
  fromX = 1,
  fromY = 0.2,
  toX = 0,
  toY = 0.2,
  curvyness = 150,
  start,
  springProps,
}: {
  fromDiv: RefObject<HTMLDivElement>;
  toDiv: RefObject<HTMLDivElement>;
  fromX?: number;
  fromY?: number;
  toX?: number;
  toY?: number;
  curvyness?: number;
  start?: boolean;
  springProps?: Record<string, unknown>;
}) {
  const { top } = useContext(SvgContext);
  const fromBounds = useBounds(fromDiv);
  const toBounds = useBounds(toDiv);

  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!start) {
      let a = new IntersectionObserver(([entry]) =>
        setInView(entry.isIntersecting)
      );
      a.observe(toDiv.current);
    }
  }, []);

  return (
    <ConnectingArrow
      springProps={springProps}
      startX={fromBounds.left + fromBounds.width * fromX}
      startY={fromBounds.top + fromBounds.height * fromY + top}
      endX={toBounds.left + toBounds.width * toX - 20}
      endY={toBounds.top + toBounds.height * toY}
      curvyness={curvyness}
      inView={start === undefined ? inView : start}
    />
  );
}

function useBounds(ref: RefObject<HTMLElement>) {
  function refresh() {
    const rect = ref.current?.getBoundingClientRect();
    if (!rect) return;
    setBounds({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height,
    });
  }

  useSubscription(() => obsi.subscribe(refresh));

  const [bounds, setBounds] = useState({
    width: 0,
    left: 0,
    top: 0,
    height: 0,
  });
  useMeasure((bounds) => {
    refresh();
  }, ref);
  return bounds;
}
