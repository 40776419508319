import {
  baseGlobalStyle,
  createTheme,
  down,
  FontWeight,
  ModalBackdrop,
  spacing16,
  spacing5,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import styled, { createGlobalStyle, css } from "styled-components";
import { fonts } from "./fonts";
import { getImageUrl } from "../utils/get-image-url";
import heroBackgroundImage from "../assets/modal-bg.png";

export const breakPointsPixels = {
  xs: "400",
  sm: "576",
  md: "768",
  lg: "992",
  xl: "1200",
  xxl: "1440",
};

export const verticalBreakpoints = {
  sm: 620,
  lg: 720,
  xl: 840,
} as const;

export const breakPointNumbers = Object.fromEntries(
  Object.entries(breakPointsPixels).map(([key, value]) => [key, Number(value)])
);

export const theme = createTheme({
  primary100: "hsl(263,48%,95%)",
  primary200: "hsl(261,46%,87%)",
  primary300: "hsl(262,46%,79%)",
  primary400: "hsl(262,46%,63%)",
  primary500: "#B1F6FF",
  primary600: "hsl(262,52%,42%)",
  primary700: "#3D103E",
  primary800: "hsl(261,52%,21%)",
  primary900: "hsl(262,53%,14%)",

  shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

  black: "#0C093F",
  dark: "#150F6B",
  medium: "#4338CA",
  light: "#5046E5",
  secondaryText: "white",
  white: "#F9FAFC",
  whitesmoke: "#F5F7FA",
  gray: "#e2e3e5",
  lightGray: "#C6C5DD",

  lightBlue: "#C6BDFF",
  orange: "#FA5201FF",

  blue: "#6534ff",

  darkGray: "#767678",
  lightPurple: "#C6BDFF",
  lightYellow: "#FFF280",

  fontFamilyBase: "Open Sans",
  headingsFontFamily: "Montserrat",
});

export type ThemeInterface = typeof theme;

export const montserrat = css`
  font-family: "Montserrat";
`;
const commonScrollbarStyle = css`
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const darkScrollbarStyle = css`
  ${commonScrollbarStyle};

  scrollbar-color: white transparent;

  &::-webkit-scrollbar-thumb {
    background: white;
  }
`;

export const lightScrollbarStyle = css`
  ${commonScrollbarStyle};

  scrollbar-color: #888 transparent;

  &::-webkit-scrollbar-thumb {
    background: #888;
  }
`;

export const scrollbarStylesMixin = css<{ $backgroundTone?: "light" | "dark" }>`
  ${({ $backgroundTone = "light" }) =>
    $backgroundTone === "dark" ? darkScrollbarStyle : lightScrollbarStyle};
`;

const herobg = getImageUrl({
  src: heroBackgroundImage,
  width: 1080,
  height: 1920,
});

export const GlobalStyle = createGlobalStyle`
  ${baseGlobalStyle};
  ${fonts};
  
  ${ModalBackdrop} {
    @supports (-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px)) {
      backdrop-filter: blur(4px);
    }
    
    @supports not (-webkit-backdrop-filter: blur(4px)) {
      background: rgba(0,0,0,0.5);
    }
  }

  html.scroll-snapping-enabled {
    overflow: hidden;
  }
  
  * {
    ${scrollbarStylesMixin};
    scroll-behavior: auto;
  }
  
  body {
    min-height: 100vh;
    font-family: ${({ theme }) => theme.fontFamilyBase};
    background: black;
  }

  input {
    outline: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.white};
    font-weight: ${FontWeight.Bold};
    text-transform: uppercase;
  }
  
  h1 {
    display: flex;
    flex-direction: column;
    width: fit-content;
    font-size: 36px;
    margin-bottom: ${spacing6};
    ${down("lg")}{
      font-size: 24px;
    }
  }
  h2 {
    &:not(:first-child){
      margin-top: ${spacing8};
    }
    font-size: 24px;
  }

  .tippy-content {
    font-size: ${({ theme }) => theme.textSm};
  }
  
  ul {
    padding-left: ${spacing5};
  }
  
  p {
    color: ${({ theme }) => theme.secondaryText};
    max-width: 800px;
    font-weight: ${FontWeight.Medium};
    text-align: center;
  }
  
  a {
    font-weight: bold;
  }
  
  section {
    > * {
      max-width: ${breakPointsPixels.xl}px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${spacing16};
    &:nth-child(2n){
      background: ${({ theme }) => theme.whitesmoke};
    }
    &:nth-child(2n+1){
      background: ${({ theme }) => theme.white};
    }
  }
  
  button {
    text-transform: uppercase;
  }
  
  label {
    color: white;
  }
  
  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast {
    background: url("${herobg}");
    border: 1px solid white;
    color: white;
    > div > div > div > div > div {
      color: white !important;
    }
  }
  
  .Toastify__close-button {
    color: white;
    path {
      fill: white;
    }
  }
  
  input {
    font-size: 16px !important;
  }
  input[type="checkbox"]{
    min-width: 16px;
  }
`;

export const CenteredTitle = styled.h1`
  margin-left: auto;
  margin-right: auto;
  &:after {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CenteredSubtitle = styled.span`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  color: ${({ theme }) => theme.secondaryText};
`;

export function verticalDown(input: keyof typeof verticalBreakpoints) {
  return `@media (min-width: ${breakPointsPixels.xl}px) and (max-height: ${verticalBreakpoints[input]}px)`;
}
