import {
  getUserDistance,
  isUsersBalanceSettled,
  User,
  UserNode,
  useUser,
} from "../../../../hooks/use-user";
import { Card, CardTitle } from "../common-styles";
import styled from "styled-components";
import { spacing, spacing2, T, useModal } from "@litbase/alexandria";
import { useResource, useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { ReactNode, useMemo, useState } from "react";
import defaultProfilePicture from "../profile-settings/assets/default_profilkep.svg";
import moneyIcon from "./assets/money-icon.svg";
import {
  Button,
  GreenButton,
  RedButton,
} from "../../../../styles/common-styles";
import { TeamPaymentModal } from "./team-payment-modal";
import { Team } from "../../../../types/team";
import { Trash } from "@styled-icons/boxicons-regular";
import { sendBackendRequest } from "../../../../services/backend";
import { useAreWeBetweenRaces } from "../../../../hooks/use-are-we-between-races";
import { Row } from "../../event-signup/common-styles";
import { CompetitionSignup } from "../../../../types/competition-signup";
import { DollarCircle } from "@styled-icons/boxicons-solid";

export function useTeammates(team?: Team): [UserNode[], boolean] {
  const user = useUser();
  const [results, loading] = useResource<{
    attributes: User;
    included: UserNode[];
  }>(EntityType.team, (team || user.team)?.id + "?include=field_csapattagok");
  return [results?.included || [], loading];
}

export function MyTeammatesCard() {
  const user = useUser();
  const team = user.team;
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [teammates] = useTeammates();
  const [signups] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filters: {
        "[field_egyeni_versenyzo.drupal_internal__uid]":
          user.drupal_internal__uid,
        "[field_verseny.id]": team?.relationships?.field_verseny?.data?.id,
      },
    }
  );

  if (!team) return null;

  const weAreTheBoss =
    team.relationships.field_csapatkapitany.data.id === user.id;

  const isPaymentPending =
    signups.some(
      (elem) => elem.attributes.field_statusz === "fizetes_fuggoben"
    ) && !signups.some((elem) => elem.attributes.field_statusz === "fizetett");

  if (
    !weAreTheBoss &&
    isPaymentPending &&
    team.attributes.field_csoportos_fizetes
  ) {
    return (
      <RedDiv>
        <CardTitle>
          <T>pendingSignup</T>
        </CardTitle>
        <p>
          <T>pendingSignup2</T>
        </p>
      </RedDiv>
    );
  }

  const showTeamPaymentTools =
    team.attributes.field_csoportos_fizetes && weAreTheBoss;

  const renderedTeammates =
    teammates.filter(
      (elem) => !deletedUsers.some((usr) => usr.id === elem.id)
    ) || [];

  return (
    <TeammatesCard
      teammates={renderedTeammates}
      showHasPayed={showTeamPaymentTools}
      isDeleting={isDeleting}
      onDelete={(user) => setDeletedUsers((prev) => [...prev, user])}
    >
      {showTeamPaymentTools && (
        <TeamAdminTools
          teammates={renderedTeammates}
          team={team}
          onStartDelete={() => setIsDeleting((prev) => !prev)}
        />
      )}
    </TeammatesCard>
  );
}

const RedDiv = styled.div`
  padding: ${spacing._4};
  background: rgba(255, 0, 0, 0.2);
  border: 1px solid #ea0000;
  border-radius: ${({ theme }) => theme.roundedLg};
  height: fit-content;
  color: white;

  ${CardTitle} {
    font-size: ${({ theme }) => theme.textLg};
    margin-bottom: ${spacing._4};
  }

  p {
    text-align: start;
  }
`;

export function TeammatesCard({
  teammates,
  children,
  showHasPayed = false,
  isDeleting,
  onDelete,
}: {
  teammates: UserNode[];
  children?: ReactNode;
  showHasPayed?: boolean;
  isDeleting?: boolean;
  onDelete: (user: UserNode) => void;
}) {
  const orderedMates = useMemo(() => {
    return [...(teammates || [])].sort(
      (a, b) => new Date(b.attributes.created) - new Date(a.attributes.created)
    );
  }, [teammates]);

  const signups = useSignups(teammates || []);

  return (
    <StyledCard>
      <CardTitle>
        <T>Csapattagok</T>
      </CardTitle>
      {orderedMates.map((elem, index) => (
        <UserRow
          key={index}
          user={elem}
          showHasPayed={showHasPayed}
          isDeleting={isDeleting}
          hasPayed={isUsersBalanceSettled(elem, signups)}
          onDelete={() => onDelete(elem)}
        />
      ))}
      {children}
    </StyledCard>
  );
}

export function UserRow({
  user,
  showHasPayed = false,
  isDeleting,
  hasPayed,
  onDelete,
}: {
  user: UserNode;
  showHasPayed?: boolean;
  isDeleting?: boolean;
  hasPayed?: boolean;
  onDelete?: () => void;
}) {
  const currentUser = useUser();
  const { showModal, dismissModal } = useModal();
  const loggedInUser = useUser();
  const profilePic = user.attributes.field_profil_kep
    ? process.env.BACKEND_URL + user.attributes.field_profil_kep
    : defaultProfilePicture;

  const weAreTheBoss =
    currentUser?.team?.relationships.field_csapatkapitany.data.id ===
    currentUser.id;

  const showGreenMoney = weAreTheBoss && showHasPayed && !hasPayed;

  return (
    <UserRowBody $showGreen={showGreenMoney}>
      {isDeleting && !hasPayed && (
        <DeleteIcon
          onClick={() => {
            showModal(
              <Card>
                <CardTitle>
                  Biztosan eltávolítod a {user.attributes.field_name}{" "}
                  felhasználót?
                </CardTitle>
                <Row>
                  <Button
                    onClick={async () => {
                      onDelete?.();
                      dismissModal();
                      await deleteUserFromTeam(user, loggedInUser.team);
                    }}
                  >
                    Igen
                  </Button>
                  <Button
                    onClick={() => {
                      dismissModal();
                    }}
                  >
                    Nem
                  </Button>
                </Row>
              </Card>
            );
          }}
        />
      )}
      <Img src={profilePic} $showGreen={showGreenMoney} />
      <span>{user.attributes.field_name}</span>
      {showGreenMoney &&
        currentUser.team?.attributes?.field_csoportos_fizetes && (
          <MoneyIconImg />
        )}
      <KM>{getUserDistance(user)} KM</KM>
    </UserRowBody>
  );
}

async function deleteUserFromTeam(user: UserNode, team: Team) {
  const teamId = team.attributes.drupal_internal__nid;
  const userId = user.attributes.drupal_internal__uid;
  await sendBackendRequest(
    `/moonshot/user-delete-from-team/${teamId}/${userId}`,
    { method: "POST" }
  );
}

const DeleteIcon = styled(Trash)`
  margin-right: 1rem;
  color: red;
  cursor: pointer;
`;

export function TeamAdminTools({
  teammates,
  team,
  onStartDelete,
}: {
  teammates: UserNode[];
  team: Team;
  onStartDelete: () => void;
}) {
  const { showModal } = useModal();

  const signups = useSignups(teammates || []);

  const unpaidTeammates = useMemo(() => {
    return teammates.filter((elem) => !isUsersBalanceSettled(elem, signups));
  }, [teammates, signups]);

  const areWeBetweenRaces = useAreWeBetweenRaces();

  return (
    <Col>
      {!!unpaidTeammates.length && (
        <GreenButton
          onClick={() =>
            showModal(<TeamPaymentModal users={unpaidTeammates} team={team} />)
          }
        >
          <T>payOffBefore</T>
          {unpaidTeammates.length} <T>payOffAfter</T>
        </GreenButton>
      )}
      {areWeBetweenRaces && (
        <RedButton onClick={onStartDelete}>
          <T>Csapattag eltávolítása</T>
        </RedButton>
      )}
    </Col>
  );
}

function useSignups(users: UserNode[]) {
  const [signups] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filters: {
        "[versenyzo_filter][condition][path]":
          "field_egyeni_versenyzo.drupal_internal__uid",
        "[versenyzo_filter][condition][operator]": "IN",
        ...Object.fromEntries(
          (users.length ? users : [{ id: "lol" }]).map((elem, index) => [
            `[versenyzo_filter][condition][value][${index + 1}]`,
            elem.attributes?.drupal_internal__uid,
          ])
        ),
      },
    }
  );
  return signups || [];
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing._6};
  > * + * {
    margin-top: ${spacing._6};
  }
`;

const MoneyIconImg = styled(DollarCircle)`
  width: 25px;
  height: 25px;
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img<{ $showGreen?: boolean }>`
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 50%;
  object-fit: cover;
  margin-right: ${spacing2};
  ${({ $showGreen }) => !$showGreen && `border-color: #4ECB71;`};
`;

const KM = styled.span`
  margin-left: auto;
`;

const UserRowBody = styled.div<{ $showGreen?: boolean }>`
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  padding: ${spacing2} 0;
  ${({ $showGreen }) => $showGreen && `color: #4ECB71;`};
`;
