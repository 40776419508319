import { Fragment, ReactNode } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { cardStyles } from "../common-styles";
import { tabSpacing } from "./common-styles";
import { forMap } from "@litbase/alexandria/utils/util-functions";
import { down } from "@litbase/alexandria";

export function EventTabContent({
  children,
  activeIndex,
  numberOfEvents,
}: {
  children: ReactNode;
  activeIndex: number;
  numberOfEvents: number;
}) {
  return (
    <EventTabContentWrapper>
      <BackgroundBorder>
        {forMap(0, numberOfEvents, (index) => (
          <Fragment key={index}>
            <BorderElem
              nElement={numberOfEvents}
              isMain={true}
              isActive={activeIndex === index}
            />
            {index < numberOfEvents - 1 && (
              <BorderElem nElement={numberOfEvents} />
            )}
          </Fragment>
        ))}
      </BackgroundBorder>
      {children}
    </EventTabContentWrapper>
  );
}

function BorderElem({
  isActive,
  isMain,
  nElement,
}: {
  isActive?: boolean;
  isMain?: boolean;
  nElement: number;
}) {
  const style = useSpring({
    borderTop: isActive
      ? "0px solid rgba(255,255,255,0)"
      : "1px solid rgba(255,255,255,1)",
  });

  return (
    <BorderElemBody
      $isActive={!!isActive}
      $isMain={isMain}
      style={{
        ...style,
        width: isMain
          ? `calc((100% - (${nElement - 1} * ${tabSpacing})) / ${nElement})`
          : tabSpacing,
        ...(!isMain ? { opacity: 1 } : {}),
      }}
    />
  );
}

const BorderElemBody = styled(animated.div)<{
  $isMain: boolean;
  $isActive: boolean;
}>`
  &:last-child {
    border-top-right-radius: ${({ theme, $isActive }) =>
      $isActive ? "0" : theme.roundedLg};
    border-bottom-right-radius: ${({ theme }) => theme.roundedLg};
    border-right: 1px solid white;
  }
  &:first-child {
    border-left: 1px solid white;
    border-top-left-radius: ${({ theme, $isActive }) =>
      $isActive ? "0" : theme.roundedLg};
    border-bottom-left-radius: ${({ theme }) => theme.roundedLg};
  }
  border-top: 1px solid white;
`;

const BackgroundBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  border-radius: ${({ theme }) => theme.roundedLg};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid white;
  border-top: none;
  border-right: none;
  border-left: none;
`;

export const EventTabContentWrapper = styled.div`
  ${cardStyles};
  border: none;
  color: white;
  margin-top: 0;
  position: relative;
`;
