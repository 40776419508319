import { distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { sendBackendRequest } from "../services/backend";
import { useState } from "react";
import { useObservable } from "@litbase/use-observable";
import { EntityType } from "../types/entity";

export interface PaginationInfo {
  page: number;
  pages: number;
  prevPage?: number;
  nextPage?: number;
}

type ResourceResponse<T> = T[];

export interface FetchParams {
  limit: number;
  query: string;
  page: number;
  filters?: Record<string, string | number>;
}

export function useResources<T>(
  type: EntityType,
  options?: Partial<FetchParams>
): [T[], boolean, Record<string, unknown>[], PaginationInfo, Error | null] {
  const [loading, setLoading] = useState(true);

  const [{ hits, includes }, , error] = useObservable<
    { hits: T[]; includes: T[] },
    [EntityType, FetchParams]
  >(
    (input$) =>
      input$.pipe(
        distinctUntilChanged((a, b) => {
          const result = JSON.stringify(a) === JSON.stringify(b);
          return result;
        }),
        tap(() => setLoading(true)),
        // debounceTime(250),
        switchMap(async ([type, options]) => {
          return fetchResource<T>({ type, ...options });
        }),
        tap(() => setLoading(false))
      ),
    { hits: [], includes: [] },
    [type, { limit: 15, page: 1, query: "", ...(options || {}) }]
  );

  return [hits || [], loading, includes, error];
}

export function useResource<T>(
  type: EntityType,
  id: string
): [T | null, boolean] {
  const [resource, loading] = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(async ([type, id]) => {
          const json = await sendBackendRequest(`/${type}/${id}`).json<any>();
          return { ...json.data, included: json.included };
        })
      ),
    null,
    [type, id]
  );
  return [resource, loading];
}

export async function fetchResource<T>({
  type,
  page,
  query,
  limit,
  filters,
}: { type: EntityType } & FetchParams) {
  const params = new URLSearchParams();
  if (limit && page) {
    params.set("page[offset]", "" + limit * (page - 1));
    params.set("page[limit]", "" + limit);
  }
  for (const [key, value] of Object.entries(filters || {})) {
    params.set((key.startsWith("[") ? "filter" : "") + key, value);
  }
  const results = await sendBackendRequest(`/${type}?${params}`).json<T>();
  return { hits: results.data, includes: results.included || [] };
}
