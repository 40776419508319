import styled from "styled-components";
import { Card, CardTitle, Title } from "../common-styles";
import { ProfilePictureCarousel } from "./profile-picture-carousel";
import {
  down,
  FontWeight,
  HookForm,
  showErrorToast,
  showSuccessToast,
  spacing12,
  spacing2,
  spacing3,
  spacing4,
  spacing8,
  T,
  useModal,
} from "@litbase/alexandria";
import { InputField } from "../../../form/input-field";
import { Button, SubmitButton } from "../../../../styles/common-styles";
import {
  formBodyStyles,
  WideContainer,
} from "../../../form/common-style-components";
import {
  refreshLoggedInUserData,
  User,
  useUser,
} from "../../../../hooks/use-user";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import Tippy from "@tippyjs/react";
import {
  getNewCsrfToken,
  sendBackendRequest,
} from "../../../../services/backend";
import { EntityType } from "../../../../types/entity";
import {
  BirthdayField,
  birthdayFieldValueToDate,
  transformToFormUsableByBirthdayField,
} from "../../../form/birthday-field";
import { object, string } from "yup";
import { ContactField } from "../../event-signup/contact-field";
import { DeleteProfileModal } from "./delete-profile-modal";
import { PasswordField } from "../../../form/password-field";

export function EditProfileForm() {
  const user = useUser();
  const { showModal } = useModal();

  console.log({ user });

  return (
    <>
      <Title>
        <T>Profil szerkesztése</T>
      </Title>
      <HookForm
        defaultValues={{
          ...user,
          field_profil_kep: user.field_profil_kep,
          field_date_of_birth: transformToFormUsableByBirthdayField(
            user.field_date_of_birth
          ),
          field_name: user.field_name,
          mail: user.mail,
        }}
        yupSchema={yupSchema}
        onSubmit={async (values) => {
          try {
            await saveUser(
              Object.fromEntries(
                Object.entries(values).filter(
                  ([key, value]) => user[key] !== value
                )
              ),
              user
            );
            showSuccessToast(
              <>
                <T>Sikeres mentés</T>!
              </>,
              null
            );
          } catch (e) {
            console.log({ e });
            showErrorToast(
              <>
                <T>Valami elromlott, próbáld újra később!</T>
              </>,
              null
            );
          }
        }}
      >
        <Body>
          <CardTitle>
            <T>Profil adatok</T>
          </CardTitle>
          <ProfilePictureCarousel />
          <WideContainer>
            <InputField label={<T>Felhasználónév</T>} name={"field_name"} />

            <EmailContainer>
              <InputField name="mail" label={<T>Email cím</T>} />
              <StyledTippy content={<T>givenEmail</T>}>
                <Unit>
                  <InfoCircle />
                </Unit>
              </StyledTippy>
            </EmailContainer>
            <StyledWideContainer>
              <BirthdayField
                name="field_date_of_birth"
                label={<T>Születési idő</T>}
              />
            </StyledWideContainer>
            <StyledWideContainer>
              <InputField
                name={"field_phone"}
                maxLength={12}
                label={<T>Telefonszám</T>}
              />
            </StyledWideContainer>
          </WideContainer>
          <NewPasswordWideContainer>
            <ChangePassword>
              <T>Számlázási adatok</T>
            </ChangePassword>
            <StyledLine />
          </NewPasswordWideContainer>
          <ContactFieldsContainer>
            <ContactField isBillingData hasStringValue />
          </ContactFieldsContainer>
          <NewPasswordWideContainer>
            <ChangePassword>
              <T>Jelszó változtatás</T>
            </ChangePassword>
            <StyledLine />
          </NewPasswordWideContainer>

          <WideContainer>
            <PasswordField
              name="current_pass"
              label={<T>Jelenlegi jelszó</T>}
            />
          </WideContainer>
          <PasswordContainer>
            <PasswordField name="newPass" label={<T>Új jelszó</T>} />
          </PasswordContainer>
          <PasswordContainer>
            <PasswordField name="newPass2" label={<T>Jelszó megerősítése</T>} />
          </PasswordContainer>
          <ButtonContainer>
            <SubmitButton>
              <T>Mentés</T>
            </SubmitButton>
            <DeleteButton
              hidden
              type={"button"}
              onClick={() => showModal(<DeleteProfileModal />)}
            >
              <T>Profil törlése</T>
            </DeleteButton>
          </ButtonContainer>
        </Body>
      </HookForm>
    </>
  );
}

const StyledWideContainer = styled(WideContainer)`
  margin-top: ${spacing2};
`;

const ContactFieldsContainer = styled(WideContainer)`
  > div,
  > div > div {
    width: 100%;
  }
`;

const ButtonContainer = styled(WideContainer)`
  display: flex;
  gap: 1rem;
  margin-top: ${spacing4}!important;
`;

const DeleteButton = styled(Button)`
  border-color: red;
  margin-left: auto;
`;

const yupSchema = object().shape({
  field_date_of_birth: object().test(
    "test_date_of_birth",
    "Adj meg érvényes dátumot!",
    (value) => {
      return !!birthdayFieldValueToDate(value);
    }
  ),
  newPass: string().when("current_pass", {
    is: (pass) => !!pass,
    then: (schema) =>
      schema.matches(
        /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "A jelszónak minimum 8 karakter hosszúnak kell lennie. Tartalmaznia kell legalább 1 nagybetűt és legalább 1 számot."
      ),
  }),
});

async function saveUser(
  { current_pass, newPass, newPass2, ...values }: Partial<User>,
  user: User
) {
  const csrfToken = await getNewCsrfToken();

  console.log({ values });

  await sendBackendRequest("/" + EntityType.user + "/" + user.id, {
    method: "PATCH",
    body: JSON.stringify({
      data: {
        type: "user--user",
        id: user.id,
        attributes: {
          ...values,
          field_profil_kep: values.field_profil_kep,
          field_date_of_birth: `${values.field_date_of_birth.year}-${values.field_date_of_birth.month}-${values.field_date_of_birth.day}`,
          field_name: values.field_name,
          mail: values.mail,
          ...(newPass
            ? {
                pass: {
                  value: newPass,
                  ...(current_pass ? { existing: current_pass } : {}),
                },
              }
            : {}),
        },
      },
    }),
    headers: {
      "Content-Type": "application/vnd.api+json",
      "X-CSRF-Token": csrfToken,
    },
  });

  await refreshLoggedInUserData();
}

const Secondary = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  color: white;
  width: 100%;
  justify-content: flex-end;
  text-align: end;
`;

const Unit = styled.span`
  //magic numbers
  position: absolute;
  display: flex;
  right: 10px;
  top: 31px;
  z-index: 9;
  font-size: 20px;
  cursor: pointer;
`;

const PasswordContainer = styled.div`
  ${down("md")} {
    grid-column: span 2;
    position: relative;
    > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const StyledLine = styled.div`
  margin-top: ${spacing2};
  margin-botttom: ${spacing2};
  width: 100%;
  border-bottom: 1px solid white;
`;

const ChangePassword = styled.span`
  font-weight: ${FontWeight.Bold};
`;

const NewPasswordWideContainer = styled(WideContainer)`
  margin-top: ${spacing4}!important;
`;
const EmailContainer = styled.div`
  grid-column: span 2;
  position: relative;
  > div {
    width: 100%;
    max-width: 100%;
  }
  margin-top: ${spacing2};
`;

const Body = styled(Card)`
  max-width: 100%;

  ${formBodyStyles};

  ${ProfilePictureCarousel} {
    grid-column: span 2;
  }

  label {
    font-weight: ${FontWeight.Bold};
  }
`;

const StyledTippy = styled(Tippy)`
  background-color: white;
  padding: ${spacing2};
  border-radius: ${({ theme }) => theme.roundedLg};
`;
