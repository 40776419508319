import {
  down,
  spacing1,
  spacing16,
  spacing4,
  spacing48,
  spacing6,
  spacing8,
  T,
} from "@litbase/alexandria";
import styled, { createGlobalStyle } from "styled-components";
import backgroundImg from "../../../assets/storm.png";
import { Container } from "../../container";
import { SineWave } from "./sine-wave";
import { useMemo, useRef } from "react";
import moonBottom from "../../../assets/moon_bottom.png";
import { useFallingStars } from "../../../hooks/use-falling-stars";
import { AppCol } from "../../../app-col";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { fetchResource } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import { getImageUrl } from "../../../utils/get-image-url";
import { useCurrentCompetitionData } from "../profile/main-stats-card";
import { useObservable } from "@litbase/use-observable";
import { switchMap } from "rxjs/operators";
import defaultMilestoneIcon from "../../../assets/default_milestone_icon.png";
import { formatDistance } from "./utils";

export function StatsPage() {
  const currentcompData = useCurrentCompetitionData();

  const progress = currentcompData?.distance || 0;

  const [[data, images]] = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        switchMap(async () => {
          return hackilyFetchManyItems();
        })
      );
    },
    [[], []],
    []
  );

  const milestones = data.map((elem) => ({
    distance: elem.attributes.field_tavolsag,
    title: elem.attributes.title,
    subtitle: elem.attributes.field_alcim,
    logo:
      process.env.BACKEND_URL +
      images.find(
        (image) => elem.relationships.field_logo.data?.id === image.id
      )?.attributes.uri.url,
  }));

  const sections = useMilestonesSortedIntoSections(milestones);
  const isSmall = useBreakpoint(down("md"));
  const starsRef = useRef(null);
  const sectionsRef = useRef<HTMLDivElement>(null);
  useFallingStars(starsRef);

  return (
    <Body>
      <FallingStarsBody ref={starsRef} />
      <Global />
      <StyledH1><T>Irány a hold</T></StyledH1>
      <p><T>Itt láthatod a távot, melyet eddig közösen sikerült megtennünk</T></p>
      <MainBody>
        <BackgroundContainer>
          <Background />
        </BackgroundContainer>
        <Col>
          {sections.map((section, index) => (
            <SectionBody key={index} ref={index === 0 ? sectionsRef : null}>
              {section.buckets.map((bucket, bucketIndex) => (
                <BucketBody key={bucketIndex}>
                  {bucket.map((elem) => (
                    <Grid>
                      <Distance>{formatDistance(elem.distance)}</Distance>
                      <ElemTitle>{elem.title}</ElemTitle>
                      <LogoContainer>
                        <Logo
                          src={
                            elem.logo && !elem.logo.endsWith("undefined")
                              ? elem.logo
                              : defaultMilestoneIcon
                          }
                        />
                      </LogoContainer>
                      <Subtitle>{elem.subtitle}</Subtitle>
                    </Grid>
                  ))}
                </BucketBody>
              ))}
            </SectionBody>
          ))}
        </Col>
        <SvgCol>
          <SineWave
            key={progress}
            progress={Math.round(progress / 1000)}
            milestones={milestones}
            amplitude={isSmall ? 30 : 50}
            sectionsRef={sectionsRef}
          />
        </SvgCol>
        <PlaceholderCol></PlaceholderCol>
        <BottomBackgroundContainer>
          <BottomBackground />
        </BottomBackgroundContainer>
      </MainBody>
      <BottomPadding />
    </Body>
  );
}

async function hackilyFetchManyItems(): Promise<
  [MilestoneInterface[], Record<string, unknown>[]]
> {
  const limit = 25;
  let { hits: curHits, includes: curIncludes } = await fetchResource({
    type: EntityType.milestone,
    limit,
    page: 1,
    filters: { include: "field_logo" },
  });

  const allHits = [...curHits];
  const allIncludes = [...curIncludes];

  let counter = 1;
  while (curHits.length <= limit && counter < 4) {
    counter++;
    const { hits: curHits, includes: curIncludes } = await fetchResource({
      type: EntityType.milestone,
      limit,
      page: counter,
      filters: { include: "field_logo" },
    });
    allHits.push(...curHits);
    allIncludes.push(...curIncludes);
  }
  return [allHits, allIncludes];
}

const FallingStarsBody = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const StyledH1 = styled.h1`
  margin-top: ${spacing8};
`;

const LogoContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  grid-row: 1 / span 2;
  grid-column: -1;
`;

const Subtitle = styled.span`
  grid-column: 2;
  color: white;
  font-weight: 100;
  font-size: 16px;
  font-family: ${({ theme }) => theme.headingsFontFamily};

  ${down("md")} {
    grid-column: 1;
    font-size: 10px;
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  object-fit: contain;
  object-position: center;
`;

const Distance = styled.h2`
  font-weight: bold;
  margin-bottom: 0;
  margin-right: ${spacing4};
  font-size: 18px;
  color: #d4bcff;
  white-space: nowrap;

  ${down("md")} {
    margin-bottom: 0;
    font-size: 12px;
  }
`;

const Global = createGlobalStyle`
  ${AppCol} {
    overflow: hidden;
  }
`;

const BottomPadding = styled.div`
  height: ${spacing48};
`;

const bg = getImageUrl({ src: backgroundImg, width: 1920, height: 2000 });

const Background = styled.img.attrs(() => ({ src: bg }))`
  width: 100vw;
  z-index: 0;
  transform: translateY(-1rem);
  ${down("md")} {
    width: 140vw;
    // We want this centered horizontally, which works fine on 100vw, but on higher values than that it gets fucky.
    // To correct for this we push it left by half the units over 100vw. To have it align with the sine wave we also
    // need to push it by the amount we pushed the sine wave to the right from center.
    margin-left: calc(-20vw + ${spacing4});
    transform: translateY(
      1rem
    ); // Kind of an eyeballed number but seems to work
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0;
  z-index: 0;
  &:first-child {
    top: 0;
  }
  &:last-child {
    bottom: 0;
  }
`;

const BottomBackgroundContainer = styled(BackgroundContainer)`
  bottom: 0;
`;

const bg2 = getImageUrl({ src: moonBottom, width: 1920, height: 1080 });

const BottomBackground = styled(Background).attrs(() => ({
  src: bg2,
}))``;

const BucketBody = styled.div`
  flex: 1;
  padding-right: ${spacing4};
`;

const SectionBody = styled.div`
  height: 200rem;
  display: flex;
  flex-direction: column;
`;

const LogoBody = styled.svg`
  margin-left: auto;
  ${down("md")} {
    grid-column: 3;
    grid-row: 1;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  z-index: 2;
  ${down("md")} {
    padding: ${spacing6};
  }
`;

const PlaceholderCol = styled(Col)`
  ${down("md")} {
    display: none;
  }
`;

const SvgCol = styled(Col)`
  display: flex;
  flex-direction: column;
  min-height: 0;
  align-items: center;
  min-width: 0px;
  width: 0px;
  max-width: 0;
  position: relative;
  ${down("md")} {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    margin-left: ${spacing4};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: ${spacing4};
  align-items: center;
  width: 100%;
  flex: 1;

  ${down("md")} {
    display: grid;
    grid-template-columns: 1fr min-content;
    width: 60%;
  }
  padding-top: ${spacing4};
`;

const ElemTitle = styled.span`
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  flex: 1;
  line-height: 1.2;
  margin-bottom: ${spacing1};

  ${down("md")} {
    font-size: 12px;
    grid-column: 1;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

const Elem = styled.div`
  display: flex;
  flex: 1;
  > * + * {
    margin-left: ${spacing4};
  }
`;

const MainBody = styled(Container)`
  display: flex;
  height: fit-content;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  ${down("md")} {
    position: relative;
    flex-direction: column;
    padding: 0;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-size: contain;
  background-position-y: -17.5rem;
  padding-top: ${spacing16};
  overflow: hidden;
  z-index: 0;
  > p,
  > h1 {
    z-index: 2;
  }
`;

interface MilestoneInterface {
  distance: number;
  title: string;
  subtitle: string;
  logo?: string;
}

function useMilestonesSortedIntoSections(milestones: MilestoneInterface[]) {
  return useMemo(() => {
    const sections = [
      { from: 0, to: 100 },
      { from: 100, to: 1000 },
      { from: 1000, to: 10000 },
      { from: 10000, to: 100000 },
      { from: 100000, to: 500000 },
    ].map((elem) => ({
      ...elem,
      buckets: Array(10)
        .fill(0)
        .map(() => [] as MilestoneInterface[]),
    }));
    for (const milestone of milestones) {
      const matchingSection = sections.find(
        (section) =>
          section.from <= milestone.distance && section.to > milestone.distance
      );
      if (!matchingSection) {
        console.error({
          reason: "No matching section for milestone",
          milestone,
          sections,
        });
        continue;
      }
      const ratio =
        (milestone.distance - matchingSection.from) /
        (matchingSection.to - matchingSection.from);
      const bucketIndex = Math.floor(ratio * 10);
      matchingSection.buckets[bucketIndex].push(milestone);
    }

    for (const section of sections) {
      for (const bucket of section.buckets) {
        bucket.sort((a, b) => a.distance - b.distance);
      }
    }

    return sections;
  }, [milestones]);
}
