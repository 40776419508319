import styled, { useTheme } from "styled-components";
import { down, spacing4 } from "@litbase/alexandria";
import { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Block } from "../../types/block";

export function useVisibleBlock(): [number, (index: number) => void] {
  const [active, setActive] = useState(0);
  const lastScrollValue = useRef(window.scrollY);

  useEffect(() => {
    function scrollHandler() {
      function determineDirection() {
        if (
          Math.floor(window.scrollY / window.innerHeight) ===
          Math.ceil(window.scrollY / window.innerHeight)
        ) {
          return "none";
        }

        const diff = lastScrollValue.current - window.scrollY;
        if (diff > 0) return "up";
        if (diff === 0) return "none";
        if (diff < 0) return "down";
      }

      const direction = determineDirection();
      const currentBlock = (direction === "up" ? Math.ceil : Math.floor)(
        window.scrollY / window.innerHeight
      );
      const nextBlock =
        direction === "up"
          ? currentBlock - 1
          : direction === "down"
          ? currentBlock + 1
          : currentBlock;
      setActive(nextBlock);
      lastScrollValue.current = window.scrollY;
    }

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return [
    active,
    (index) => {
      window.scrollTo({ top: window.innerHeight * index, behavior: "smooth" });
    },
  ];
}

export function BlockIndicator({ blocks }: { blocks: Block[] }) {
  const [active, setActive] = useVisibleBlock();
  return (
    <BlockIndicatorView blocks={blocks} active={active} onClick={setActive} />
  );
}

export function BlockIndicatorView({
  blocks,
  active,
  onClick,
}: {
  blocks: { name: string }[];
  active: number;
  onClick: (index: number) => void;
}) {
  return (
    <BodyContainer>
      <Body>
        {blocks.map((elem, index) => (
          <BlockIndicatorElem
            onClick={() => onClick?.(index)}
            key={index}
            text={elem.name}
            isActive={index === active}
          />
        ))}
      </Body>
    </BodyContainer>
  );
}

export function BlockIndicatorElem({
  text,
  isActive,
  onClick,
}: {
  text: string;
  isActive: boolean;
  onClick?: () => void;
}) {
  const theme = useTheme();
  const [hovering, setHovering] = useState(false);
  const [recentlyActivated, setRecentlyActivated] = useState(false);
  const [styles] = useSpring(
    () => ({
      background: isActive || hovering ? theme.blue : theme.gray,
      borderRadius: isActive || hovering ? "50%" : "0%",
      width: isActive || hovering ? "8px" : "20px",
      height: isActive || hovering ? "8px" : "4px",
    }),
    [isActive, hovering, recentlyActivated]
  );
  const textStyles = useSpring({
    left: hovering || recentlyActivated ? 16 : -200,
    opacity: hovering || recentlyActivated ? 1 : 0,
  });
  useEffect(() => {
    const timeouts = [];
    if (isActive) {
      timeouts.push(
        setTimeout(() => setRecentlyActivated(true), 300),
        setTimeout(() => setRecentlyActivated(false), 2000)
      );
    }
    if (!isActive) {
      setRecentlyActivated(false);
    }
    return () => {
      for (const timeout of timeouts) {
        clearTimeout(timeout);
      }
    };
  }, [isActive]);
  return (
    <ElemBody
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
    >
      <DotContainer>
        <ElemDot style={styles} />
      </DotContainer>
      <ElemTextContainer>
        <ElemText style={textStyles}>{text}</ElemText>
      </ElemTextContainer>
    </ElemBody>
  );
}

const ElemTextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ElemText = styled(animated.span)`
  text-transform: uppercase;
  position: absolute;
  white-space: nowrap;
  left: 1rem;
  font-weight: 200;
  font-family: Montserrat;
  color: white;
`;

const ElemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing4};
  cursor: pointer;
`;

const DotContainer = styled.div`
  position: relative;
  width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 5;
  height: 100%;
  top: 0;
  left: 0;
  padding-left: 1rem;
  ${down("xl")} {
    display: none;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;

const ElemDot = styled(animated.div)`
  width: 20px;
  height: 4px;
  background: ${({ theme }) => theme.gray};
  position: absolute;
  cursor: pointer;
`;
