import styled from "styled-components";
import {
  FontWeight,
  LargeLoadingIcon,
  spacing16,
  spacing4,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import { StepWrapper } from "../components/general/steps";

export const ModalParagraph = styled.div<{ $align?: string }>`
  margin-top: ${spacing16};
  margin-bottom: ${spacing8};
  font-weight: ${FontWeight.Medium};
  font-size: ${({ theme }) => theme.textLg};
  text-align: ${({ $align }) => $align || "left"};
  line-height: 1.4;

  p {
    max-width: none;
    font-weight: inherit;
    text-align: inherit;
  }
`;

export const ModalSubtitle = styled.div`
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${spacing6};
`;

export const ModalStepWrapper = styled(StepWrapper)`
  margin-top: ${spacing8};
  margin-bottom: ${spacing8};
`;

export function LargeModalLoadingIcon() {
  return (
    <LoadingIconWrapper>
      <LargeLoadingIcon />
    </LoadingIconWrapper>
  );
}

const LoadingIconWrapper = styled.div`
  text-align: center;
  padding-bottom: ${spacing4};

  svg {
    color: ${({ theme }) => theme.lightBlue};
  }
`;
