import styled, { createGlobalStyle, css, keyframes } from "styled-components";

import {
  down,
  FontWeight,
  LoadingIcon,
  spacing10,
  spacing12,
  spacing16,
  spacing2,
  spacing24,
  spacing3,
  spacing4,
  spacing5,
  spacing6,
  spacing8,
  transition,
  up,
} from "@litbase/alexandria";
import { ComponentProps, ComponentPropsWithoutRef, ReactNode } from "react";
import {
  breakPointsPixels,
  montserrat,
  verticalBreakpoints,
  verticalDown,
} from "./theme-types";
import { useVisibleBlock } from "../components/general/block-indicator";
import { animated, config, useSpring } from "react-spring";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { useFormState } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { getImageUrl } from "../utils/get-image-url";

export function BlockBody({
  children,
  blockIndex,
  backgroundImage,
  staticContent,
  hasFallingStars = false,
  ...props
}: ComponentProps<typeof BlockBodyBody> & {
  children: ReactNode;
  blockIndex: number;
  backgroundImage?: string;
  staticContent?: ReactNode;
  hasFallingStars?: boolean;
}) {
  const [activeBlock] = useVisibleBlock();
  const isActive = activeBlock === blockIndex;
  const isVerticallySmall = useMediaQuery({
    query: `(max-height: ${verticalBreakpoints.sm}px)`,
  });
  const styles = useSpring({
    opacity: isActive || isVerticallySmall ? 1 : 0,
    delay: isActive ? 500 : 0,
    pointerEvents: isActive || isVerticallySmall ? "all" : "none",
    config: isActive ? config.slow : config.default,
  });
  const isSmall = useBreakpoint(down("xl"));
  return (
    <BlockBodyBody
      {...props}
      $backgroundImage={
        backgroundImage?.startsWith("/")
          ? getImageUrl({
              src: backgroundImage,
              height: 1080,
              width: 1920,
            })
          : backgroundImage
      }
    >
      {staticContent}
      {/* @ts-ignore */}
      <FixedBlockBody style={isSmall ? undefined : styles}>
        <BlockBodyContent>{children}</BlockBodyContent>
      </FixedBlockBody>
    </BlockBodyBody>
  );
}

export const BlockBodyContent = styled.div`
  margin: 0 auto;
  max-width: ${breakPointsPixels.xl}px;
  padding: ${spacing12};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${down("md")} {
    padding: 0;
    width: 100%;
    padding-bottom: ${spacing24};
    padding-top: ${spacing24};
  }
`;

export const FixedBlockBody = styled(animated.div)`
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${verticalDown("sm")} {
    position: static;
  }
  ${down("xl")} {
    position: static;
  }
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing8};
  margin-bottom: ${spacing10};
  width: 100%;
`;

export function SubmitButton({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  const { isSubmitting } = useFormState();
  const styles = useSpring({
    width: isSubmitting ? "1.2rem" : "0rem",
  });

  return (
    <Button type="submit" disabled={isSubmitting} className={className}>
      <SpinnerContainer style={styles}>
        <LoadingIcon />
      </SpinnerContainer>
      {children}
    </Button>
  );
}

function ButtonComponent({
  children,
  ...props
}: ComponentPropsWithoutRef<"button">) {
  return (
    <button {...props}>
      <span>{children}</span>
    </button>
  );
}

const SpinnerContainer = styled(animated.span)`
  display: inline-block;
  svg {
    max-width: 100%;
  }
`;

export const activeButtonStyle = css`
  border: 2px solid ${({ theme }) => theme.blue};

  &:before {
    opacity: 1;
  }
`;

export const Button = styled(ButtonComponent)`
  position: relative;
  background: transparent;
  border: 2px solid ${({ theme }) => theme.blue};
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  padding: ${spacing2} ${spacing4};
  font-weight: ${FontWeight.Bold};
  display: flex;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(180deg, #15018e 0%, #6534ff 100%),
      linear-gradient(180deg, #5a0085 0%, #dc93ff 100%);
    opacity: 0;
    transition: ${({ theme }) =>
      transition(["opacity"], theme.fastTransitionTiming)};
  }

  &:hover {
    ${activeButtonStyle};
  }

  > span {
    position: relative;
    z-index: 2;
  }

  svg {
    margin-right: ${spacing3};
  }
`;

export const GreenButton = styled(Button)`
  border-color: #4cd964;
  width: fit-content;
  padding: 13px 35px;
  text-transform: none;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
`;

export const RedButton = styled(Button)`
  border-color: #d74242;
  width: fit-content;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
  border-radius: 10px;
`;

const fadingBlockStyles = css`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${({ $backgroundImage }) => $backgroundImage});
`;

const BlockBodyBody = styled(animated.div)<{ $backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url(${({ $backgroundImage }) => $backgroundImage});
  background-size: cover;
  ${down("xl")} {
    height: 100%;
    ${fadingBlockStyles};
  }
  ${verticalDown("sm")} {
    min-height: 100vh;
    height: auto !important;
    ${fadingBlockStyles};
  }
  ${up("xl")} {
    height: 100vh;
  }
`;

const bigSignupUpButtonStyle = css`
  font-size: 28px;
  padding: 14px 20px;
  letter-spacing: 1px;
`;

export const SignUpButton = styled(Button)<{ $big?: boolean }>`
  font-size: 22px;
  padding: 8px 12px;
  margin-left: auto;
  margin-right: auto;

  ${montserrat};

  ${({ $big }) => $big && bigSignupUpButtonStyle};
`;

export const BlueTitle = styled.h1`
  color: ${({ theme }) => theme.lightPurple};
  letter-spacing: 0.1em;
  margin-bottom: ${spacing5};
  text-align: center;
  width: 100%;
  ${down("md")} {
    text-align: center;
  }
  ${verticalDown("lg")} {
    margin-bottom: ${spacing3};
    margin-top: ${spacing8};
  }
`;

export const Subtitle = styled.div`
  margin-bottom: ${spacing16};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  ${verticalDown("lg")} {
    margin-bottom: ${spacing8};
  }
`;

export const TextBlock = styled.div`
  margin-bottom: ${spacing16};
  margin-top: ${spacing16};
  ${verticalDown("lg")} {
    margin-top: ${spacing8};
    margin-bottom: ${spacing8};
  }
  ${down("md")} {
    margin-top: ${spacing8};
    margin-bottom: ${spacing8};
  }
`;
const starsTwinkle = keyframes`
  0% {
    transform: scale(1, 1);
    background: rgba(255,255,255,0.0);
    animation-timing-function: ease-in;
  }
  60% {
    transform: scale(0.8, 0.8);
    background: rgba(255,255,255,1);
    animation-timing-function: ease-out;
  }
  80% {
    background: rgba(255,255,255,0.00);
    transform: scale(1, 1);
  }
  100% {
    background: rgba(255,255,255,0.0);
    transform: scale(1, 1);
  }`;

export const StarStyle = createGlobalStyle`
   .star {
     position: absolute;
     width: 4px;
     height: 4px;
     background: rgba(255, 255, 255, 0);
     border-radius: 50%;
     animation: ${starsTwinkle} linear;
   }
`;
const fallingStar = keyframes`
  0% {
    transform: rotate(195deg) translateX(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(195deg) translateX(-1500px);
    opacity: 0;
  }
`;

export const FallingStarStyle = createGlobalStyle`
  .fallingStar {
    position: absolute;
    top: 0;
    left: 0;
    right: initial;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1);
    animation: ${fallingStar} linear;
    animation-duration: 4s;
  
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 300px;
      height: 1px;
      background: linear-gradient(90deg, #fff, transparent);
    }
  }
`;
