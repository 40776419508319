import styled from "styled-components";
import { Card, Title } from "../common-styles";
import earth from "./assets/earth.png";
import { Slider } from "./slider";
import { down, spacing, spacing2, spacing4, T } from "@litbase/alexandria";
import { useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { Team } from "../../../../types/team";
import { useMemo, useState } from "react";
import { selectStyles } from "../../../form/select-field";
import Select from "react-select";
import { useUser } from "../../../../hooks/use-user";
import { AutoAnimatedDiv } from "../../../general/auto-animated-div";
import { CompetitionSignup } from "../../../../types/competition-signup";

export function Leaderboard() {
  return (
    <>
      <Title>
        <T>Aktuális verseny</T>
      </Title>
      <LeaderboardSection restrictToCurrentRace={true} />
      <Title>
        <T>overallRanking</T>
      </Title>
      <LeaderboardSection restrictToCurrentRace={false} />
    </>
  );
}

function LeaderboardSection({
  restrictToCurrentRace,
}: {
  restrictToCurrentRace?: boolean;
}) {
  const user = useUser();
  const [category, setCategory] = useState(
    categories.find(
      (elem) => elem.value === user.team?.attributes?.field_csapat_kategoria
    ) || categories[0]
  );

  const [allTeams] = useResources<Team>(EntityType.team, {
    filters: {
      sort: "-field_osszes_megtett_tavolsag_ar",
      "[field_csapat_kategoria]": category?.value,
    },
  });

  const [allUsers] = useResources(EntityType.user, {
    filters: {
      sort: "-field_osszes_megtett_tavolsag_ar",
      "[rejtett_group][group][conjunction]": "OR",

      "[rejtett_filter_null][condition][path]": "field_rejtett",
      "[rejtett_filter_null][condition][operator]": "IS NULL",
      "[rejtett_filter_null][condition][memberOf]": "rejtett_group",

      "[rejtett_filter_false][condition][path]": "field_rejtett",
      "[rejtett_filter_false][condition][operator]": "=",
      "[rejtett_filter_false][condition][value]": false,
      "[rejtett_filter_false][condition][memberOf]": "rejtett_group",
    },
    limit: 10000,
  });

  const allUserIndex = allUsers.findIndex(
    (elem) =>
      elem?.attributes.drupal_internal__uid === user.drupal_internal__uid
  );

  const currentRaceResults = useCurrentRaceResults({ category });

  const users = restrictToCurrentRace ? currentRaceResults?.users : allUsers;
  const teams = restrictToCurrentRace ? currentRaceResults?.teams : allTeams;
  const userIndex = restrictToCurrentRace
    ? currentRaceResults.userIndex
    : allUserIndex;

  return (
    <StyledCard>
      <div>
        <StyledSelect
          value={category}
          onChange={setCategory}
          styles={selectStyles}
          options={categories}
          menuPlacement={"auto"}
        />
      </div>
      <Grid>
        {(category.value === "egyeni"
          ? [
              ...users.slice(0, 10),
              ...(userIndex > 9
                ? [{ attributes: user, title: user.field_name }]
                : []),
            ].map((elem) => ({
              attributes: {
                ...elem.attributes,
                title: elem.attributes.field_name,
              },
            }))
          : teams
        ).map((elem, index) => {
          const isLast = category.value === "egyeni" && index > 9;
          return (
            <>
              {isLast && <Placeholder>...</Placeholder>}
              <Result
                percent={
                  (100 * elem.attributes.field_osszes_megtett_tavolsag_ar) /
                  (category.value === "egyeni" ? users : teams)[0].attributes
                    .field_osszes_megtett_tavolsag_ar
                }
                place={isLast ? userIndex + 1 : index + 1}
                team={elem}
              />
            </>
          );
        })}
      </Grid>
    </StyledCard>
  );
}

function useCurrentRaceResults({
  category,
}: {
  category: typeof categories[number];
}) {
  const user = useUser();
  const currentRace = user.currentRace;

  const [teams] = useResources<Team>(EntityType.team, {
    filters: {
      sort: "-field_osszes_megtett_tavolsag_ar",
      "[field_csapat_kategoria]": category?.value,
      "[field_verseny.drupal_internal__nid]":
        currentRace?.attributes.drupal_internal__nid,
    },
  });

  const [signups, , includedUsers] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filters: {
        sort: "-field_osszes_megtett_tavolsag_ar",
        "[field_verseny.drupal_internal__nid]":
          currentRace?.attributes.drupal_internal__nid,
        include: "field_egyeni_versenyzo",
      },
      limit: 10000,
    }
  );

  const users = useMemo(() => {
    return signups.map((signup) => {
      const result =
        includedUsers.find(
          (user) =>
            signup.relationships.field_egyeni_versenyzo?.data?.id === user.id
        ) || {};
      result.attributes.field_osszes_megtett_tavolsag_ar =
        signup.attributes.field_osszes_megtett_tavolsag_ar;
      return result;
    });
  }, [signups, includedUsers]);

  const userIndex = users.findIndex(
    (elem) =>
      elem?.attributes.drupal_internal__uid === user.drupal_internal__uid
  );

  return { users, userIndex, teams };
}

function Result({
  percent,
  team,
  place,
}: {
  percent: number;
  team: Team;
  place: number;
}) {
  return (
    <>
      <Number>{place}.</Number>
      <span>{team.attributes.title}</span>
      <Span>
        {(
          Math.round(
            team.attributes.field_osszes_megtett_tavolsag_ar / 10 || 0
          ) / 100
        ).toLocaleString("hu")}{" "}
        KM
      </Span>
      <Moon />
      <Slider percent={percent || 0} />
    </>
  );
}

const Placeholder = styled.span`
  grid-column: 1 / -1;
`;

const StyledSelect = styled(Select)`
  border: none;
  margin-bottom: ${spacing4};
`;

const Number = styled.span`
  margin-right: ${spacing4};
`;

const Span = styled.span`
  white-space: nowrap;
`;

const Moon = styled.img.attrs(() => ({ src: earth }))`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  object-fit: cover;
`;

const Grid = styled(AutoAnimatedDiv)`
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content 1fr;
  grid-row-gap: ${spacing4};
  grid-column-gap: ${spacing2};
  align-items: center;
  ${down("md")} {
    grid-template-columns: min-content 1fr min-content;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: ${spacing._8};
`;

const categories = [
  { value: "egyeni", label: <T>Egyéni</T> },
  { value: "1_mikro", label: <T>Mikro (1-10 fő)</T> },
  { value: "5_small", label: <T>Kis (11-50 fő)</T> },
  { value: "10_middle", label: <T>Közepes (51-100 fő)</T> },
  { value: "15_big", label: <T>Nagy (101-200 fő)</T> },
  { value: "20_huge", label: <T>Óriás (201+ fő)</T> },
];
