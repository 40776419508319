import {
  HookForm,
  LoadingIcon,
  ModalDialogX,
  showErrorToast,
  spacing4,
  spacing6,
  T,
  useModal,
} from "@litbase/alexandria";
import styled from "styled-components";
import {
  formBodyStyles,
  WideContainer,
} from "../../../form/common-style-components";
import { Card, CardTitle } from "../common-styles";
import { Button, SubmitButton } from "../../../../styles/common-styles";
import { OTPButton, SimplePayButton } from "../../../general/payment-buttons";
import { useUser } from "../../../../hooks/use-user";
import { ContactField } from "../../event-signup/contact-field";
import { hackilySubmitFormRequest } from "../../../../services/backend";
import { SelectField } from "../../../form/select-field";
import { useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { CompetitionSignup } from "../../../../types/competition-signup";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Interweave } from "interweave";
import { getContactInfo } from "../../event-signup/payment-form";
import paymentTermsDoc from "../../../../assets/fizetesi_tajekoztato_moonshot.pdf";
import { mixed, object, string } from "yup";

export function PaymentInfoCard({ teamName }: { teamName: string }) {
  const { dismissModal } = useModal();

  const user = useUser();
  const [result, loading, included] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filters: {
        "[field_egyeni_versenyzo.drupal_internal__uid]":
          user.drupal_internal__uid,
        include: "field_verseny",
        "[field_statusz]": "fizetett",
      },
    }
  );
  const competitions = useMemo(() => {
    return (
      included?.map((elem) => ({
        value: elem.attributes.drupal_internal__nid,
        label: elem.attributes.title,
      })) || []
    );
  }, [included]);

  if (!result || loading) {
    return (
      <CardBody>
        <LoadingIcon />
      </CardBody>
    );
  }

  if (!competitions.length) {
    return (
      <CardBody>
        <CloseButton onClick={() => dismissModal()} />
        <CardTitle>
          <T>Jelentkezz versenyre mielőtt csapatot hoznál létre!</T>
          <Link to={"/#versenyek"}>
            <Button onClick={() => dismissModal()}>
              <T>Versenyek</T>
            </Button>
          </Link>
        </CardTitle>
      </CardBody>
    );
  }

  return (
    <StyledCardBody>
      <CloseButton onClick={() => dismissModal()} />
      <CardTitle>
        <T>Számlázási adatok</T>
      </CardTitle>
      <HookForm
        defaultValues={{
          fizetes_valaszt: "bankkartya",
          ...user,
          // Avoid undefined error
          field_szamlazasi_nev: user.field_szamlazasi_nev || "",
        }}
        yupSchema={schema}
        onSubmit={async (values) => {
          try {
            await createTeam(values, teamName, values.competition.value);
          } catch (e) {
            showErrorToast(
              <>
                <T>Hiba</T>!
              </>,
              <>{e.message}</>
            );
          }
        }}
      >
        <Body>
          <SelectField
            label={<T>Verseny</T>}
            name="competition"
            options={competitions}
            formatOptionLabel={(option) => (
              <Interweave content={option.label} />
            )}
          />
          <ContactField hasStringValue />
          <WideContainer>
            <StyledCardTitle>
              <T>Fizetés</T>
            </StyledCardTitle>
            <label>
              <T>Válassz fizetési módot</T>{" "}
              <a target={"_blank"} href={paymentTermsDoc}>
                <T>Fizetési tájékoztató</T>
              </a>
            </label>
            <Row>
              <SimplePayButton />
              <OTPButton />
            </Row>
            <SubmitButton>
              <T>pay</T>
            </SubmitButton>
          </WideContainer>
        </Body>
      </HookForm>
    </StyledCardBody>
  );
}

async function createTeam(
  contactInfo: Record<string, string>,
  teamName: string,
  competitionNid: string
) {
  const response = await hackilySubmitFormRequest(
    "/node/add/csapatok/csapat_letrehozasa",
    {
      ...getContactInfo(contactInfo),
      "title[0][value]": teamName,
      fizetes_valaszt: contactInfo.fizetes_valaszt || "bankkartya",
      field_verseny: competitionNid,
    },
    {
      postOptions: {
        headers: {
          "do-not-redirect": "true",
        },
      },
      onlyAllowExistingKeys: false,
    }
  );

  const targetLocation = response.headers.get("lokacio");

  if (!targetLocation) {
    console.error("No location header found, headers were", [
      ...response.headers,
    ]);
    const text = await response.text();
    if (
      text.includes(
        "Már csapattag vagy egy másik csapatban amelyik erre a versenyre nevezett!"
      )
    ) {
      throw new Error(
        "Már csapattag vagy egy másik csapatban amelyik erre a versenyre nevezett!"
      );
    }
    alert(
      "Hiba történt a fizetési oldalra átirányítás során! Kérlek próbáld meg később."
    );
    return;
  }

  location.href = targetLocation;
}

const schema = object().shape({
  competition: mixed().required("Verseny kiválasztása kötelező"),
  field_szamlazasi_nev: string().required("Számlázási név megadása kötelező"),
  field_country: string().required("Ország megadása kötelező"),
  field_iranyitoszam: string().required("IRSZ megadása kötelező"),
  field_telepules: string().required("Település megadása kötelező"),
  field_kozterulet_neve: string().required("Közterület megadása kötelező"),
  field_kozterulet_jellege: string().required(
    "Közterület jellegének megadása kötelező"
  ),
  field_hazszam: string().required("Házszám megadása kötelező"),
  field_phone: string().required("Telefonszám megadása kötelező"),
  fizetes_valaszt: string().required("Fizetési opció kiválasztása kötelező"),
});

const CloseButton = styled(ModalDialogX)`
  position: absolute;
  right: ${spacing4};
  top: ${spacing4};
`;

const CardBody = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const StyledCardTitle = styled(CardTitle)`
  margin-top: ${spacing6};
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacing6};
`;

const Body = styled.div`
  ${formBodyStyles};
  display: flex;
  flex-direction: column;
`;

const StyledCardBody = styled(CardBody)`
  max-height: 90vh;
  overflow-y: auto;
`;
