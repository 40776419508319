import { useResources } from "./use-resources";
import { Competition, getEndsAt, getStartsAt } from "../types/competition";
import { EntityType } from "../types/entity";
import { useMemo } from "react";

export function useAreWeBetweenRaces(): boolean | undefined {
  const [races, loading] = useResources<Competition>(EntityType.competition);

  return useMemo(() => {
    if (loading) return undefined;

    return true;

    return !races.find(
      (elem) => getStartsAt(elem) < new Date() && new Date() < getEndsAt(elem)
    );
  }, [loading, races]);
}
