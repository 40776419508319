import { BlockBody, BlueTitle, Button } from "../../../styles/common-styles";
import { useResources } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import {
  EventDescription,
  EventGrid,
  EventRenderer,
  EventRendererContainer,
  EventRendererLeftArrow,
  EventRendererRightArrow,
  EventRendererRow,
  EventSectionTitle,
  EventStats,
  EventTitle,
  ShareButton,
} from "../../general/event-renderer";
import styled from "styled-components";
import {
  between,
  down,
  Restricted,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
  up,
  useModal,
} from "@litbase/alexandria";
import { StatLabel, StatValue } from "../profile/basic-stats-card";
import { Competition, getStartsAt } from "../../../types/competition";
import { Container } from "../../container";
import { Paths } from "../../../utils/paths";
import { Link } from "react-router-dom";
import { useUser } from "../../../hooks/use-user";
import { CompetitionSignup } from "../../../types/competition-signup";
import { useCompetitionPrice } from "../../../hooks/use-competition-price";
import { Interweave } from "interweave";
import { EventTabContentWrapper } from "../profile/my-events/event-tab-content";
import { verticalDown } from "../../../styles/theme-types";
import { RegistrationModal } from "../registration-modal";

export function CompetitionsBlock({ blockIndex }: { blockIndex: number }) {
  const [events, , images] = useResources<Competition>(EntityType.competition, {
    filters: {
      sort: "field_regisztracios_idoszak.value",
      include: "field_kep",
    },
  });

  const competitions = events.map((elem) => ({
    ...elem,
    attributes: {
      ...elem.attributes,
      image: images.find(
        (img) => elem.relationships.field_kep.data.id === img.id
      )?.attributes.uri.url,
    },
  }));

  return (
    <BlockBody
      staticContent={<a id={"versenyek"}></a>}
      blockIndex={blockIndex}
      id="versenyek"
    >
      <Container>
        <Wrapper>
          <BlueTitle>
            <T>Versenyek</T>
          </BlueTitle>
          {competitions?.length && (
            <StyledEventRenderer
              competitions={competitions}
              routePrefix={"/"}
              headerComponent={(elem) => <EventHeader event={elem} />}
            >
              {(event) => <EventInfo event={event} />}
            </StyledEventRenderer>
          )}
        </Wrapper>
      </Container>
    </BlockBody>
  );
}

const Wrapper = styled.div`
  ${verticalDown("xl")} {
    margin-top: 50px;
    max-height: calc(100vh - 100px);
    ${verticalDown("sm")} {
      max-height: 100%;
    }
    display: flex;
    flex-direction: column;
    ${EventRendererContainer} {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
    }
    ${EventTabContentWrapper} {
      padding: ${spacing4};
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      > div:nth-child(2) {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        > div {
          flex: 1;
          min-height: 0;
        }
      }
    }
    ${EventGrid} {
      grid-template-rows: min-content minmax(0, 190px);
    }
    ${EventDescription} {
      font-size: 12px;
      overflow-y: auto;
      padding-right: ${spacing3};
    }
  }
`;

function EventHeader({ event }: { event: Competition }) {
  const [, , includes] = useResources(EntityType.competitionSignup, {
    filters: {
      "[field_verseny.drupal_internal__nid]":
        event.attributes.drupal_internal__nid,
      include: "field_verseny.field_arak",
    },
  });

  const price = useCompetitionPrice(event.id);

  return (
    <>
      <EventTitle>
        <Interweave content={event.attributes.title} />
      </EventTitle>
      <StyledSpan>
        <T>Nevezési díj:</T>{" "}
        <b>{price === 0 ? <T>Hamarosan</T> : price + "Ft"}</b>
      </StyledSpan>
    </>
  );
}

function EventInfo({ event }: { event: Competition }) {
  const user = useUser();
  const { showModal } = useModal();
  const [signups] = useResources(EntityType.competitionSignup, {
    filters: {
      "[field_verseny.drupal_internal__nid]":
        event.attributes.drupal_internal__nid,
    },
    limit: 9999,
  });

  const [currentUsersSignup] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filters: {
        "[field_egyeni_versenyzo.drupal_internal__uid]":
          user.drupal_internal__uid,
        "[field_verseny.drupal_internal__nid]":
          event.attributes.drupal_internal__nid,
        "[statusz_group][group][conjunction]": "OR",

        "[statusz_filter_fizetett][condition][path]": "field_statusz",
        "[statusz_filter_fizetett][condition][operator]": "=",
        "[statusz_filter_fizetett][condition][value]": "fizetett",
        "[statusz_filter_fizetett][condition][memberOf]": "statusz_group",

        "[statusz_filter_fuggoben][condition][path]": "field_statusz",
        "[statusz_filter_fuggoben][condition][operator]": "=",
        "[statusz_filter_fuggoben][condition][value]": "fizetes_fuggoben",
        "[statusz_filter_fuggoben][condition][memberOf]": "statusz_group",
      },
    }
  );

  const hasSignedUpAlready = !!currentUsersSignup.length;

  const price = useCompetitionPrice(event.id);
  const dateCheck = new Date();

  return (
    <EventGrid>
      <EventSectionTitle>
        <T>Részletek</T>
      </EventSectionTitle>
      <ShareButton style={{ opacity: 0 }}>
        <T>Megosztás</T>
      </ShareButton>
      <EventDescription>
        <Interweave content={event.attributes.field_leiras?.value} />
      </EventDescription>
      <EventStats>
        <StatValue>
          <T>Verseny időtartama:</T>
        </StatValue>
        <StatLabel>
          {getStartsAt(event).toLocaleDateString("hu")} -{" "}
          {new Date(
            event.attributes.field_verseny_idoszaka.end_value
          ).toLocaleDateString("hu")}
        </StatLabel>
        <StatValue>
          <T>Jelentkezési időszak:</T>
        </StatValue>
        <StatLabel>
          {new Date(
            event.attributes.field_regisztracios_idoszak.value
          ).toLocaleDateString("hu")}{" "}
          -{" "}
          {new Date(
            event.attributes.field_regisztracios_idoszak.end_value
          ).toLocaleDateString("hu")}
        </StatLabel>
        {/*<StatValue>Eddigi jelentkezők:</StatValue>*/}
        {/*<StatLabel>{signups.length} fő</StatLabel>*/}
        <StatValue>
          <T>Nevezési díj:</T>
        </StatValue>
        <StatLabel>{price === 0 ? <T>Hamarosan</T> : price + "Ft"}</StatLabel>
        <Restricted>
          {hasSignedUpAlready && user.field_name !== "Arthur944" ? (
            <Span>
              <T>Már jelentkeztél erre a versenyre</T>
            </Span>
          ) : new Date(event.attributes.field_regisztracios_idoszak.value) <=
              dateCheck &&
            dateCheck <=
              new Date(
                event.attributes.field_regisztracios_idoszak.end_value
              ) ? (
            <Link to={Paths.EVENT_SIGNUP + "/" + event.id}>
              <Button>
                <T>Jelentkezem</T>
              </Button>
            </Link>
          ) : (
            <Span>
              <T>Jelenleg nincs jelentkezési időszak!</T>
            </Span>
          )}
        </Restricted>
        <Restricted guest>
          <Button
            style={{ width: "fit-content" }}
            onClick={() => showModal(<RegistrationModal />)}
          >
            <T>Jelentkezem</T>
          </Button>
        </Restricted>
      </EventStats>
    </EventGrid>
  );
}

const StyledEventRenderer = styled(EventRenderer)`
  ${EventRendererRow} {
    ${down("md")} {
      // So that left and right arrows have space
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  ${EventTabContentWrapper} {
    // So that left and right arrows have space
    ${down("md")} {
      margin-left: 50px;
      margin-right: 50px;
    }
  }

  ${EventRendererLeftArrow} {
    // Prevent positioning too far left on mobile and practically disappear
    ${down("md")} {
      transform: translateY(-50%);
    }
  }
  ${EventRendererRightArrow} {
    // Prevent pushing over <html> on mobile
    ${down("md")} {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  ${down("md")} {
    // More space so "delay" overlapping
    padding: 0;

    ${EventSectionTitle} {
      // No need for big space here, looks weird on smaller screens
      margin-bottom: 0;
    }
  }

  ${EventStats} {
    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    ${down("sm")} {
      grid-template-columns: 1fr;
    }

    ${down("md")} {
      margin-top: ${spacing8};
      padding-left: 0;
      padding-right: 0;

      ${StatValue}, ${StatLabel} {
        justify-self: start;
      }
    }

    ${between("sm", "md")} {
      a {
        grid-column: span 2;
      }
    }

    ${between("md", "lg")} {
      grid-template-columns: 1fr;
    }

    ${up("lg")} {
      a {
        grid-column: span 2;
      }
    }
  }
`;

const Span = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-top: ${spacing6};
  text-align: end;

  ${between("sm", "md")} {
    grid-column: span 2;
  }

  ${up("lg")} {
    grid-column: span 2;
  }
`;

const StyledSpan = styled.span`
  font-size: 14px;
`;
