import { useResource } from "./use-resources";
import { EntityType } from "../types/entity";
import { Competition } from "../types/competition";

export function useCompetitionPrice(id: string) {
  const [result] = useResource<{ included: Record<string, unknown>[] }>(
    EntityType.competition,
    id + "?include=field_arak"
  );

  return (
    result?.included?.find((elem) => elem.type === "node--arak")?.attributes
      ?.field_ar || 0
  );
}
