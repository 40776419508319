import styled, { css } from "styled-components";

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const activeStepCircleStyle = css`
  background: linear-gradient(180deg, #15018e 0%, #6534ff 100%),
    linear-gradient(180deg, #5a0085 0%, #dc93ff 100%);
  border-width: 0;
`;

export const StepCircle = styled.div<{ $isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 24px;
  font-family: ${({ theme }) => theme.fontFamilyBase};
  border: 2px solid ${({ theme }) => theme.blue};

  ${({ $isActive }) => $isActive && activeStepCircleStyle};
`;

export const Line = styled.div`
  width: 82px;
  height: 2px;
  background-color: ${({ theme }) => theme.blue};
`;
