import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function TopScroller() {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.match(/^\/\d+$/)) {
      document.documentElement.classList.add("auto-scroll-behavior");
      window.scrollTo({ left: 0, top: 0, behavior: "auto" });
      // Defer scrolling up for a microtask, so the page can actually transition before we scroll up
      setTimeout(() => {
        // document.documentElement.classList.remove("auto-scroll-behavior");
      }, 1);
    }
  }, [location.pathname]);

  return null;
}
