import { Link, NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";
import {
  down,
  spacing12,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
  transition,
} from "@litbase/alexandria";
import { Card } from "./common-styles";
import {
  BarChartAlt2,
  FlagCheckered,
  Group,
  User,
} from "@styled-icons/boxicons-solid";
import defaultProfilePicture from "./profile-settings/assets/default_profilkep.svg";
import { useUser } from "../../../hooks/use-user";
import { activeButtonStyle, Button } from "../../../styles/common-styles";
import { StyledIconBase } from "@styled-icons/styled-icon";
import { verticalDown } from "../../../styles/theme-types";
import { QuestionMark } from "@styled-icons/boxicons-regular";

export function Sidebar() {
  const user = useUser();
  const profilePic = user.field_profil_kep
    ? process.env.BACKEND_URL + user.field_profil_kep
    : defaultProfilePicture;

  return (
    <>
      <Body>
        <EditProfileButton to="szerkesztes">
          <ProfilePicture src={profilePic} />
          <UserName>{user.field_name}</UserName>
          <EditProfileText>
            <T>Profil szerkesztése</T>
          </EditProfileText>
        </EditProfileButton>

        <SidebarButtons>
          <SidebarButton end to="/profilom/">
            <User />{" "}
            <span>
              <T>Teljesítmény</T>
            </span>
          </SidebarButton>
          <SidebarButton to="versenyeim">
            <FlagCheckered />{" "}
            <span>
              <T>Versenyeim</T>
            </span>
          </SidebarButton>
          <SidebarButton to="csapatom">
            <Group />{" "}
            <span>
              <T>Csapatom</T>
            </span>
          </SidebarButton>
          <SidebarButton to="ranglista">
            <BarChartAlt2 />{" "}
            <span>
              <T>Ranglista</T>
            </span>
          </SidebarButton>
          <SidebarButton to="strava">
            <StravaLogo /> <span>Strava</span>
          </SidebarButton>
          {/*<SidebarButton to={"gyik"}>*/}
          {/*  <QuestionMark /> <span><T>GYIK</T></span>*/}
          {/*</SidebarButton>*/}
        </SidebarButtons>
      </Body>
    </>
  );
}

const SidebarButton = styled(Button).attrs(() => ({
  as: SidebarButtonComponent,
}))`
  width: 100%;
  text-transform: uppercase;
  align-items: center;

  svg {
    font-size: 1.4em;
    margin-right: ${spacing2};
    // Offset icon base
    margin-top: -2px;

    ${down("lg")} {
      margin: auto;
    }
  }

  &.active {
    ${activeButtonStyle};
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  & + & {
    margin-top: ${spacing6};
  }

  > span > span {
    ${down("lg")} {
      display: none;
    }
  }

  ${down("lg")} {
    width: auto;
    margin-top: 0 !important;
    padding: ${spacing2} ${spacing4};
    font-size: 15px;
  }
`;

function SidebarButtonComponent({ children, ...props }: NavLinkProps) {
  return (
    <NavLink {...props}>
      <span>{children}</span>
    </NavLink>
  );
}

const ProfilePicture = styled.img`
  border: 3px solid white;
  border-radius: 50%;
  object-fit: cover;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  transition: ${({ theme }) =>
    transition(["border-color"], theme.fastTransitionTiming)};
  ${verticalDown("lg")} {
    width: 100px;
    height: 100px;
  }
`;

const EditProfileButton = styled(NavLink)`
  color: white;
  font-size: 14px;
  text-align: center;
  margin-bottom: ${spacing12};
  transition: ${({ theme }) =>
    transition(["color"], theme.fastTransitionTiming)};

  > span {
    white-space: nowrap;
  }

  &:hover,
  &.active {
    text-decoration: none;
    color: ${({ theme }) => theme.blue};

    ${ProfilePicture} {
      border-color: ${({ theme }) => theme.blue};
    }
  }

  ${down("lg")} {
    display: block;
    margin-bottom: ${spacing6};
    > span {
      display: block;
    }
  }
`;

const Body = styled(Card)`
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid white;
  border-radius: ${({ theme }) => theme.roundedLg};
  padding: ${spacing6};
  width: 240px;
  max-width: 100%;
  height: fit-content;

  ${down("lg")} {
    width: 100%;
  }

  ${down("lg")} {
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: ${spacing8};
    max-width: 100%;
  }
`;

const EditProfileText = styled.div`
  margin-top: ${spacing2};
  font-size: ${({ theme }) => theme.textBase};

  ${down("lg")} {
    display: none;
  }
`;

const UserName = styled.div`
  margin-top: ${spacing2};
  font-size: ${({ theme }) => theme.textBase};
`;

const SidebarButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${down("lg")} {
    flex-direction: row;
    justify-content: center;

    > a + a {
      margin-left: ${spacing6};
      ${down("sm")} {
        margin-left: 0;
      }
    }

    a:last-child {
      margin-right: 0;
    }
  }
  ${down("sm")} {
    justify-content: space-between;
  }
`;

export function StravaLogo() {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        d="m41.03 47.852-5.572-10.976h-8.172L41.03 64l13.736-27.124h-8.18"
        fill="#f9b797"
      />
      <path
        d="m27.898 21.944 7.564 14.928h11.124L27.898 0 9.234 36.876H20.35"
        fill="#f05222"
      />
    </Svg>
  );
}

const Svg = styled.svg`
  height: 1em;
`;
