import { Row } from "./common-styles";
import { SelectField } from "../../form/select-field";
import { InputField } from "../../form/input-field";
import { ReactNode } from "react";
import styled from "styled-components";
import { T } from "@litbase/alexandria";
import { kozteruletTypeOptions } from "../../../utils/kozterulet-type-options";
import { NumberFormatInputField } from "../../form/number-format-input-field";

const countryOptions = [{ value: "Magyarország", label: <T>Magyarország</T> }];

export function ContactField({
  name,
  isBillingData,
  children,
  hasStringValue,
  hasTaxNumber = false,
}: {
  name?: string;
  children?: ReactNode;
  isBillingData?: boolean;
  hasStringValue?: boolean;
  hasTaxNumber?: boolean;
}) {
  const prefix = name ? name + "." : "";
  return (
    <>
      <StyledRow>
        <InputField
          name="field_szamlazasi_nev"
          label={<T>Számlázási név*</T>}
        />
        <div style={{ flex: 1 }} />
        {hasTaxNumber && (
          <NumberFormatInputField
            name={"field_adoszam"}
            label={<T>Adószám</T>}
            format={"########-#-##"}
            mask={"_"}
          />
        )}
      </StyledRow>
      <StyledRow>
        <SelectField
          name={prefix + "field_country"}
          label={
            <>
              <T>Ország</T>*
            </>
          }
          options={countryOptions}
          hasStringValue={hasStringValue}
        />
        <InputField
          name={prefix + "field_iranyitoszam"}
          maxLength={4}
          label={
            <>
              <T>Irányítószám</T>*
            </>
          }
        />
        <InputField
          name={prefix + "field_telepules"}
          label={
            <>
              <T>Település</T>*
            </>
          }
        />
      </StyledRow>
      <Row>
        <InputField
          name={prefix + "field_kozterulet_neve"}
          label={
            <>
              <T>Közterület neve</T>*
            </>
          }
        />
        <SelectField
          name={prefix + "field_kozterulet_jellege"}
          label={
            <>
              <T>Közterület jellege</T>*
            </>
          }
          hasStringValue={hasStringValue}
          options={kozteruletTypeOptions}
        />
        <InputField
          name={prefix + "field_hazszam"}
          label={
            <>
              <T>Házszám</T>*
            </>
          }
        />
        {children}
      </Row>
      <Row>
        {!isBillingData && (
          <InputField
            name={prefix + "field_phone"}
            maxLength={12}
            label={
              <>
                <T>Telefonszám</T>*
              </>
            }
          />
        )}
      </Row>
    </>
  );
}

const StyledRow = styled(Row)`
  margin-top: 0;
`;
