import styled from "styled-components";
import { BlockBody, BlueTitle } from "../../../styles/common-styles";
import {
  down,
  spacing16,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
} from "@litbase/alexandria";
import { breakPointsPixels, verticalDown } from "../../../styles/theme-types";
import { useRef } from "react";
import { BlockData, BlockType } from "../../../types/block";
import earth from "./assets/about-earth.png";
import moon from "./assets/about-moon.png";
import { ConnectionContainer } from "../rules/connection-container";
import { DivConnection } from "../rules/div-connection";
import { useVisibleBlock } from "../../general/block-indicator";
import { config } from "react-spring";
import { useObservable } from "@litbase/use-observable";
import { delay, map } from "rxjs/operators";
import rocketSvg from "./assets/rocket.svg";
import run from "./assets/run.svg";
import bgImage from "./assets/block-4-bg.png";

export function AboutTheCompetitionBlock({
  blockIndex,
  block,
}: {
  blockIndex: number;
  block: BlockData<BlockType.aboutCompetition>;
}) {
  const [activeBlock] = useVisibleBlock();

  const [isVisible] = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        delay(1000),
        map(([activeBlock]) => {
          return activeBlock === blockIndex;
        })
      );
    },
    false,
    [activeBlock]
  );

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  return (
    <Body blockIndex={blockIndex} backgroundImage={bgImage} hasFallingStars>
      <Content>
        <BlueTitle>{block.attributes.field_comp_seri_title}</BlueTitle>
        <p>{block.attributes.field_comp_seri_p_1}</p>
        <Row>
          <div ref={ref1}>
            <Img src={earth} />
          </div>
          <Col>
            <span>
              <Icon src={rocketSvg} /> <T>3 Nap</T>
            </span>
            <span>
              <Icon src={run} /> <T>9,5 Év</T>
            </span>
          </Col>
          <div ref={ref2}>
            <Img src={moon} />
          </div>
          <ConnectionContainer>
            <DivConnection
              springProps={{ config: { duration: 3000 } }}
              start={isVisible}
              fromDiv={ref1}
              toDiv={ref2}
              curvyness={0}
              toX={0.12}
              toY={0.3}
              fromY={0.3}
            />
            <DivConnection
              springProps={{ config: { duration: 15000 } }}
              start={isVisible}
              fromDiv={ref1}
              toDiv={ref2}
              curvyness={0}
              toX={0.12}
              fromY={0.7}
              toY={0.7}
            />
          </ConnectionContainer>
        </Row>
        <Highlighted>
          {block.attributes.field_comp_seri_highlighted}
        </Highlighted>
        <p>{block.attributes.field_comp_seri_p_2}</p>
        <HighlightedSecondary>
          {block.attributes.field_comp_seri_colored}
        </HighlightedSecondary>
      </Content>
    </Body>
  );
}

const StyledTitle = styled(BlueTitle)`
  margin-top: ${spacing3};
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${spacing4} 0;
`;

const Img = styled.img`
  width: 250px;
  height: 250px;
  ${verticalDown("lg")} {
    width: 125px;
    height: 125px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${down("xl")} {
    display: none;
  }
`;

const Content = styled.div`
  max-width: ${breakPointsPixels.xl}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HighlightedSecondary = styled.p`
  color: ${({ theme }) => theme.lightPurple};
`;

const Body = styled(BlockBody)`
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${spacing16};
  background-size: cover;
  > * + * {
    margin-top: ${spacing8};
  }
  ${down("md")} {
    padding: ${spacing6};
  }
`;

const Highlighted = styled.div`
  border-top: 1px solid ${({ theme }) => theme.lightPurple};
  border-bottom: 1px solid ${({ theme }) => theme.lightPurple};
  color: white;
  font-size: 24px;
  padding: ${spacing3} ${spacing8};
  margin: ${spacing8} 0;
  ${down("md")} {
    text-align: center;
  }
`;
