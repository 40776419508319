import styled from "styled-components";
import heroBackgroundImage from "./assets/hero-bg.png";
import { Container } from "../../container";
import {
  down,
  LoadingIcon,
  Restricted,
  spacing12,
  spacing16,
  spacing2,
  spacing32,
  spacing4,
  spacing6,
  spacing8,
  T,
  up,
  useModal,
} from "@litbase/alexandria";
import { useEffect, useState } from "react";
import { Interval, intervalToDuration, getDay } from "date-fns";
import { animated, useTransition } from "react-spring";
import { BlockBody, SignUpButton } from "../../../styles/common-styles";
import { BlockData, BlockType } from "../../../types/block";
import { sumBy } from "lodash-es";
import { RegistrationModal } from "../registration-modal";
import { Logo } from "../../general/logo";
import { useResources } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import { getStartsAt } from "../../../types/competition";
import { verticalDown } from "../../../styles/theme-types";
import { Team } from "../../../types/team";
import { getImageUrl } from "../../../utils/get-image-url";
import { useObservable } from "@litbase/use-observable";
import { sendBackendRequest } from "../../../services/backend";
import { switchMap } from "rxjs/operators";
import {
  formatKilometer,
  FormatKilometer,
} from "../../../utils/format-kilometer";
import { useCurrentCompetitionData } from "../profile/main-stats-card";

const bg = getImageUrl({
  src: heroBackgroundImage,
  width: 3000,
  height: 1080,
  gravity: "center",
});

export function HeroBlock({
  blockIndex,
  block,
}: {
  blockIndex: number;
  block: BlockData<BlockType.hero>;
}) {
  const { showModal } = useModal();

  return (
    <Body blockIndex={blockIndex} backgroundImage={bg} id="hero">
      <StyledContainer>
        <Grid>
          <Spacing />
          <FirstRowText>
            <StlyedLogo />
          </FirstRowText>
          <HeroButtonContainer>
            <Restricted guest>
              <SignUpButton
                $big
                onClick={() => showModal(<RegistrationModal />)}
              >
                {block.attributes?.field_button_text}
              </SignUpButton>
            </Restricted>
            <Restricted>
              <SignUpButton
                $big
                onClick={() =>
                  document.querySelector("#versenyek")?.scrollIntoView()
                }
              >
                {block.attributes?.field_button_text}
              </SignUpButton>
            </Restricted>
          </HeroButtonContainer>
          <Spacing />

          <LastRow>
            <DistanceCovered />
          </LastRow>
          <LastRow>
            <FollowingEvent />
          </LastRow>
          <Slogan>
            <T>Gyűjts kilométereket bárhol!</T>
          </Slogan>
        </Grid>
      </StyledContainer>
    </Body>
  );
}

export function useFullDistance() {
  const [progress, loading] = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(async () => {
          const json = await sendBackendRequest(
            "/moonshot_distance/stats/get_all_distance"
          ).json();
          return json.distance;
        })
      ),
    null,
    []
  );
  return [progress, loading];
}

function DistanceCovered() {
  const data = useCurrentCompetitionData();
  return (
    <DistanceCoveredWrapper>
      <DistanceCounter>
        {!data ? <LoadingIcon /> : formatKilometer(data.distance)}
      </DistanceCounter>
      <DistanceText>
        <T>Megtett kilométerek</T>
      </DistanceText>
    </DistanceCoveredWrapper>
  );
}

function FollowingEvent() {
  const [competitions] = useResources(EntityType.competition);

  const nextEvent = [...competitions]
    .sort((a, b) => getStartsAt(a) - getStartsAt(b))
    .filter((elem) => {
      return getStartsAt(elem) > new Date();
    })?.[0];

  const nextDate = nextEvent ? getStartsAt(nextEvent) : new Date();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const [days, hours, minutes, seconds] = formatIntervalUntil(nextDate);
      setDays(days);
      setHours(hours - 2);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 500);
    return () => clearInterval(interval);
  }, [nextDate]);

  return (
    <FollowingEventWrapper>
      <TimerWrapper>
        <Number>
          <CountdownNumber number={days} />
        </Number>
        <Number>
          <CountdownNumber number={hours} />
        </Number>
        <Number>
          <CountdownNumber number={minutes} />
        </Number>
        <Number>
          <CountdownNumber number={seconds} />
        </Number>
        <Text>
          <T>N</T>
        </Text>
        <Text>
          <T>Ó</T>
        </Text>
        <Text>
          <T>P</T>
        </Text>
        <Text>
          <T>MP</T>
        </Text>
      </TimerWrapper>
      <FollowingEventText>
        <T>A következő eseményig</T>
      </FollowingEventText>
    </FollowingEventWrapper>
  );
}

function CountdownNumber({ number: number }: { number: number }) {
  const numbers = String(number).padStart(2, "0").split("");
  return (
    <>
      <span>
        {numbers.map((number, index) => (
          <CountdownNumberElem key={index} number={number} />
        ))}
      </span>
    </>
  );
}

function CountdownNumberElem({ number }: { number: number }) {
  const transitions = useTransition(number, {
    from: { opacity: 0, transform: "translateY(-3rem)" },
    enter: { opacity: 1, transform: "translateY(0rem)" },
    leave: { opacity: 0, transform: "translateY(3rem)" },
  });
  return (
    <NumberContainer>
      {transitions((styles, item) => (
        <NumberBody style={styles}>{item}</NumberBody>
      ))}
    </NumberContainer>
  );
}

const Spacing = styled.div`
  grid-column: 1 / -1;
`;

const Slogan = styled.span`
  margin: 0 auto;
  grid-column: 1 / -1;
  font-size: 24px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  order: 4;
`;

const StyledContainer = styled(Container)`
  padding: 0;
  height: 100%;
`;

const NumberBody = styled(animated.span)`
  position: absolute;
  height: 100%;
  text-align: center;
  width: 0.75em;
  left: 0;
  top: 0;
  font-weight: bold;
`;

const NumberContainer = styled.span`
  position: relative;
  width: 0.65em;
  height: 1em;
  display: inline-flex;
  justify-content: center;
  text-align: center;
`;

function formatIntervalUntil(date?: Date) {
  const duration = date - new Date();

  const days = Math.floor(duration / 86400000);
  const hours = Math.floor((duration - days * 86400000) / 3600000);
  const minutes = Math.floor(
    (duration - (days * 86400000 + hours * 3600000)) / 60000
  );
  const seconds = Math.floor(
    (duration - (days * 86400000 + hours * 3600000 + minutes * 60000)) / 1000
  );

  return [days || 0, hours || 0, minutes || 0, seconds || 0];
}

const Body = styled(BlockBody)<{ $backgroundImage: string }>`
  color: white;
  background-position-y: 10%;
  background-position-x: center;
  background-size: auto 100% !important;
  background-repeat: no-repeat;
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  overflow: auto;
  ${up("xxl")} {
    height: 100vh;
  }
  ${down("md")} {
    height: fit-content;
    flex-shrink: 0;
    padding: ${spacing6};
    padding-top: 0;
  }
  ${down("xl")} {
    height: fit-content;
    flex-shrink: 0;
  }
  > div,
  > div > div {
    height: 100%;
    ${down("xl")} {
      height: fit-content;
      flex-shrink: 0;
    }
  }
`;

const Grid = styled.div`
  margin-top: auto;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${spacing32} min-content min-content 1fr min-content min-content;
  grid-column-gap: 100px;
  grid-row-gap: ${spacing8};
  align-content: center;
  height: 100%;

  ${verticalDown("lg")} {
    grid-template-rows: ${spacing16} min-content min-content 1fr min-content min-content;
  }

  > div {
    max-height: 250px;
  }

  ${down("lg")} {
    display: flex;
    flex-direction: column;
    grid-row-gap: ${spacing12};
  }
`;

const HeroButtonContainer = styled.div`
  grid-column: 1 / -1;
  padding-top: ${spacing4};
  ${verticalDown("lg")} {
    padding-bottom: ${spacing2};
    padding-top: ${spacing2};
    padding-bottom: ${spacing4};
  }
  ${down("md")} {
    order: 4;
  }
`;

const LastRow = styled.div`
  justify-content: center;
  top: 0;
  ${down("md")} {
     {
      order: 5;
      &:last-child {
        order: 3;
      }
    }
  }
`;

const FirstRowText = styled.div`
  grid-column: 1 / -1;
  font-size: 28px;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  > span {
    color: ${({ theme }) => theme.gray200};
    font-size: 0.8em;
  }

  ${down("lg")} {
    padding-top: ${spacing12};
  }

  ${down("md")} {
    padding-top: ${spacing32};
    font-size: 18px;
    order: 1;
  }
`;
const DistanceCoveredWrapper = styled.div`
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  text-align: center;
  color: white;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  padding-bottom: ${spacing6};
  ${down("md")} {
    margin-top: ${spacing32};
  }
`;

const FollowingEventWrapper = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.headingsFontFamily};
`;

const TimerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 0.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-top: solid 2px white;
  border-bottom: solid 2px white;
  padding-bottom: 0.95rem;
`;

const DistanceCounter = styled.div`
  font-size: 62px;
  ${verticalDown("lg")} {
    font-size: 48px;
  }
  font-weight: bold;
  ${down("xl")} {
    font-size: 48px;
  }
`;

const Number = styled.div`
  font-size: 64px;
  ${verticalDown("lg")} {
    font-size: 48px;
  }
  ${down("xl")} {
    font-size: 48px;
  }
`;

const DistanceText = styled.div`
  font-size: 28px;
  ${down("xl")} {
    font-size: 24px;
  }
`;

const Text = styled.div`
  font-size: 28px;
`;

const FollowingEventText = styled.div`
  font-size: 18px;
  margin-top: ${spacing4};
  ${down("md")} {
    white-space: nowrap;
  }
`;

const StlyedLogo = styled(Logo)`
  color: white;
  max-width: 356px;
`;
