import { BlockFields } from "../components/pages/block-types";

export enum BlockType {
  hero = "paragraph--hero",
  aboutUs = "paragraph--a_moonshotrol",
  howItWorks = "paragraph--hogyan_mukodik_a_verseny",
  aboutCompetition = "paragraph--moonshot_versenysorozat",
  events = "paragraph--esemenyeink",
  moon = "paragraph--a_hold",
  virtualCompetition = "paragraph--mi_az_a_virtualis_verseny",
  earth = "paragraph--foldkelte",
  competitions = "paragraph--versenyek",
}

export interface Block {
  name: string;
  type: BlockType;
}

export interface BlockData<T extends BlockType> {
  type: T;
  id: string;
  attributes: BlockFields[T];
}
