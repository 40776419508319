import styled from "styled-components";

export function Arrow({ className, ...props }: { className?: string }) {
  return (
    <Svg
      {...props}
      className={className}
      width="8"
      height="26"
      viewBox="0 0 8 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29468 25.0882C7.07239 25.1995 6.81496 25.2181 6.57902 25.1397C6.34308 25.0612 6.14795 24.8923 6.03655 24.67L0.41155 13.42C0.346222 13.2897 0.312206 13.1459 0.312206 13C0.312206 12.8542 0.346222 12.7104 0.41155 12.58L6.03655 1.33004C6.09112 1.219 6.16713 1.11986 6.2602 1.03834C6.35327 0.956814 6.46156 0.89452 6.57882 0.855045C6.69608 0.815571 6.81999 0.799696 6.94341 0.808338C7.06684 0.81698 7.18733 0.849969 7.29794 0.905399C7.40856 0.96083 7.5071 1.03761 7.5879 1.1313C7.6687 1.225 7.73016 1.33377 7.76872 1.45133C7.80729 1.56889 7.8222 1.69292 7.8126 1.81627C7.80301 1.93963 7.76909 2.05986 7.7128 2.17004L2.29968 13L7.7128 23.83C7.82418 24.0523 7.84271 24.3098 7.76429 24.5457C7.68588 24.7816 7.51696 24.9768 7.29468 25.0882Z"
        fill="#6534FF"
      />
    </Svg>
  );
}

const Svg = styled.svg`
  path {
    fill: currentColor;
  }
`;
