import styled from "styled-components";
import {
  down,
  HookForm,
  HookInputField,
  HookSubmitButton,
  ModalDialogContent,
  ModalDialogText,
  SimpleModal,
  spacing12,
  spacing16,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
  transition,
  useModal,
} from "@litbase/alexandria";
import heroBackgroundImage from "../../assets/modal-bg.png";
import { Link, useNavigate } from "react-router-dom";
import { montserrat } from "../../styles/theme-types";
import { object, string } from "yup";
import { RegistrationModal } from "./registration-modal";
import { login } from "../../hooks/use-user";
import { PasswordResetModal } from "./auth/password-reset-modal";
import { HTTPError } from "ky";
import { SubmitButton, SubmitButtonWrapper } from "../../styles/common-styles";
import { PasswordField } from "../form/password-field";
import { FormErrorDisplay } from "../form/form-error-display";
import { ModalParagraph } from "../../styles/modal-styles";
import { getImageUrl } from "../../utils/get-image-url";

export function LoginModal() {
  const { showModal, dismissModal } = useModal();
  const navigate = useNavigate();

  return (
    <MoonshotModal>
      <Title>
        <T>Belépés</T>
      </Title>
      <ModalParagraph>
        <T>Kérlek add meg adataidat a belépéshez:</T>
      </ModalParagraph>
      <HookForm<{ email: string; password: string }>
        defaultValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          try {
            await login(values);
            dismissModal();
            navigate("/profilom");
          } catch (error) {
            if (error instanceof HTTPError) {
              const errorResponse = await error.response.json();

              if (
                errorResponse?.message.includes(
                  "unrecognized username or password"
                )
              ) {
                throw new Error("Nem megfelelő felhasználónév vagy jelszó");
              }

              throw new Error(errorResponse?.message || String(errorResponse));
            }

            throw error;
          }
        }}
        yupSchema={emailLoginObjectSchema}
      >
        <FormErrorDisplay />
        <StyledHookInputField
          name="email"
          type="email"
          label={<T>Email cím</T>}
          placeholder="email@cimed.hu"
        />
        <PasswordField
          label={<T>Jelszó</T>}
          name="password"
          placeholder="●●●●●●●●"
        />

        <div>
          {/*<HookCheckboxField*/}
          {/*  name="rememberMe"*/}
          {/*  checkboxLabel="Maradjak belépve"*/}
          {/*/>*/}
          <StyledLink
            to=""
            onClick={(event) => {
              event.preventDefault();
              showModal(<PasswordResetModal />);
            }}
          >
            <T>Elfelejtettem a jelszavam</T>
          </StyledLink>
        </div>
        <SubmitButtonWrapper>
          <SubmitButton>
            <T>Belépés</T>
          </SubmitButton>
        </SubmitButtonWrapper>
      </HookForm>

      <RegistrationWrapper>
        <T>Még nincs felhasználói fiókod?</T>{" "}
        <StyledLink
          to=""
          onClick={(event) => {
            event.preventDefault();
            showModal(<RegistrationModal />);
          }}
        >
          <T>Regisztrálj</T>
        </StyledLink>
      </RegistrationWrapper>
    </MoonshotModal>
  );
}
const emailLoginObjectSchema = object().shape({
  email: string()
    .required("E-mail cím megadása kötelező")
    .email("E-mail cím formátuma nem megfelelő"),
  password: string().required("Jelszó megadása kötelező"),
});

export const StyledHookInputField = styled(HookInputField)`
  color: white;
  background: transparent;
  max-width: 100%;
  ::placeholder {
    color: white;
  }
  ${down("sm")} {
    min-width: 100%;
  }
`;

const herobg = getImageUrl({
  src: heroBackgroundImage,
  width: 1080,
  height: 1920,
});

export const MoonshotModal = styled(SimpleModal)`
  ${montserrat};
  background-image: url(${herobg});
  background-size: cover;
  background-position: center;
  width: 500px;
  padding: 20px;

  ${ModalDialogText} {
    color: white;
    overflow-x: hidden;
  }

  ${down("sm")} {
    width: 100%;
    max-height: 90vh;
    padding: 0;
    padding-bottom: ${spacing8};
    > div {
      max-height: 85vh;
      padding: 0;
    }
    ${ModalDialogContent} {
      padding: 0;
      > div {
        padding: ${spacing8};
      }
    }
  }
`;

export const StyledHookSubmitButton = styled(HookSubmitButton)`
  text-align: center;
  background-color: transparent;
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  padding-left: ${spacing8};
  padding-right: ${spacing8};
  border: 3px solid ${({ theme }) => theme.lightBlue};
  border-radius: 5px;
  letter-spacing: 2px;
  margin: auto;
  transition: ${({ theme }) =>
    transition(["color", "border-color"], theme.fastTransitionTiming)};

  &:hover {
    color: ${({ theme }) => theme.lightBlue} !important;
    background-color: transparent !important;
    border-color: ${({ theme }) => theme.blue};
  }
`;

const RegistrationWrapper = styled.div`
  text-align: center;
  margin-bottom: ${spacing4};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.lightBlue};
  transition: ${({ theme }) =>
    transition(["color"], theme.fastTransitionTiming)};

  &:hover {
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
  }
`;

export const EyeWrapper = styled(StyledLink)`
  position: absolute;
  // Align the eye approximately to the center of the input
  top: 1.75rem;
  right: ${spacing2};
  font-size: ${({ theme }) => theme.text3xl};
  display: flex;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamilyBase};
  font-size: 36px;
  margin-top: ${spacing4};
  line-height: 1;
`;
