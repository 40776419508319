import ky, { Options, ResponsePromise } from "ky";
import { currentLanguageSettings$ } from "@litbase/alexandria";

export function sendBackendRequest(
  path: string,
  options?: Options
): ResponsePromise {
  const lang = currentLanguageSettings$.getValue()?.language;
  console.log({ lang });
  return ky(process.env.BACKEND_URL + (lang === "en" ? "/en" : "") + path, {
    credentials: "include",
    ...options,
  });
}

export interface HackilySubmitFormOptions {
  formSelector?: string;
  getOptions?: Options;
  postOptions?: Options;
  onlyAllowExistingKeys?: boolean;
}

export async function hackilySubmitFormRequest(
  url: string,
  data: Record<string, unknown>,
  options?: HackilySubmitFormOptions
) {
  const formData = await getPreSubmitFormData(url, options);

  for (const [key, value] of Object.entries(data)) {
    if (
      false === options?.onlyAllowExistingKeys ||
      formData.has(key) ||
      key === "op"
    ) {
      formData.set(key, String(value));
    } else {
      console.error(`WARNING: Trying to set unknown form key "${key}"`);
    }
  }

  return sendBackendRequest(url, {
    method: "POST",
    body: formData,
    ...options?.postOptions,
  });
}

export async function hackilySubmitForm(
  url: string,
  data: Record<string, string>,
  options?: HackilySubmitFormOptions
) {
  const formData = await getPreSubmitFormData(url, options);

  for (const [key, value] of Object.entries(data)) {
    if (formData.has(key) || key === "op") {
      formData.set(key, value);
    } else {
      console.error(`WARNING: Trying to set unknown form key "${key}"`);
    }
  }

  const responseObject = await hackilySubmitFormRequest(url, data, options);
  const response = await responseObject.text();

  const document = parseHtml(response);
  const errorMessageAlert = document.querySelector(
    "div[data-drupal-messages] div.messages--error"
  );

  if (!errorMessageAlert) {
    return response;
  }

  const errorTitle = errorMessageAlert.querySelector("h2")?.textContent || "";
  const errorMessage =
    errorMessageAlert.querySelector(".messages__content")?.textContent || "";

  throw new Error(`${errorTitle}: ${errorMessage}`);
}

export interface GetPreSubmitFormDataOptions {
  getOptions?: Options;
  formSelector?: string;
}

async function getPreSubmitFormData(
  url: string,
  options?: GetPreSubmitFormDataOptions
) {
  const text = await sendBackendRequest(url, options?.getOptions).text();

  const { formData } = getFormDataFromHtml(text, options?.formSelector);
  return formData;
}

export function getFormDataFromHtml(
  html: string,
  formSelector: string = "form"
) {
  const document = parseHtml(html);
  const form = document.querySelector<HTMLFormElement>(formSelector);

  if (!form) {
    console.error("WARNING: Could not find form in getPreSubmitFormData()");
    return { formData: new FormData(), form: document.createElement("form") };
  }

  const formData = new FormData(form);

  for (const button of form.querySelectorAll("button")) {
    const name = button.name;
    const value = button.value;

    if (name) {
      formData.set(name, value);
    }
  }

  return { formData, form };
}

const domParser = new DOMParser();

export function parseHtml(html: string) {
  return domParser.parseFromString(html, "text/html");
}

export async function hackilyGetFormTokenAndStuff(url: string) {
  const text = await sendBackendRequest(url).text();
  const formBuildId = text.match(/name="form_build_id" value="(.+)"/)?.[1];
  const changed = text.match(/name="changed" value="(.+)"/)?.[1];
  const formToken = text.match(/name="form_token" value="(.+)"/)?.[1];
  const formId = text.match(/name="form_id" value="(.+)"/)?.[1];

  return { formBuildId, changed, formToken, formId };
}

export function getNewCsrfToken() {
  return sendBackendRequest("/session/token").text();
}
