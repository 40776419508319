import { Card, CardTitle } from "../common-styles";
import { ContactField } from "../../event-signup/contact-field";
import { HookForm, showErrorToast, spacing, T } from "@litbase/alexandria";
import styled from "styled-components";
import { SimplePayButton } from "../../../general/payment-buttons";
import { SubmitButton } from "../../../../styles/common-styles";
import paymentProcessorsSrc from "./assets/payment_processors.png";
import { UserNode } from "../../../../hooks/use-user";
import { sendBackendRequest } from "../../../../services/backend";
import { Team } from "../../../../types/team";
import { useCompetitionPrice } from "../../../../hooks/use-competition-price";
import { useNavigate } from "react-router-dom";

export function TeamPaymentModal({
  users,
  team,
}: {
  users: UserNode[];
  team: Team;
}) {
  const navigate = useNavigate();
  const price = useCompetitionPrice(
    team?.relationships.field_verseny?.data?.id
  );

  return (
    <HookForm
      defaultValues={{ fizetes_valaszt: "bankkartya" }}
      onSubmit={async (values) => {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) =>
          formData.set(key, value)
        );
        formData.set("field_country", values?.field_country?.value);
        formData.set(
          "field_kozterulet_jellege",
          values?.field_kozterulet_jellege?.value
        );
        if (!values?.field_adoszam) {
          formData.delete("field_adoszam");
        }
        try {
          const response = await sendBackendRequest(
            "/moonshot/team-payment-controller/" +
              team.attributes.drupal_internal__nid,
            { method: "POST", body: formData, redirect: "manual" }
          );
          if (response.headers.get("lokacio")) {
            location.href = response.headers.get("lokacio");
          } else {
            throw new Error();
          }
        } catch (e) {
          showErrorToast(
            <>
              <T>Hiba</T>
            </>,
            <>
              <T>generalErr</T>
            </>
          );
        }
      }}
    >
      <StyledCard>
        <CardTitle>
          <T>Csapatos fizetés</T>
        </CardTitle>
        <Row>
          <div>
            <Text>
              <T>teamPay2</T>{" "}
            </Text>
            <Text>
              <T>teamPay3</T>
            </Text>
          </div>
          <Col>
            {users.map((elem, index) => (
              <b key={index}>{elem.attributes.field_name}</b>
            ))}
          </Col>
        </Row>
        <CardTitle>
          <T>Számlázási adatok</T>
        </CardTitle>
        <ContactField isBillingData hasTaxNumber />
        <CardTitle>
          <T>pay</T>
        </CardTitle>
        <Row>
          <SimplePayButton />
          <WideCol>
            <Row>
              <BottomLabel>
                <T>Csapattagok száma</T>:
              </BottomLabel>
              <BottomValue>
                {users.length} <T>fő</T>
              </BottomValue>
            </Row>
            <Row>
              <BottomLabel>
                <T>Fizetendő</T>:
              </BottomLabel>
              <BottomValue>
                {Number(price * users.length).toLocaleString("hu")} Ft
              </BottomValue>
            </Row>
          </WideCol>
        </Row>
        <SubmitRow>
          <PaymentProcessorsImg src={paymentProcessorsSrc} />
          <SubmitButton>
            <T>pay</T>
          </SubmitButton>
        </SubmitRow>
      </StyledCard>
    </HookForm>
  );
}

const StyledCard = styled(Card)`
  max-height: 90vh;
  overflow-y: auto;
`;

const BottomLabel = styled.span`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 100;
`;

const BottomValue = styled.span`
  font-weight: bold;
  font-size: 18px;
  font-family: Montserrat;
`;

const PaymentProcessorsImg = styled.img`
  width: 250px;
  height: 30px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 266px;
  max-height: 160px;
  overflow-y: auto;
  text-align: end;
  align-items: flex-end;
  padding-right: 1rem;
  > * + * {
    margin-top: ${spacing._4};
  }
`;

const WideCol = styled.div`
  flex: 1;
  margin-left: ${spacing._16};
  max-width: 430px;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-top: ${spacing._2};
  padding-bottom: ${spacing._2};
`;

const Text = styled.p`
  text-align: start;
  max-width: 300px;
  font-weight: normal;
  font-family: Montserrat;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitRow = styled(Row)`
  margin-top: ${spacing._6};
  align-items: center;
`;
