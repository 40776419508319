import { Container } from "../container";
import { getImageUrl } from "../../utils/get-image-url";
import profileBg from "../../assets/profile_bg.png";
import styled from "styled-components";
import { down, spacing24, spacing3, spacing5 } from "@litbase/alexandria";
import reszveteli from "../../assets/reszveteli.pdf";
import stravaPolicy from "../../assets/STRAVA Felhasználási tájékoztató.pdf";
import { useEffect } from "react";

export function ArszPage() {
  useEffect(() => {
    location.replace(reszveteli);
  }, []);

  return null;

  return (
    <Body>
      <Container>
        <Title>Általános Részvételi Szabályzat</Title>

        <Content>Ide kerül a szabályzat szövege :)</Content>
      </Container>
    </Body>
  );
}

export function StravaPolicyPage() {
  useEffect(() => {
    location.replace(stravaPolicy);
  }, []);
  return null;
}

const bg = getImageUrl({ src: profileBg, width: 1920, height: 1080 });

const Body = styled.div`
  color: white;
  align-items: center;
  min-height: 100vh;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  overflow: auto;
  z-index: 1;

  ${Container} {
    z-index: 2;
  }

  &:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: url(${bg});
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  ${down("md")} {
    padding-bottom: 0;
    padding-left: ${spacing3};
    padding-right: ${spacing3};
  }
`;

const Title = styled.div`
  margin-top: ${spacing24};
  text-align: center;
  font-size: 48px;

  ${down("md")} {
    font-size: 36px;
  }

  ${down("sm")} {
    font-size: 24px;
  }
`;
const Content = styled.div`
  color: white;
  padding-top: ${spacing5};
`;
