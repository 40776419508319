export enum EntityType {
  competition = "jsonapi/node/versenyek",
  training = "jsonapi/node/teljesitesek",
  user = "jsonapi/user/user",
  competitionSignup = "jsonapi/node/versenyjelentkezesek",
  team = "jsonapi/node/csapatok",
  purchase = "jsonapi/node/szamlazasi_adatok",
  milestone = "jsonapi/node/merfoldkovek",
  profilePicture = "jsonapi/node/profilkep",
  activityType = "jsonapi/node/mozgasformak",
  roles = "jsonapi/user_role/user_role",
  faq = "jsonapi/node/gyik",
  popup = "jsonapi/node/popup",
}
