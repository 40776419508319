import { EventTab } from "../pages/profile/my-events/event-tab";
import eventLogo1 from "../../assets/event-logo-1.png";
import { EventTabContent } from "../pages/profile/my-events/event-tab-content";
import {
  down,
  RouteTabs,
  spacing,
  spacing1,
  spacing12,
  spacing2,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
  T,
  TabContent,
  TabContents,
  TabList,
} from "@litbase/alexandria";
import { ReactNode, useMemo, useState } from "react";
import { Competition, getEndsAt, getStartsAt } from "../../types/competition";
import { Card } from "../pages/profile/common-styles";
import {
  BasicStatsCard,
  StatLabel,
  StatValue,
} from "../pages/profile/basic-stats-card";
import { Button } from "../../styles/common-styles";
import styled from "styled-components";
import { Arrow } from "./arrow";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import checkmarkSvg from "../pages/blocks/assets/checkmark.svg";
import { useUser } from "../../hooks/use-user";
import { Interweave } from "interweave";
import { usePositionInMyTeam } from "../pages/profile/dashboard";
import { verticalDown } from "../../styles/theme-types";
import { useMyTeamsPlacement } from "../pages/profile/my-team/my-team-card";
import { getImageUrl } from "../../utils/get-image-url";
import { formatKilometer } from "../../utils/format-kilometer";
import {
  UserRow,
  useTeammates,
} from "../pages/profile/my-team/my-teammates-card";
import { useResources } from "../../hooks/use-resources";
import { EntityType } from "../../types/entity";
import { sum } from "lodash-es";
import { CompetitionSignup } from "../../types/competition-signup";
import { Team } from "../../types/team";

export function EventRenderer({
  competitions,
  routePrefix,
  children,
  headerComponent,
  small = false,
  className = "",
}: {
  competitions: Competition[];
  routePrefix: string;
  children: (elem: Competition) => ReactNode;
  headerComponent?: (elem: Competition) => ReactNode;
  small?: boolean;
  className?: string;
}) {
  const smallScreen = useBreakpoint(down("lg"));
  const tinyScreen = useBreakpoint(down("md"));
  const maxItems = getMaxItems({ small, smallScreen, tinyScreen });
  const [renderFrom, setRenderFrom] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <EventRendererContainer className={className}>
      <RouteTabs
        onChange={setActiveIndex}
        routes={competitions
          .slice(renderFrom, renderFrom + maxItems)
          .map((elem) => routePrefix + elem.attributes.drupal_internal__nid)}
      >
        <EventRendererRow>
          <StyledTabList>
            {competitions
              .slice(renderFrom, renderFrom + maxItems)
              .map((elem) => (
                <EventTab key={elem.id}>
                  <TabLogo
                    src={getImageUrl({
                      src: elem.attributes.image || eventLogo1,
                      width: 150,
                      height: 150,
                      isAdmin: !!elem.attributes.image,
                    })}
                  />
                  {headerComponent ? (
                    headerComponent(elem)
                  ) : (
                    <>
                      <EventTitle>
                        <Interweave content={elem.attributes.title} />
                      </EventTitle>
                      <EventDates>
                        <span>
                          {getStartsAt(elem).toLocaleDateString("hu")}
                        </span>
                        <span>{getEndsAt(elem).toLocaleDateString("hu")}</span>
                      </EventDates>
                    </>
                  )}
                </EventTab>
              ))}
          </StyledTabList>
          <EventRendererLeftArrow
            $isActive={renderFrom > 0}
            onClick={() => setRenderFrom(Math.max(0, renderFrom - 1))}
          />
          <EventRendererRightArrow
            $isActive={renderFrom < competitions.length - maxItems}
            onClick={() =>
              setRenderFrom(
                Math.min(competitions.length - maxItems, renderFrom + 1)
              )
            }
          />
        </EventRendererRow>
        <EventTabContent
          numberOfEvents={
            competitions.slice(renderFrom, renderFrom + maxItems).length
          }
          activeIndex={activeIndex}
        >
          <TabContents>
            {competitions
              .slice(renderFrom, renderFrom + maxItems)
              .map((elem) => (
                <TabContent key={elem.id}>{children(elem)}</TabContent>
              ))}
          </TabContents>
        </EventTabContent>
      </RouteTabs>
    </EventRendererContainer>
  );
}

function getMaxItems({
  small,
  smallScreen,
  tinyScreen,
}: {
  smallScreen: boolean | null;
  tinyScreen: boolean | null;
  small: boolean;
}) {
  if (tinyScreen) return 1;
  if (smallScreen) return 2;
  if (small) return 3;
  return 4;
}

function useCumulativeEventKilometers(event: Competition) {
  const [events] = useResources<Competition>(EntityType.competition, {});
  return useMemo(() => {
    return sum(
      events
        .filter((elem) => getStartsAt(elem) <= getStartsAt(event))
        .map((elem) => elem.attributes.field_osszes_megtett_tavolsag_ar)
    );
  }, [events, event]);
}

export function EventInfo({ event }: { event: Competition }) {
  const user = useUser();
  const [usersTeams] = useResources<Team>(EntityType.team, {
    filters: {
      "[field_csapattagok.drupal_internal__uid]": user.drupal_internal__uid,
      "[field_verseny.drupal_internal__nid]":
        event.attributes.drupal_internal__nid,
    },
  });
  const usersTeam = usersTeams?.[0];
  const position = usePositionInMyTeam(usersTeam);

  const teamPlacement = useMyTeamsPlacement(event, usersTeam);
  const allKilometer = useCumulativeEventKilometers(event);
  const [teammates] = useTeammates(usersTeam);
  const [eventSignups] = useResources<CompetitionSignup>(
    EntityType.competitionSignup,
    {
      filter: {
        "[field_egyeni_versenyzo.drupal_internal__uid]":
          user.drupal_internal__uid,
      },
    }
  );
  const currentRaceKm = useMemo(() => {
    return sum(
      eventSignups
        .filter(
          (elem) => elem.relationships.field_verseny?.data?.id === event.id
        )
        .map((elem) => elem.attributes.field_osszes_megtett_tavolsag_ar)
    );
  }, [user, eventSignups, event]);

  return (
    <EventGrid>
      <WideSectionTitle>
        <T>Részletek</T>
      </WideSectionTitle>
      <EventDescription>
        <Interweave content={event.attributes.field_leiras?.value} />
      </EventDescription>
      <Content>
        <Row>
          <Label>
            <T>Összes megtett kilométer</T>:
          </Label>
          <Value>{formatKilometer(allKilometer)} km</Value>
        </Row>
        <Row>
          <Label>
            <T>Versenyen megtett összes km</T>:
          </Label>
          <Value>
            {formatKilometer(event.attributes.field_osszes_megtett_tavolsag_ar)}{" "}
            km
          </Value>
        </Row>
        <Row>
          <Label>
            <T>Versenynen általad megtett km</T>:{" "}
          </Label>
          <Value>{formatKilometer(currentRaceKm)} km</Value>
        </Row>
        <Row>
          <Label>
            <T>Versenynen csapatban megtett km</T>:
          </Label>
          <Value>
            {formatKilometer(
              usersTeam?.attributes.field_osszes_megtett_tavolsag_ar
            )}{" "}
            km
          </Value>
        </Row>
        {usersTeam && (
          <>
            <TeamInfoTitle>
              <T>Csapat információk</T>
            </TeamInfoTitle>

            <TeamInfoContent>
              <Row>
                <Label>
                  <T>Csapatod:</T>
                </Label>
                <Value>{usersTeam?.attributes?.title}</Value>
              </Row>
              <Row>
                <Label>
                  <T>Csapaton belüli helyezésed:</T>
                </Label>
                <Value>{position}.</Value>
              </Row>
              <Row>
                <Label>
                  <T>Csapatod helyezése a kategóriában:</T>
                </Label>
                <Value>{teamPlacement}.</Value>
              </Row>
            </TeamInfoContent>
            <TeammatesTitle>
              <T>Csapattagok</T>
            </TeammatesTitle>
            <TeammatesContainer>
              {teammates.map((user) => (
                <UserRow user={user} />
              ))}
            </TeammatesContainer>
          </>
        )}
      </Content>
    </EventGrid>
  );
}

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span``;

const Value = styled.span`
  margin-left: auto;
  white-space: nowrap;
  font-weight: bold;
  padding-left: ${spacing._6};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
`;

const TeamInfoContent = styled(Content)`
  ${down("md")} {
    order: 0;
  }
`;

const TeammatesContainer = styled(Content)`
  max-height: 230px;
  overflow-y: auto;
  ${down("md")} {
    order: 3;
  }
`;

const PaginationArrow = styled(Arrow)<{ $isActive: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ $isActive }) => ($isActive ? "white" : "gray")};
  margin-left: 0 !important;
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

export const EventRendererLeftArrow = styled(PaginationArrow)`
  left: 0;
  transform: translate3d(-100%, -50%, 0);
`;

export const EventRendererRightArrow = styled(PaginationArrow)`
  right: 0;
  transform: translate3d(100%, -50%, 0) rotate(180deg);
  transform-origin: center;
`;

export const EventStats = styled(BasicStatsCard)`
  border: none;
  grid-row-gap: ${spacing3};
  grid-auto-rows: min-content;
  padding-top: 0;
  padding-right: 0;
  ${StatValue}, ${StatLabel} {
    font-size: 14px;
  }

  ${down("md")} {
    padding-left: 0;
  }
`;

export const ShareButton = styled(Button)`
  margin: 0;
  justify-self: end;
  font-size: 14px;
  padding: ${spacing1} ${spacing6};
  align-items: center;
  height: fit-content;
  &:hover {
    border: 2px solid ${({ theme }) => theme.blue};
    background: linear-gradient(180deg, #15018e 0%, #6534ff 100%),
      linear-gradient(180deg, #5a0085 0%, #dc93ff 100%);
  }
`;

export const EventSectionTitle = styled.h2`
  text-transform: none;
  margin-bottom: ${spacing12};
  ${verticalDown("lg")} {
    margin-bottom: ${spacing2};
  }
`;

export const EventGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${spacing6};
  ${down("md")} {
    display: flex;
    flex-direction: column;
  }
  ${EventSectionTitle} {
    margin-bottom: 15px;
  }
`;

export const EventDescription = styled.div`
  text-align: start;
  font-size: 14px;
  p {
    text-align: initial;
  }
  ul {
    margin-top: ${spacing3};
    list-style-image: url("${checkmarkSvg}");
    margin-left: ${spacing2};
  }
  li {
    padding-left: ${spacing3};
  }
  li + li {
    margin-top: ${spacing2};
  }
`;

const WideSectionTitle = styled(EventSectionTitle)`
  grid-column: span 2;
`;

const TeamInfoTitle = styled(EventSectionTitle)`
  ${down("md")} {
    order: 0;
  }
`;

const TeammatesTitle = styled(EventSectionTitle)`
  ${down("md")} {
    order: 3;
  }
`;

export const EventRendererContainer = styled.div`
  ${down("md")} {
    padding: 0 ${spacing8};
  }
`;

const EventDates = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  font-weight: 100;
  margin-top: ${spacing4};
`;

const TabLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: ${spacing4};
  ${verticalDown("lg")} {
    width: 70px;
    height: 70px;
  }
`;

const StyledTabList = styled(TabList)`
  display: flex;
  width: 100%;
`;

export const EventTitle = styled.span`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  ${verticalDown("lg")} {
    font-size: 16px;
  }
`;

export const EventRendererRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  > ${Card} {
    flex: 1;
  }
  > * + * {
    margin-left: ${spacing8};
  }
  > div {
    display: flex;
    flex: 1;
    border-bottom: none;
    margin-bottom: 0;
  }
`;
