import { setLanguageSettings } from "@litbase/alexandria";

export function loadTranslations() {
  setLanguageSettings({
    language: getSiteLanguage(),
    translationLoader: (lang) =>
      Object.fromEntries(
        Object.entries(translations).map(([key, value]) => [
          key,
          getTranslation(lang, key, value),
        ])
      ),
  });
}

function getSiteLanguage() {
  if (location.host.includes("en.moonshot")) {
    return "en";
  }
  if (location.search.includes("lang=en")) {
    return "en";
  }
  return "hu";
}

function getTranslation(
  lang: string,
  key: string,
  value: string | Record<string, unknown>
) {
  if (lang === "hu") {
    if (value.hu) return value.hu;
    return key;
  }
  return value.en || value;
}

const translations = {
  English: "Magyar",
  Versenyleírás: "Competition description menu",
  "MOONSHOT és STRAVA csatlakoztatása": "MOONSHOT and STRAVA connection",
  comp1: {
    hu: "A MOONSHOT versenyek alatt megtett távokat a STRAVA alkalmazás méri és tartja számon. Ehhez le kell tölteni a STRAVA APP-ot a telefonodra és létrehozni egy profilt ebben az alkalmazásban.",
    en:
      "The completed distances are monitored and saved by the STRAVA application. \n" +
      "You need to download STRAVA app to your phone and create a profile in this app.",
  },
  comp2: {
    hu:
      " Ezután a MOONSHOT profilodhoz hozzá tudod csatlakoztatni a\n" +
      "                STRAVA fiókodat.",
    en:
      " In the next step, you can connect your STRAVA profile to your MOONSHOT \n" +
      "profile.",
  },
  comp3: {
    en:
      "This only takes one click to the „STRAVA app connection” in STRAVA \n" +
      "menu in your MOONSHOT profile.",
    hu:
      "Ezt egy kattintással meg tudod tenni, ha a MOONSHOT profilban\n" +
      "              található",
  },
  "STRAVA menüpontra lépve a “STRAVA APP összekapcsolása”":
    "Click the 'Connect to STRAVA' button in the STRAVA menu item.",
  "gombra kattintasz": "",
  comp4: {
    hu:
      "Ezt követően, amikor távot szeretnél teljesíteni, csak el kell\n" +
      "              indítanod a STRAVA alkalmazást, amit a sportolásod végeztével az\n" +
      "              eredmény elmentése után szinkronizálunk a MOONSHOT oldalon a\n" +
      "              “Teljesítmény” menüpontod alá.",
    en:
      "After this, anytime you are doing sports, you just need to launch STRAVA app, \n" +
      "and after your work out, when you saved the data, it will be synchronized and \n" +
      "uploaded to „Achievement” menu in the MOONSHOT profile.",
  },
  comp5: {
    en:
      "If you find any error when registering to STRAVA, you can find a guide and help \n" +
      "here:",
    hu:
      "Ha valami probléma merülne fel a Strava jelentkezéssel, itt\n" +
      "          találhattok leírást és segítséget:",
  },
  "Hogyan tudok részt venni a versenyen?":
    "How can I participate in the contest?",
  comp6: {
    hu:
      "Első lépés a regisztráció! Ezt megteheted a Jelentkezz gomb\n" +
      "              megnyomását követően, vagy ha a Versenyek menüpontban már\n" +
      "              kiválasztottad a Neked tetsző kihívást, időszakot, melyen részt\n" +
      "              vennél.",
    en:
      "The first step is the registration! You can do it by clicking the Join the race \n" +
      "button, or choosing a challenge/season under Races menu.",
  },
  "A versenyre 2 módon tudsz jelentkezni:": "You can join a race two ways:",
  comp_7: {
    hu: "1. A főoldalról kiválasztod az éppen aktuális versenyt",
    en: "1. Choose the currently running race from the main page",
  },
  comp7: {
    hu:
      "2. Regisztráció/belépés után a profilodból a “Versenyek” menüben\n" +
      "                kiválaszthatod, melyik időszakra szeretnél jelentkezni",
    en:
      "2. After registration/log in, you can choose a challenge/season from Races \n" +
      "menu.",
  },
  comp8: {
    hu:
      "A jelentkezés során meg kell adnod pár adatot a beazonosításhoz.\n" +
      "              Ha ez sikerült, úgy érkezni fog egy jóváhagyó e-mail, melyre\n" +
      "              kattintva a regisztráció véglegessé válik, és a “Belépés” gomb\n" +
      "              alatt be tudsz jelentkezni a saját fiókodba.",
    en:
      "At registration, you need to add some personal data for the identification. If you \n" +
      "submitted them, you’ll receive a confirmation e-mail, and by clicking the link the \n" +
      "registration will be completed, and you can log in to your own profile at „Log in”.",
  },
  comp9: {
    hu:
      "*A nevezéseddel elfogadottnak tekinted, hogy ez egy önellátó\n" +
      "                    futás/ túra, melyen neked kell gondoskodnod magadról.",
    en:
      "*with your entry, you accept that this is an individual running/tour, where you \n" +
      "need to take care of yourself.",
  },
  comp10: {
    hu:
      "A szervezők nem felelnek az esetlegesen bekövetkező\n" +
      "                  sérülésekért, balesetekért, károkért, ahogy azért sem, hogy a\n" +
      "                  teljesítéshez szükséges alkalmazást megfelelően használd.",
    en:
      "The organizer cannot be held accountable for any \n" +
      "injuries, accidents, damages during the race, or the proper usage of the \n" +
      "application. ",
  },
  comp11: {
    hu:
      "Jelentkezés után még egyéni indulóként vagy számon tartva, tehát a\n" +
      "              versenyt egyénileg is tudod teljesíteni, de bármikor van\n" +
      "              lehetőséged egy csapat tagjává válni.",
    en:
      "After joining the race, you are registered as an individual participant, so you can\n" +
      "achieve the race alone, but you can also join a team anytime.",
  },
  "Csapatos jelentkezés tudnivalók!": "Team registration information",
  comp12: {
    hu:
      "Amennyiben csapatoddal jelentkeznél, a profilodban a\n" +
      "                  “Csapatom” menüben tudod összekapcsolni az egyéni versenyzőket\n" +
      "                  csapattá.",
    en:
      "In case you would like to register your team, you can link the individual \n" +
      "participants together in a team in „My team” menu.",
  },
  "Két lehetőséged van:": "You have two options:",
  "Csapat létrehozása:": "Create a team:",
  comp13: {
    hu:
      "Szükség van egy\n" +
      "                csapatkapitányra! Hozd létre saját csapatod, annak nevének\n" +
      "                megadásával, és a csapat nevezési díj kifizetése után a profilod\n" +
      "                Csapatom menüpontja alatt megjelenik egy csapatkód, melyet\n" +
      "                csapattársaiddal megosztva, csatlakozni tudnak a csapatodhoz.",
    en:
      "You’ll need a team leader! Create your own team by \n" +
      "naming it, and after paying the entry fee of the team you’ll see a team-\n" +
      "code under „My team” menu; you can share this code with your team \n" +
      "members, so they can join.",
  },
  "Csatlakozás csapathoz:": "Join a team:",
  comp14: {
    hu:
      "a csapatkapitánytól\n" +
      "                kapott kódot be kell írnod a mezőbe és így már részese is vagy a\n" +
      "                kívánt csapatnak!",
    en:
      " you receive a team-code from the team leader, submit this \n" +
      "code to the right field and you are already a team member!",
  },
  comp15: {
    hu:
      "A profilodból elérhető a STRAVA csatlakoztatásához szükséges\n" +
      "                  menü.",
    en: "You can find the STRAVA connecting menu in your profile.",
  },
  comp16: {
    hu: "Ezt csak egyszer kell megtenned a verseny során.",
    en: "This only needs to be done once during the race.",
  },
  comp17: {
    en: "MOONSHOT and STRAVA connection",
    hu: "MOONSHOT és STRAVA csatlakoztatása",
  },
  comp18: {
    hu:
      "A “STRAVA” menü alatt kattints a “STRAVA APP összekapcsolása”\n" +
      "                gombra",
    en: "Click to „connect with STRAVA” under STRAVA menu.",
  },
  comp19: {
    hu:
      "Ez a gomb át fog navigálni a STRAVA oldalára, ahol be kell\n" +
      "                jelentkezned a STRAVA profilodba.",
    en:
      "You’ll be redirected to STRAVA’s page, where you need to log in to your STRAVA\n" +
      "profile.",
  },
  comp20: {
    hu:
      "Ha nincs még saját fiókod, akkor regisztrálnod kell a\n" +
      "                  STRAVA-ra.",
    en: "If you don’t have one yet, you need to create one.",
  },
  comp21: {
    hu:
      "Miután bejelentkeztél, automatikusan átirányít a STRAVA és\n" +
      "                MOONSHOT összekötés engedélyezésére. Hagyd jóvá a\n" +
      "                https://www.moonshotrace.hu számára az automatikus\n" +
      "                szinkronizálást, majd nyomj az “Authorize”gombra.",
    en:
      "After logging in, the STRAVA-MOONSHOT connection authorization will \n" +
      "automatically appear. Validate the automatic synchronization to \n" +
      "https://www.moonshotrace.hu, then click to „Authorize” button.",
  },
  comp22: {
    hu:
      " Mostantól az elfogadott edzések kilométere automatikusan\n" +
      "              hozzáadódik a versenyhez. Továbbiakban nincs más dolgod mint\n" +
      "              használni a STRAVA alkalmazást és gyűjteni a kilométereket.",
    en:
      "From now on, the accepted work out’s distance will be automatically submitted \n" +
      "to the race. You only need to continue to use STRAVA app and collect the \n" +
      "kilometers.",
  },
  comp23: {
    en:
      "The race lasts for 4 weeks. From the beginning to the end, you can achieve \n" +
      "distances multiple times even every day, which will all contribute to the final \n" +
      "result. You just need to make sure that your phone or smartwatch is with you, \n" +
      "and the STRAVA app is running and counting the kilometers",
    hu:
      "A verseny 4 hétig tart. A kezdetétől a végéig akár\n" +
      "              minden nap, egy nap többször is teljesíthetsz távokat, melyek\n" +
      "              hozzáadódnak mindig a végső eredményhez. Neked arra kell\n" +
      "              figyelned, hogy a",
  },
  "telefonod vagy okosórád mindig Veled legyen": " ",
  "Hogy zajlik a verseny?": "How the race goes?",
  comp24: {
    hu: ", hogya STRAVA mérni tudja az elvégzett távot.",
    en: " ",
  },
  comp25: {
    hu:
      "A távokat csapatonként és egyénenként is követjük, ezek az\n" +
      "                adatok összeadódnak és az összesített eredménynél látható a\n" +
      "                gyűjtött kilométerek száma, és a kialakult helyezés is.",
    en:
      "The distances will be monitored individually and in teams too, and these data will\n" +
      "be summed up. The collected kilometers and the ranking will be seen at the \n" +
      "unified achievement part.",
  },
  comp26: {
    hu:
      "Minden versenyző által teljesített táv össze is adódik, hiszen\n" +
      "                együttes célunk “feljutni a Holdra”, ennek állását a “Közös\n" +
      "                eredménynél” láthatod.",
    en:
      "And at last, every participant’s every achievement will be submitted, since our \n" +
      "final goal is to reach the Moon in a joint effort. This will be seen at „Collective \n" +
      "achievement” menu.",
  },
  "Mindig ellenőrizd le, hogy elindítottad-e a STRAVA alkalmazást":
    "You always need to start STRAVA app at the beginning",
  comp27: {
    hu:
      "és a végén, hogy leállítottad-e. Utólagosan bediktált adatokat\n" +
      "              elfogadni nem tudunk.",
    en:
      "and stop it at the end of \n" +
      "your work out. We cannot accept submitted data subsequently.",
  },
  comp28: {
    hu:
      "A versenyt az nyeri, aki a végén a legtöbb kilométert gyűjtötte\n" +
      "                össze akár csapatban, akár egyéni indulóként.",
    en:
      "The winner will be the one who collected the most kilometers even individually or\n" +
      "in team.",
  },
  "Jó versenyzést kíván a Moonshot - the Virtual Race csapata!":
    "We wish you a pleasant competition – MOONSHOT the Virtual Race Team",
  Versenyjelentkezés: "Race application",
  Nevezés: "Entry",
  "Esemény neve": "Name of the event",
  "Esemény időtartama": "Time of event",
  "Csapatod neve": "Team name",
  "A nevezés díja": "Entry fee",
  Ft: "HUF",
  teamPay1: {
    hu:
      "<b>Csapatos nevezés aktív:</b> A nevezési díjat a csapatkapitányod\n" +
      "              fogja kiegyenlíteni",
    en: "<b>Team payment active:</b> The entry fee will be settled by the team leader",
  },
  Vissza: "Back",
  Jelentkezés: "Apply",
  "Számlázási adatok": "Invoicing information",
  "A számlázási adatok eltérnek a lakcímtől":
    "The invoicing data is different than the postal address",
  Fizetés: "Payment",
  "Válassz fizetési módot": "Please choose payment method",
  "Fizetési tájékoztató": "Payment information",
  err1: {
    hu: "Nem jó csapat kód, vagy a csapat nem erre a versenyre adta le a jelentkezést",
    en: "Incorrect team code, or the team did not sign up to this race",
  },
  err2: {
    hu: "Hiba történt a fizetési oldalra átirányítás során! Kérlek próbáld meg később.",
    en: "There was an error while redirecting to the payment page. Please try again later",
  },
  szepCard: {
    hu:
      "SZÉP kártyás fizetés esetén a számlát minden esetben a SZÉP kártya\n" +
      "        tulajdonos nevére és címére kell kiállítani!",
    en: "When using a SZÉP card, the billing name and address should match the SZÉP card's",
  },
  taxNumber: {
    hu: "Adószám (csak cégeknél)",
    en: "Tax number (companies only)",
  },
  Adószám: "Tax number",
  Ország: "Country",
  Magyarország: "Hungary",
  Irányítószám: "ZIP code",
  Település: "City",
  "Közterület neve": "Street name",
  "Közterület jellege": "Street type",
  Házszám: "House number",
  Telefonszám: "Phone number",
  pay: {
    hu: "Fizetés",
    en: "Pay",
  },
  "Sikeresen csatlakoztál a csapathoz": "Team joined successfully",
  "Úgy tűnik még nincs csapatod":
    "Looks like you're not a member of a team yet",
  Hiba: "Error",
  teamError1: {
    hu:
      "(Ha a közelmúltban jelentkeztél a versenyre néhány\n" +
      "                percig tarthat mielőtt csatlakozni tudsz a csapatba. Ez esetben\n" +
      "                kérlük próbáld meg később)",
    en: "If you signed up to a competition recently, it can take a few minutes before you can join a team. In this case, please try again later",
  },
  teamCode: {
    hu:
      "A csapatkapitánytól kapott kóddal könnyedén tudsz csatlakozni egy csapathoz. Azonban\n" +
      "          ehhez rendelkezned kell a versenyre érvényes egyéni nevezéssel.",
    en: "You can join a team with the code received from the team leader. To do this, you will need to have validly signed up to a race.",
  },
  "Csatlakozás a csapathoz": "Join team",
  ownTeam: {
    hu:
      "Ha saját csapatot szeretnél, azt is könnyedén megteheted. Azonban\n" +
      "          ehhez rendelkezned kell a versenyre érvényes egyéni nevezéssel.",
    en: "If you want to create a new team, you can do it here. But for that, you need to have a valid individual entry to the race.",
  },
  "A csapat létrehozásának díja": "Team entry fee: ",
  "Add meg a csapatod nevét és lépj tovább a fizetéshez":
    "Submit the name of the team and continue to the payment",
  "Csapat létrehozása": "Create team",
  "Nincs olyan versenyre nevezésed amelyikre a csapat regisztrált":
    "You don't have an application to the race the team registered to",
  teamErr2: {
    hu: "A csapathoz már nem lehet csatlakozni mert a verseny regisztrációs időszaka lezárult",
    en: "You can't join the team, because the registration period has ended.",
  },
  "Csapat információk": "Team information",
  Csapatnév: "Team name",
  Csapatkapitány: "Team leader",
  Csapatkód: "Team code",
  "Összes teljesített kilométer": "Total completed kilometers",
  "Csapat rangja a kategóriájában": "Team's rank in its category",
  "Legszorgalmasabb csapattag": "Most active team member",
  payOffBefore: {
    hu: " ",
    en: "pay off ",
  },
  payOffAfter: {
    hu: "csapattag kifizetése",
    en: " team members",
  },
  "Csapattag eltávolítása": "Remove team member",
  generalErr: {
    hu: "Valami elromlott. Próbáld újra később.",
    en: "Something went wrong. Please try again later",
  },
  "Csapatos fizetés": "Team payment",
  teamPay2: {
    hu:
      "Az alábbi listában látod, mely csapattagjaid nevezési díját tudod\n" +
      "              kiegyenlíteni.",
    en: "You can see in the list below, which team member’s payment can you complete.",
  },
  teamPay3: {
    hu:
      "A fizetés befejeztét követően a felsorolt csapattagok számlája\n" +
      "              rendezésre kerül, és ők is részesei lehetnek a versenynek",
    en: "After completing the payment, the listed member’s balance will be settled, and they can be part of the competition too.",
  },
  "Csapattagok száma": "Number of team members",
  fő: "people",
  Fizetendő: "Total",
  pendingSignup: {
    hu: "Van 1 függő versenyjelentkezésed",
    en: "You have 1 pending team application",
  },
  pendingSignup2: {
    hu:
      "A nevezési díjat a csapatkapitányod fogja kiegyenlíteni, nevezésed\n" +
      "          pedig ezt követően kerül véglegesítésre!",
    en: "The entry fee will be paid by your team leader, and your entry will be finalized after that!",
  },
  Regisztráció: "Registration",
  "Email cím": "E-mail address",
  Név: "Name",
  "Születési idő": "Date of birth",
  Jelszó: "Password",
  "Elfogadom az": "I accept the ",
  "Adatkezelési Tájékoztatót": "Privacy policy",
  "Általános Részvételi Szabályzatot": "Terms of service",
  "és az": "and the",
  Tovább: "Continue",
  "Megerősítő email elküldve": "Confirmation e-mail sent",
  register1: {
    hu:
      "A regisztrációd majdnem készen áll, már csak hitelesítened kell az\n" +
      "          email címedet, az arra küldött üzenetben lévő linkre való\n" +
      "          kattintással.",
    en:
      "Your registration is almost ready, you only have to confirm your e-amil address" +
      " by clicking the link in the e-mail sent to your address.",
  },
  Bezárás: "Close",
  "A regisztrációd feldolgozás alatt áll":
    "Your registration is being processed",
  "Sikeres aktiváció": "Successful activation",
  successfulRegister: {
    hu:
      "Sikeresen aktiváltad regisztrációdat! <br />\n" +
      "            Most már szabadon használhatod az oldal szolgáltatásait.",
    en: "You successfully activated your registration! <br /> Now you can use the services of the website freely.",
  },
  "Hiba történt a regisztrációd során": "An error occurred while registering",
  Teljesítmény: "Achievements",
  "Fontos tudnivalók": "Important information",
  JELENTKEZZ: "Join the race",
  "Gyűjts kilométereket bárhol!": "Collect kilometers anywhere!",
  "Megtett kilométerek": "Completed kilometers",
  N: "D",
  Ó: "H",
  P: "M",
  MP: "S",
  "A következő eseményig": "Until next event",
  Belépés: "Log in",
  Versenyek: "Races",
  "Általános Részvételi Szabályzat": "General Rules of Participation",
  "Közös eredmény": "Collective achievement",
  Kijelentkezés: "Log out",
  Profilom: "My profile",
  "3 Nap": "3 days",
  "9,5 Év": "9.5 years",
  Részletek: "Details",
  Megosztás: "Share",
  "Hamarosan...": "Soon...",
  "Összes megtett kilométer": "Overall achievement",
  "Általad megtett kilométer": "Your achievement",
  "Csapatban megtett kilométer": "Team achievement",
  "Csapatbeli helyezésed": "Your rank in team",
  "Csapatod helyezése a kategóriában:": "Team rank in category:",
  "Verseny időtartama:": "Race duration:",
  "Nevezési díj:": "Entry fee:",
  Hamarosan: "Soon",
  "Jelentkezési időszak:": "Registration period:",
  "Már jelentkeztél erre a versenyre": "You've already entered this race",
  "Jelenleg nincs jelentkezési időszak!": "Registration period is not open!",
  Jelentkezem: "Enter race",
  "A Hold": "The Moon",
  Hőmérséklet: "Temperature",
  "Nappali hőmérséklet": "Daytime temperature",
  "Éjjeli hőmérséklet": "Nighttime temperature",
  Holdraszállás: "The Moon landing",
  Indulás: "Launch",
  Leszállás: "Landing",
  "Kennedy űrállomás": "Kennedy Space Station",
  "A Hold átlagos keringési sebessége":
    "The average orbital velocity of the Moon",
  orbitalVelocity: {
    hu: "1,022 km/s keringési sebességével megközelítőleg az átmérőjével megegyező távolságot tesz meg",
    en: "With its 1,022 km/s orbital speed, it almost takes the distance of its own diameter",
  },
  Holdnap: "Moonday",
  nap: "day",
  darkSide: {
    hu:
      "A „hold sötét oldala” kifejezés nem a „sötétségre” utal, mint a\n" +
      "              fény hiányára, hanem a „sötétre”, mint az ismeretlenre: amíg az\n" +
      "              emberek nem tudtak űrhajókat küldeni a Hold körül, ezt a területet\n" +
      "              soha nem látták.",
    en: "The “dark side of the Moon” phrase does not mean darkness as the lack of light; it means the unknown side. Until people couldn’t send a spaceship around the Moon, these areas has never been seen.",
  },
  moonDay: {
    hu:
      "Pontosan 27 nap, 7 óra, 43 perc és 12 másodperc alatt tesz meg\n" +
      "                egy teljes kört",
    en: "The Moon needs exactly 27 days 7 hours 43 minutes and 12 seconds to take a whole circle.",
  },
  "A világosság a Holdon": "Brightness lasts",
  "hétig tart": "weeks",
  darkness: {
    hu: "Amit nagyjából 2 hét sötétség követ",
    en: "on the Moon. <br/>It is followed by approximately 2 weeks of darkness",
  },
  Átmérő: "Diameter",
  Hold: "Moon",
  Föld: "Earth",
  Nap: "Sun",
  theDarkSide: {
    hu: "A Sötét oldal – sokkal inkább a Távoli oldal",
    en: "The dark side – more like the distant side",
  },
  bothSide: {
    hu:
      "A Hold mindkét oldala 2 hét világosság után 2 hét sötétségbe borul,\n" +
      "            amit sokszor a „Hold sötét oldalának” hívnak, ami inkább a nem\n" +
      "              láthatót, semmint a fény hiányát jelenti",
    en: "Both sides of the Moon have 2 weeks of brightness followed by 2 weeks of darkness, which often called the “dark side” of the Moon, but it means the unknown rather than the lack of light.",
  },
  FÖLDKELTE: "EARTHRISE",
  Kapcsolat: "Contact",
  "Látogass el hozzánk": "Visit us",
  "Kövess minket": "Follow us",
  "Adatkezelési Tájékoztató": "Privacy Policy",
  "STRAVA Felhasználási Tájékoztató": "Strava User Guide",
  "Irány a hold": "To the Moon",
  "Itt tartunk": "We are here",
  "Itt láthatod a távot, melyet eddig közösen sikerült megtennünk":
    "Here you can see the distance we covered Together",
  "Profil szerkesztése": "Profile settings",
  Versenyeim: "My Races",
  Csapatom: "My Team",
  Ranglista: "Ranking",
  GYIK: "FAQ",
  "Profil adatok": "Profile details",
  Felhasználónév: "Username",
  givenEmail: {
    hu:
      "A megadott email cím nem kerül nyilvánosságra, és csak\n" +
      "                    elfelejtett jelszóhoz való segítségnyújtásra, valamint a\n" +
      "                    kért értesítésekhez használjuk.",
    en: "We do not share the given email address with third parties, it is only used for notifications and assistance is requested",
  },
  "Jelszó változtatás": "Change password",
  "Jelenlegi jelszó": "Current password",
  "Új jelszó": "New password",
  "Jelszó megerősítése": "New password again",
  Mentés: "Save",
  "Profil törlése": "Delete Profile",
  "Számlázási név*": "Invoicing name*",
  "Jelentkezz versenyre mielőtt csapatot hoznál létre!":
    "You must enter a race before creating a team!",
  "Sikeres mentés": "Successful save",
  "Valami elromlott, próbáld újra később!":
    "Something is broken, please try again later!",
  Profilkép: "Profile picture",
  "Nem található kiírt verseny": "Couldn't find active race",
  "Aktuális versenyen megtett kilométerek": "Distance covered in current race",
  "Aktuális versenyből hátravan": "Remaining from current race",
  "Következő versenyig hátravan": "Until next race",
  "Egyéni teljesítmény": "Personal achievements",
  "Megtett km-ek:": "Distance covered:",
  "Sporttal töltött idő:": "Time spent with sport:",
  "Elvégzett edzések:": "Completed workouts:",
  "Csapatos teljesítmény": "Team achievements",
  "Még nem csatlakoztál csapathoz": "You haven't joined a team yet",
  "Legutóbbi edzéseim": "My previous workouts",
  Tevékenység: "Activity",
  "Arányosított táv": "Proportional distance",
  "Megtett táv": "Completed distance",
  Arányszám: "Ratio",
  Időpont: "Date",
  Időtartam: "Duration",
  "Még nem jelentkeztél versenyre!": "You did not enter any race yet!",
  "Az alábbi gombra kattintva megtekintheted versenyeinket":
    "Check out the races by clicking on the button below",
  "Kapcsold össze Strava fiókodat a Moonshot fiókoddal":
    "Connect your Strava profile with your MOONSHOT profile",
  "A Strava összekötése egyszerű, kövesd az alábbi lépéseket:":
    "Connecting STRAVA is easy, just follow these steps:",
  strava1: {
    hu: "Kattints a lent található “Connect with Strava” gombra",
    en: "Click to “Connect with Strava” button below",
  },
  strava2: {
    hu:
      "\n" +
      "              Ez a gomb át fog vinni a Strava oldalára, ahol be kell\n" +
      "              jelentkezned a Strava profilodba. Ha nincs, regisztrálnod kell a\n" +
      "              Strava-ra.\n" +
      "            ",
    en: "You’ll be redirected to STRAVA’s page, where you need to log in to your STRAVA profile. If you don’t have one yet, you need to create one.",
  },
  strava3: {
    hu:
      "Miután bejelentkeztél, automatikusan átirányít a Strava és MOONSHOT\n" +
      "            engedélyezésére. Hagyd jóvá a http://moonshot.hu nak az automatikus\n" +
      "            szinkronizálást, majd kattints az “Authorize” gombra.",
    en: "After logging in, the STRAVA-MOONSHOT connection authorization will automatically appear. Validate the automatic synchronization to https://www.moonshotrace.hu, then click to „Authorize” button.",
  },
  strava4: {
    hu: "Ez visszarepít a MOONSHOT profilodra.",
    en: "This will get you back to MOONSHOT profile.",
  },
  strava5: {
    hu:
      "\n" +
      "            Mostantól az elfogadott edzések km-e automatikusan hozzáadódik a\n" +
      "            versenyhez. Továbbiakban nincs más dolgod mint használni a Strava\n" +
      "            alkalmazást és gyűjteni a kilométereket, melyek automatikusan\n" +
      "            feltöltődnek a Moonshot oldalára.\n" +
      "          ",
    en: "From now on, the accepted work out’s distance will be automatically submitted to the race. You only need to continue to use STRAVA app and collect the kilometers, which will automatically be submitted to MOONSHOT website.",
  },
  stravaBottom: {
    hu:
      "Ha részletesebben szeretnél olvasni a tudnivalókról és\n" +
      "            szabályzatról, kattints",
    en: "If you would like to read more about the information and the general rules, click ",
  },
  ide: "here",

  "Összekötés Strava-val": "Connect with Strava",
  "Összekötve Strava-val": "Connected to Strava",
  "Hibaüzenet: Team code mező szükséges.": "Error: Team code field required.",
  Egyéni: "Individual",
  "Mikro (1-10 fő)": "Micro (1-10 prs)",
  "Kis (11-50 fő)": "Small (11-50 prs)",
  "Közepes (51-100 fő)": "Medium (51-100 prs)",
  "Nagy (101-200 fő)": "Large 101-200 prs)",
  "Óriás (201+ fő)": "Giant (200+ prs)",
  "Versenyző adatai": "Competitor details",
  "Személyes adatok *": "Personal information*",
  Nem: "Gender",
  Férfi: "Male",
  Nő: "Female",
  "Elérhetőség*": "Contact details*",
  "Egyéb adatok": "Other information",
  "Honnan hallottál a MOONSHOT versenyről?*":
    "Where have heard about Moonshot?",
  "Melyik cégtől/intézménytől jelentkezel?":
    "Which company/institution are you applying from?",
  Hírlevél: "Newsletter",
  "Google keresés": "Google search",
  "Kollégák, céges kommunikáció": "Colleagues, corporate communication",
  "AYCM oldal": "AYCM website",
  "Barátok, egyetemi csoport": "Friends, college groups",
  Egyéb: "Other",
  "Kérlek add meg adataidat a belépéshez:":
    "Please fill in your data to log in:",
  "Elfelejtettem a jelszavam": "I forgot my password",
  "Még nincs felhasználói fiókod?": "Don't have an account yet?",
  Regisztrálj: "Register",
  "Új jelszó igénylése": "Request new password",
  "Kérlek add meg a fiókodhoz tartozó e-mail címet az új jelszó igényléséhez:":
    "Please enter the email address associated with your account to request a new password:",
  "Igénylés elküldése": "Send request",
  év: "year",
  "Aktuális versenyen együtt megtett kilométerek":
    "Kilometres covered together in current race",
  "Csapaton belüli helyezésed:": "Rank in your team:",
  "Csapatod:": "Your team:",
  "Versenyen megtett összes km": "Total distance covered in race",
  "Versenynen általad megtett km": "Distance covered by you in race",
  "Versenynen csapatban megtett km": "Distance covered by your team in race",
  Csapattagok: "Team members",
  overallRanking: {
    hu: "Összesített ranglista",
    en: "Overall ranking",
  },
  "Aktuális verseny": "Current race",
  "Fizetés feldogozása folyamatban...": "Payment is being processed...",
  "Sikertelen fizetés": "Unsuccessful payment",
  "Automatikus átirányítás a főoldalra...": "Redirecting to home page...",
  "Vigyél oda most": "Take me there",
  "Sikeres fizetés": "Successful payment",
  "Tranzakció azonosító": "Transaction ID",
  Összeg: "Total",
  "Automatikus átirányítás a 'profilom' oldalra...":
    "Redirecting to 'my profile'...",
  "Korábbi csapat megtartása": "Keep previous team",
  csapatTartas1: {
    hu: "Korábbi csapatodat megtarthatod a további versenyek folyamán, így év végén egységesítve láthatod a csapatod által elért eredményt.",
    en: "You can keep your previous team for the rest of the tournament, so at the end of the year, you can see your team's results consolidated.",
  },
  "A csapat továbbvitelének díja": "Fee for keeping the team is",
  csapatTartas2: {
    hu:
      "Ezt az opciót választva csak csapatod neve és csapatkódja marad meg, a\n" +
      "        csapat korábbi tagjainak újra csatlakozniuk kell a csapatkód\n" +
      "            megadásával.",
    en: "If you choose this option, only your team name and team code will be kept, previous team members will have to rejoin the team by entering the team code.",
  },
  "Csapat megtartása": "Keep team",
  napok: {
    hu: " nap",
    en: " days",
  },
  hét: {
    hu: " hét",
    en: " weeks",
  },
  hónap: {
    hu: " hónap",
    en: " month",
  },
  óra: {
    hu: " óra",
    en: " hours",
  },
  év: "year",
  hónap: "month",
  privacyPolicyLink: {
    hu: "https://allyoucanmove.hu/content_files/adatkezelesi_tajekoztato.pdf",
    en: "https://allyoucanmove.hu/content_files/en_adatkezelesi_tajekoztato.pdf", // TODO: Point to real doc
  },
  Verseny: "Race",
};
