import { Card, CardTitle } from "../common-styles";
import { InputField } from "../../../form/input-field";
import { Button, SubmitButton } from "../../../../styles/common-styles";
import styled from "styled-components";
import {
  HookForm,
  showErrorToast,
  spacing16,
  spacing4,
  useModal,
  T,
} from "@litbase/alexandria";
import { PaymentInfoCard } from "./payment-info-card";
import {
  refreshLoggedInUserData,
  User,
  useUser,
} from "../../../../hooks/use-user";
import {
  hackilyGetFormTokenAndStuff,
  hackilySubmitForm,
  sendBackendRequest,
} from "../../../../services/backend";
import { object, string } from "yup";
import { useState } from "react";

export function NewTeamCard() {
  const user = useUser();
  const { showModal } = useModal();
  const [done, setDone] = useState(false);

  if (done) {
    return (
      <StyledCard>
        <CardTitle>
          <T>Sikeresen csatlakoztál a csapathoz</T>!
        </CardTitle>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <CardTitle>
        <T>Úgy tűnik még nincs csapatod</T>
      </CardTitle>
      <Spacing />
      <HookForm
        onSubmit={async (values, form) => {
          try {
            await connectToTeam(values.team, user);
            setDone(true);
          } catch (e) {
            showErrorToast(
              <>
                <T>Hiba</T>
              </>,
              <>
                {e.message} <T>teamError1</T>
              </>
            );
            form.setError("team", e.message);
          }
        }}
      >
        <InputField name="team" label={<T>teamCode</T>} />
        <StyledSubmitButton>
          <T>Csatlakozás a csapathoz</T>
        </StyledSubmitButton>
      </HookForm>
      <Spacing />
      <HookForm
        yupSchema={newTeamSchema}
        onSubmit={(values) => {
          showModal(<PaymentInfoCard teamName={values.teamName} />);
        }}
      >
        <Label>
          <T>ownTeam</T>
        </Label>
        <Label>
          <T>A csapat létrehozásának díja</T> 1.000 Ft
        </Label>
        <Label>
          <T>Add meg a csapatod nevét és lépj tovább a fizetéshez</T>
        </Label>
        <InputField name="teamName" />
        <StyledSubmitButton>
          <T>Csapat létrehozása</T>
        </StyledSubmitButton>
      </HookForm>
    </StyledCard>
  );
}

const newTeamSchema = object().shape({
  teamName: string().required("Adj meg csapatnevet!"),
});

async function connectToTeam(teamCode: string, user: User) {
  const url = "/moonshot/csatlakozas-csapathoz";

  const response = await hackilySubmitForm(url, {
    csapat_kod: teamCode,
  });

  if (
    response.includes(
      "Nincs olyan versenyre nevezésed amelyikre a csapat regisztrált!"
    )
  ) {
    throw new Error(
      <T>Nincs olyan versenyre nevezésed amelyikre a csapat regisztrált</T>
    );
  }

  if (
    response.includes(
      "A csapathoz már nem lehet csatlakozni mert a verseny regisztrációs időszaka lezárult!"
    )
  ) {
    throw new Error(<T>teamErr2</T>);
  }

  await refreshLoggedInUserData();
}

const StyledCard = styled(Card)`
  height: fit-content;
  grid-column: 1;
`;

const Label = styled.p`
  font-size: 14px;
  text-align: start;
  font-weight: 600;
`;

const Spacing = styled.div`
  height: ${spacing16};
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${spacing4};
  width: 100%;
  justify-content: center;
`;
