import { animated, config, useSpring } from "react-spring";
import { useMemo, useRef, useState } from "react";
import { isEqual } from "lodash-es";
import { useInView } from "react-intersection-observer";

export function ConnectingArrow({
  startX,
  startY,
  endX,
  endY,
  curvyness,
  inView,
  springProps,
}: {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  curvyness: number;
  inView: boolean;
  springProps?: Record<string, unknown>;
}) {
  const [x, y] = [endX, endY];
  const [coords, setCoords] = useState([]);
  const ref = useRef<SVGSVGElement | null>(null);
  const styles = useSpring({
    progress: inView ? 1 : 0,
    ...springProps,
  });

  const d = useMemo(() => {
    return [
      ["M", startX, startY].join(" "),
      [
        `C ${startX} ${startY}`,
        calculateControlPoint([startX, startY], [endX, endY], curvyness).join(
          " "
        ),
        `${x} ${y}`,
      ].join(" "),
    ].join(" ");
  }, [startX, startY, endX, endY, curvyness]);

  return (
    <>
      <path
        ref={(svgElem) => {
          if (!svgElem) return;
          const length = svgElem.getTotalLength();
          const newCoords = Array(100)
            .fill(0)
            .map((elem, index) =>
              svgElem.getPointAtLength((index / 100) * length)
            );
          if (
            newCoords.find(
              (elem, index) =>
                elem.x !== coords[index]?.x || elem.y !== coords[index]?.y
            )
          ) {
            setCoords(newCoords);
          }
          ref.current = svgElem;
        }}
        d={d}
        opacity="1"
        strokeWidth={5}
        stroke="none"
        fill={"none"}
        strokeDasharray={"15"}
      />
      <animated.path
        d={styles.progress.to((progress) => {
          return [
            `M ${startX} ${startY}`,
            ...coords
              .slice(0, coords.length * progress)
              .map((elem) => `L ${elem.x} ${elem.y}`),
          ].join(" ");
        })}
        opacity="1"
        strokeWidth={5}
        stroke="white"
        fill={"none"}
        markerEnd="url(#arrowhead)"
        strokeDasharray={"15"}
      />
    </>
  );
}

function calculateControlPoint(
  start: [number, number],
  end: [number, number],
  curvyness = 15
) {
  const length =
    Math.sqrt(
      Math.abs(end[0] - start[0]) ** 2 + Math.abs(end[1] - start[1]) ** 2
    ) * 0.3;
  const [vx, vy] = normalize(end[0] - start[0], end[1] - start[1]);
  const [nx, ny] = [vy, -1 * vx];
  const [zx, zy] = [(start[0] + end[0]) / 2, (end[1] + start[1]) / 2];
  return [nx * curvyness * length + zx, ny * curvyness * length + zy];
}

function normalize(x: number, y: number) {
  const length = Math.sqrt(x * x + y * y);
  return [x / length, y / length];
}
