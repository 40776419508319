import { Title } from "../common-styles";
import styled from "styled-components";
import { useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { useUser } from "../../../../hooks/use-user";
import { EventInfo, EventRenderer } from "../../../general/event-renderer";
import { Competition } from "../../../../types/competition";
import { jumpToElement } from "../../../../links";
import { Link } from "react-router-dom";
import { down, spacing2, spacing4, spacing6, T } from "@litbase/alexandria";
import { Button } from "../../../../styles/common-styles";

export function MyEvents() {
  const user = useUser();
  const [signups, loading, includes] = useResources(
    EntityType.competitionSignup,
    {
      filters: {
        include: "field_verseny,field_verseny.field_kep",
        "[field_egyeni_versenyzo.drupal_internal__uid]":
          user.drupal_internal__uid,
        sort: "field_verseny.field_regisztracios_idoszak.value",
        "[field_statusz]": "fizetett",
      },
    }
  );

  const images = includes.filter((elem) => elem.type === "file--file");
  const events = includes
    .filter((elem) => elem.type === "node--versenyek")
    .map((elem) => ({
      ...elem,
      attributes: {
        ...elem.attributes,
        image: images.find(
          (img) => elem.relationships.field_kep.data.id === img.id
        )?.attributes.uri.url,
      },
    }));

  return (
    <>
      <Title>
        <T>Versenyeim</T>
      </Title>
      {events?.length != undefined && events?.length > 0 && (
        <EventRenderer
          small
          competitions={(events || []) as Competition[]}
          routePrefix={"/profilom/versenyeim/"}
        >
          {(event) => <EventInfo event={event} />}
        </EventRenderer>
      )}
      {!loading && events?.length <= 0 && (
        <>
          <EmptyMessage>
            <T>Még nem jelentkeztél versenyre!</T>
          </EmptyMessage>
          <Span>
            <T>Az alábbi gombra kattintva megtekintheted versenyeinket</T>
          </Span>
          <Link to={"/#versenyek"}>
            <StyledButton>
              <T>Versenyek</T>
            </StyledButton>
          </Link>
        </>
      )}
    </>
  );
}

const StyledButton = styled(Button)`
  margin-top: ${spacing2};
  width: fit-content;
`;

const Span = styled.span`
  color: white;
  margin-top: ${spacing4};
`;

const EmptyMessage = styled.span`
  color: white;
  font-size: 24px;
`;

const StyledLink = styled(Link)`
  margin-top: ${spacing4};
  text-decoration: none;
  font-size: 18px;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: ${spacing4};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary400};
  }

  ${down("sm")} {
    font-size: 18px;
  }
`;
