import { CardTitle } from "../profile/common-styles";
import { Col } from "./common-styles";
import styled from "styled-components";
import {
  down,
  HookForm,
  showErrorToast,
  spacing,
  spacing3,
  spacing4,
  T,
  up,
} from "@litbase/alexandria";
import { StyledHookCheckboxField } from "../../form/checkbox-field";
import { ContactField } from "./contact-field";
import { OTPButton, SimplePayButton } from "../../general/payment-buttons";
import { Button, SubmitButton } from "../../../styles/common-styles";
import { InputField } from "../../form/input-field";
import { Line, StepCircle, StepWrapper } from "../../general/steps";
import { useNavigate, useParams } from "react-router-dom";
import { useResource, useResources } from "../../../hooks/use-resources";
import { EntityType } from "../../../types/entity";
import {
  Competition,
  getEndsAt,
  getStartsAt,
} from "../../../types/competition";
import { useCompetitionPrice } from "../../../hooks/use-competition-price";
import {
  refreshLoggedInUserData,
  User,
  useUser,
} from "../../../hooks/use-user";
import { useController, useWatch } from "react-hook-form";
import {
  hackilyGetFormTokenAndStuff,
  hackilySubmitFormRequest,
  sendBackendRequest,
} from "../../../services/backend";
import { mixed, object, string } from "yup";
import { Interweave } from "interweave";
import paymentTermsDoc from "../../../assets/fizetesi_tajekoztato_moonshot.pdf";
import { NumberFormatInputField } from "../../form/number-format-input-field";
import { Team } from "../../../types/team";
import { useState } from "react";
import { maxBy } from "lodash-es";

export function PaymentForm({
  onBack,
  csapat_kod,
}: {
  onBack: () => void;
  csapat_kod?: string;
}) {
  const { id } = useParams();
  const [success, setSuccess] = useState(false);

  const user = useUser();
  const [competition, loading] = useResource<Competition>(
    EntityType.competition,
    id
  );
  const [teams, loadingTeam, included] = useResources<Team>(EntityType.team, {
    filters: {
      "[field_csapat_kod]": csapat_kod,
      include: "field_verseny",
    },
  });

  const price = useCompetitionPrice(id);

  if (csapat_kod && loadingTeam) {
    return null;
  }

  if (loading || !competition) {
    return null;
  }

  const currentTeam = findCurrentTeam(
    teams,
    included as unknown as Competition[]
  );

  const isTeamPaymentActive = !!currentTeam?.attributes.field_csoportos_fizetes;

  if (isTeamPaymentActive && success) {
    return (
      <Col>
        <CardTitle>Sikeres jelentkezés!</CardTitle>
      </Col>
    );
  }

  return (
    <HookForm
      defaultValues={{ fizetes_valaszt: "bankkartya" }}
      yupSchema={schema}
      onSubmit={async (values) => {
        try {
          await submitEventSignup(
            { ...values, ...(csapat_kod ? { csapat_kod } : {}) },
            user,
            competition
          );
        } catch (e) {
          if (isTeamPaymentActive) {
            await refreshLoggedInUserData();
            setSuccess(true);
          } else {
            showErrorToast(<>Hiba</>, <>{e.message}</>);
          }
        }
      }}
    >
      <Col>
        <CardTitle>
          <T>Nevezés</T>
        </CardTitle>
        <StepWrapper>
          <StepCircle onClick={onBack} style={{ cursor: "pointer" }} $isActive>
            1
          </StepCircle>
          <Line />
          <StepCircle $isActive>2</StepCircle>
        </StepWrapper>
        <Grid>
          <Label>
            <T>Esemény neve</T>
          </Label>
          <Value>
            <Interweave content={competition.attributes.title} />
          </Value>
          <Label>
            <T>Esemény időtartama</T>
          </Label>
          <Value>
            {getStartsAt(competition).toLocaleDateString("hu")} -{" "}
            {getEndsAt(competition).toLocaleDateString("hu")}
          </Value>
          {isTeamPaymentActive ? (
            <>
              <Label>
                <T>Csapatod neve</T>
              </Label>
              <Value>{currentTeam.attributes.title}</Value>
            </>
          ) : (
            <>
              <Label>
                <T>A nevezés díja</T>
              </Label>
              <Value>
                {Number(price).toLocaleString("hu")} <T>Ft</T>
              </Value>
            </>
          )}
        </Grid>
        {isTeamPaymentActive ? (
          <>
            <GreenMessage>
              <T>teamPay1</T>
            </GreenMessage>
            <TeamPaymentRow>
              <Button onClick={onBack}>
                <T>Vissza</T>
              </Button>
              <SubmitButton>
                <T>Jelentkezés</T>
              </SubmitButton>
            </TeamPaymentRow>
          </>
        ) : (
          <DefaultPaymentFlow onBack={onBack} />
        )}
      </Col>
    </HookForm>
  );
}

function DefaultPaymentFlow({ onBack }: { onBack?: () => void }) {
  return (
    <>
      <CardTitle>
        <T>Számlázási adatok</T>
      </CardTitle>
      <StyledHookCheckboxField
        name="addressDifferent"
        checkboxLabel={<T>A számlázási adatok eltérnek a lakcímtől</T>}
      />
      <ContactInfoField />

      <CardTitle>
        <T>Fizetés</T>
      </CardTitle>
      <Span>
        <T>Válassz fizetési módot</T>!{" "}
        <a target={"_blank"} href={paymentTermsDoc}>
          <T>Fizetési tájékoztató</T>
        </a>
      </Span>
      <Row>
        <SimplePayButton />
        <OTPButton />
        <Div>
          <TaxError />
          <ControllButtonsWrapper>
            <Button type={"button"} onClick={onBack}>
              <T>Vissza</T>
            </Button>
            <StyledSubmitButton>
              <T>pay</T>
            </StyledSubmitButton>
          </ControllButtonsWrapper>
        </Div>
      </Row>
    </>
  );
}

function findCurrentTeam(teams: Team[], competitions: Competition[]) {
  const latestCompetition = maxBy(
    competitions,
    (competition) => competition.attributes.field_verseny_idoszaka.value
  );

  if (!latestCompetition) {
    return null;
  }

  const latestCompetitionIndex = competitions.indexOf(latestCompetition);

  if (-1 === latestCompetitionIndex) {
    return null;
  }

  return teams[latestCompetitionIndex];
}

const GreenMessage = styled.span`
  color: #4ecb71;
  margin-top: ${spacing._8};
  margin-bottom: ${spacing._8};
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  ${up("sm")} {
    margin-left: auto !important;
    margin-top: auto !important;
  }
`;

function TaxError() {
  const controller = useController({ name: "fizetes_valaszt" });

  if (!controller.fieldState.error) {
    return null;
  }
  return <ErrorSpan>{controller.fieldState.error.message}</ErrorSpan>;
}

const ErrorSpan = styled.span`
  color: red;
  text-align: right;
`;

const schema = object().shape({
  fizetes_valaszt: string().when("payment.field_adoszam", {
    is: (val) => !!val,
    then: (schema) =>
      schema.notOneOf(
        ["szep"],
        "SZÉP kártyás fizetés esetén az adószám nem lehet kitöltve!"
      ),
    otherwise: (schema) => schema,
  }),
});

const Disclaimer = styled.span`
  margin-top: ${spacing3};
`;

const Span = styled.span`
  margin-bottom: ${spacing3};
`;

function ContactInfoField() {
  const value = useWatch({ name: "addressDifferent" });

  if (!value) {
    return null;
  }

  return (
    <>
      <Disclaimer>
        <T>szepCard</T>
      </Disclaimer>
      <ContactField name={"payment"} isBillingData={true}>
        <NumberFormatInputField
          name={"payment.field_adoszam"}
          label={<T>taxNumber</T>}
          format={"########-#-##"}
          mask={"_"}
        />
      </ContactField>
    </>
  );
}

async function submitEventSignup(
  values: Record<string, unknown>,
  user: User,
  competition: Competition
) {
  const { ...billingData } = values.addressDifferent
    ? { ...values, ...values.payment }
    : user;

  console.log(
    JSON.stringify({
      billingData,
      addressDifferent: values.addressDifferent,
      payment: values.payment,
    })
  );

  const response = await hackilySubmitFormRequest(
    "/node/add/versenyjelentkezesek?verseny_id=" + competition.id,
    {
      ...getContactInfo(billingData),
      verseny_id: competition?.attributes.drupal_internal__nid,
      csapat_kod: values.csapat_kod || billingData.csapat_kod || "",
      fizetes_valaszt: values.fizetes_valaszt || "bankkartya",
    },
    {
      postOptions: {
        headers: {
          "do-not-redirect": "true",
        },
      },
      onlyAllowExistingKeys: false,
    }
  );

  if (response.headers.get("lokacio")) {
    location.href = response.headers.get("lokacio");
  } else {
    console.error("No location found in response, headers were: ", [
      ...response.headers,
    ]);
    const text = await response.text();
    if (
      text.includes(
        "Nem jó csapat kód, vagy a csapat nem erre a versenyre adta le a jelentkezést!"
      )
    ) {
      throw new Error(<T>err1</T>);
    }
    throw new Error(<T>err2</T>);
  }
}

export function getContactInfo(billingData: Record<string, any>) {
  return {
    field_szamlazasi_nev: billingData.field_szamlazasi_nev,
    field_iranyitoszam: billingData.field_iranyitoszam,
    field_telepules: billingData.field_telepules,
    field_kozterulet_neve: billingData.field_kozterulet_neve,
    field_kozterulet_jellege:
      billingData.field_kozterulet_jellege?.value ||
      billingData.field_kozterulet_jellege,
    field_hazszam: billingData.field_hazszam,
    field_lepcsohaz: billingData.field_lepcsohaz || "",
    field_emelet: billingData.field_emelet || "",
    field_ajto: billingData.field_ajto || "",
    field_adoszam: billingData.field_adoszam || "",
  };
}

export function fillFormDataWithContactInfo(
  billingData: Record<string, unknown>,
  formData: FormData
) {
  formData.set("field_szamlazasi_nev", billingData.field_name);
  formData.set("field_adoszám", billingData.field_adoszam || 123);
  formData.set("field_iranyitoszam", billingData.field_iranyitoszam);
  formData.set("field_telepules", billingData.field_telepules);
  formData.set("field_kozterulet_neve", billingData.field_kozterulet_neve);
  formData.set(
    "field_kozterulet_jellege",
    billingData.field_kozterulet_jellege?.value ||
      billingData.field_kozterulet_jellege
  );
  formData.set("field_hazszam", billingData.field_hazszam);
  formData.set("field_lepcsohaz", billingData.field_lepcsohaz || "");
  formData.set("field_emelet", billingData.field_emelet || "");
  formData.set("field_ajto", billingData.field_ajto || "");
  formData.set("field_adoszam", billingData.field_adoszam || "");
  return formData;
}

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  ${down("md")} {
    flex-wrap: wrap;
    align-items: flex-start;
    > div:last-child {
      margin-left: 0;
      margin-top: ${spacing4};
    }
  }
`;

const ControllButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: flex-end;
  > * + * {
    margin-left: ${spacing4};
  }
  ${down("sm")} {
    margin-right: auto;
    margin-left: 0;
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  height: fit-content;
  margin-top: auto;
  margin-right: 0;
`;

const Value = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 100;
`;

const Grid = styled.div`
  margin-top: ${spacing4};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${spacing3};
  width: 100%;
`;

const TeamPaymentRow = styled(Row)`
  justify-content: flex-end;
  > * + * {
    margin-left: ${spacing._4};
  }
`;
