import {
  LargeModalLoadingIcon,
  ModalParagraph,
  ModalSubtitle,
} from "../../../styles/modal-styles";
import { Button, SubmitButtonWrapper } from "../../../styles/common-styles";
import { useNavigate } from "react-router-dom";
import { useModal } from "@litbase/alexandria";
import { useEffect, useState } from "react";
import { parseHtml, sendBackendRequest } from "../../../services/backend";
import { refreshLoggedInUserData } from "../../../hooks/use-user";
import { Location } from "react-router-dom";
import { useObservable } from "@litbase/use-observable";
import { MoonshotModal, Title } from "../login-modal";

export function StravaCallbackModal({ location }: { location?: Location }) {
  const navigate = useNavigate();
  const { dismissModal } = useModal();
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function handleCallback() {
      try {
        await processStravaCallback(location);
        // Close modal after 5 seconds
        setTimeout(() => dismissModal(), 5000);
      } catch (error) {
        setError(error as Error);
      } finally {
        // Clear the registration code from the url
        navigate("/profilom", { replace: true });
        setIsProcessing(false);
      }
    }

    handleCallback();
  }, []);

  if (isProcessing) {
    return (
      <MoonshotModal>
        <Title>Strava összekötés</Title>
        <ModalParagraph $align="center">
          A Strava összeköttetés feldolgozás alatt áll...
        </ModalParagraph>
        <LargeModalLoadingIcon />
      </MoonshotModal>
    );
  }

  return (
    <MoonshotModal>
      <Title>Strava összekötés</Title>
      {!error ? (
        <ModalParagraph>
          <ModalSubtitle>Sikeres összeköttetés!</ModalSubtitle>
          <div>
            Sikeresen összekötötted a Strava fiókodat a Moonshotos fiókoddal!
          </div>
        </ModalParagraph>
      ) : (
        <ModalParagraph>
          <ModalSubtitle>
            Hiba történt az összeköttetés során során
          </ModalSubtitle>
          <div>{error.message || String(error)}</div>
        </ModalParagraph>
      )}

      <SubmitButtonWrapper>
        <Button onClick={() => dismissModal()}>Bezárás</Button>
      </SubmitButtonWrapper>
    </MoonshotModal>
  );
}

async function processStravaCallback(location?: Location) {
  if (!location) {
    throw new Error("Ismeretlen hiba.");
  }

  const response = await sendBackendRequest(
    location.pathname + location.search
  ).text();

  if (
    !response.includes(
      "A te fi\\u00f3kod m\\u00e1r \\u00f6ssze lett kapcsolva a Strava fi\\u00f3koddal!"
    ) &&
    !response.includes("Authenticated as Strava user")
  ) {
    throw new Error("Sikertelen összeköttetés");
  }

  await refreshLoggedInUserData();
}

export interface StravaStatus {
  connected: boolean;
  buttonUrl: string;
}

export function useStravaButton(): StravaStatus | null {
  const [result] = useObservable(async () => {
    const response = await sendBackendRequest("/user/login/strava", {
      method: "GET",
    }).text();

    if (
      response.includes(
        "A te fi\\u00f3kod m\\u00e1r \\u00f6ssze lett kapcsolva a Strava fi\\u00f3koddal!"
      )
    ) {
      return {
        connected: true,
        buttonUrl: "",
      };
    }

    if (response.includes("Kapcsold \\u00f6ssze Strava fi\\u00f3kodat")) {
      return {
        connected: false,
        buttonUrl: "",
      };
    }

    let link = response.match(
      /href=\\u0022(https:\\\/\\\/www.strava.com.+)\\u0022\\/
    )?.[1];

    console.log("link1", link);

    if (link) {
      link = JSON.parse(`"${link}"`) || "";

      console.log("link2", link);
    } else {
      try {
        const domDocument = parseHtml(response);

        console.log("link3 document", domDocument);

        const anchorElement = domDocument.querySelector(
          ".strava-auth"
        ) as HTMLAnchorElement | null;

        console.log("link4 element", anchorElement);

        link = anchorElement?.href;

        console.log("link5", link);
      } catch (error) {
        console.error(error);
      }
    }

    console.log("link6", link);

    if (!link) {
      return {
        connected: false,
        buttonUrl: "javascript:alert('Ismeretlen hiba, hibás Strava link');",
      };
    }

    link = link.replace(
      /http(s?)%3A\/\/moonshot.mbsm.hu/,
      location.protocol + "//" + location.host
    );

    return {
      connected: false,
      buttonUrl: link,
    };
  });

  return result;
}
