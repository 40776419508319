import styled from "styled-components";
import { down, spacing3, spacing4, spacing6 } from "@litbase/alexandria";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${spacing3};
  margin-left: -${spacing4};
  margin-top: -${spacing4};
  > * {
    margin-top: ${spacing4} !important;
    margin-left: ${spacing4};
  }
  ${down("sm")} {
    > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const Subtitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-top: ${spacing6};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
