import styled from "styled-components";
import backgroundImage from "../../../assets/earth.webp";
import Line from "../../../assets/vonal.svg";
import { BlockBody, BlueTitle } from "../../../styles/common-styles";
import {
  down,
  FontWeight,
  spacing10,
  spacing12,
  spacing2,
  spacing3,
  spacing32,
  spacing4,
  spacing48,
  spacing6,
  spacing72,
  spacing8,
  T,
  up,
} from "@litbase/alexandria";
import { Container } from "../../container";
import { montserrat } from "../../../styles/theme-types";
import { Logo } from "../../general/logo";
import { Link } from "react-router-dom";
import { Facebook, Instagram } from "@styled-icons/boxicons-logos";
import { StravaLogo } from "../profile/sidebar";
import { Paths } from "../../../utils/paths";
import reszveteli from "../../../assets/reszveteli.pdf";
import stravaTajekoztato from "../../../assets/STRAVA Felhasználási tájékoztató.pdf";

export function EarthBlock({ blockIndex }: { blockIndex: number }) {
  return (
    <Body blockIndex={blockIndex} backgroundImage={backgroundImage}>
      <Wrapper>
        <Title>
          <T>FÖLDKELTE</T>
        </Title>
        {/*<StyledLine />*/}
        <DialogueWrapper>
          <Date>December 24, 1968, 16:00 UTC</Date>
          <DialogueRow>
            <Name>Anders:</Name>
            <Text>
              Oh my God! Look at that picture over there! There’s the Earth
              coming up. Wow, that’ pretty.
            </Text>
          </DialogueRow>
          <DialogueRow>
            <Name> Borman:</Name>
            <Text> Hey, don’t take that, it’s not scheduled. (joking)</Text>
          </DialogueRow>
          <DialogueRow>
            <Name>Anders:</Name>
            <Text>
              (laughs) You got a color film, Jim? <br />
              Hand me that roll of color quick, would you...
            </Text>
          </DialogueRow>
          <DialogueRow>
            <Name>Lovell:</Name>
            <Text>Oh man, that’s great!</Text>{" "}
          </DialogueRow>
        </DialogueWrapper>
      </Wrapper>

      <DesktopMarkerWrapper>
        <ImageWrapper>
          <LineSVG src={Line} />
        </ImageWrapper>
        <AYCM href="https://allyoucanmove.hu/" target="_blank">
          AYCM
        </AYCM>
      </DesktopMarkerWrapper>

      <Footer>
        <StyledContainer>
          <Grid>
            <div>
              <StyledLogo />
              <MobileAYCMWrapper>
                <MobileAYCM href="https://allyoucanmove.hu/" target="_blank">
                  AYCM
                </MobileAYCM>
              </MobileAYCMWrapper>
            </div>
            <FooterTextWrapper>
              <FooterTitle>
                <T>Kapcsolat</T>
              </FooterTitle>
              <StyledA href="mailto:info@moonshotrace.hu">
                info@moonshotrace.hu
              </StyledA>
              <StyledA href="tel: +36 1 445 1563">+36 1 445 1563</StyledA>
              <FooterTitle>
                <T>Látogass el hozzánk</T>
              </FooterTitle>
              <FooterText as="span">1053 Budapest, Károlyi utca 12.</FooterText>
            </FooterTextWrapper>
            <FooterTextWrapper>
              <FooterTitle>
                <T>Kövess minket</T>
              </FooterTitle>
              <FooterText
                as={"a"}
                href={"https://www.facebook.com/Moonshotrace"}
                target={"_blank"}
              >
                Facebook
              </FooterText>
              <FooterText
                as={"a"}
                href={"https://www.instagram.com/moonshotrace/"}
                target={"_blank"}
              >
                Instagram
              </FooterText>{" "}
              <FooterTitle>Tudnivalók</FooterTitle>
              <StyledA target="_blank" href={reszveteli}>
                <T>Általános Részvételi Szabályzat</T>
              </StyledA>
              <StyledA
                target={"_blank"}
                href={
                  "https://allyoucanmove.hu/media/adatkezelesi_tajekoztato.pdf"
                }
              >
                <T>Adatkezelési Tájékoztató</T>{" "}
              </StyledA>
              <StyledA target="_blank" href={stravaTajekoztato}>
                <T>STRAVA Felhasználási Tájékoztató</T>
              </StyledA>
            </FooterTextWrapper>
          </Grid>
        </StyledContainer>
        <StyledFooterContainer>
          <Row>
            <CopyRight>©1999-2022 KLUB Rekreáció Kft.</CopyRight>
            <SocialLinksWrapper>
              <SocialLink
                as={"a"}
                href={"https://www.strava.com"}
                target={"_blank"}
              >
                <StravaLogo />
              </SocialLink>
              <SocialLink
                as={"a"}
                href={"https://www.facebook.com/Moonshotrace"}
                target={"_blank"}
              >
                <Facebook />
              </SocialLink>
              <SocialLink
                as={"a"}
                href={"https://www.instagram.com/moonshotrace/"}
                target={"_blank"}
              >
                <Instagram />
              </SocialLink>
            </SocialLinksWrapper>
          </Row>
        </StyledFooterContainer>
      </Footer>
    </Body>
  );
}

const SocialLink = styled(Link)`
  font-size: 26px;
  color: white;
  margin-left: ${spacing3};
  svg {
  }
  &:hover {
    color: ${({ theme }) => theme.primary400};
  }
`;

const FooterText = styled(Link)`
  font-size: 14px;
  color: rgb(126, 126, 126);
  font-weight: ${FontWeight.Normal};

  &:hover {
    color: ${({ theme }) => theme.primary400};
    text-decoration: none;
  }
`;

const StyledA = styled.a`
  font-size: 14px;
  color: rgb(126, 126, 126);
  font-weight: ${FontWeight.Normal};

  &:hover {
    color: ${({ theme }) => theme.primary400};
    text-decoration: none;
  }
`;

const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${down("md")} {
    padding: ${spacing4} ${spacing4} 0;
  }

  ${down("sm")} {
    flex-direction: column;
  }
`;

const FooterTitle = styled.span`
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${spacing2};
  &:not(:first-child) {
    margin-top: ${spacing4};
  }

  ${down("sm")} {
    margin-top: ${spacing2};
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  ${down("sm")} {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const SocialLinksWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${down("md")} {
    padding: ${spacing4};
  }
  ${down("sm")} {
    margin-left: 0;
  }
`;

const CopyRight = styled.span`
  font-size: 12px;
  ${down("sm")} {
    margin-left: 0;
    margin-top: ${spacing3};
  }
`;

const StyledFooterContainer = styled(Container)`
  padding-bottom: 0.5rem;
  padding-top: 0;
`;

const Body = styled(BlockBody)`
  color: white;
  align-items: center;
  background-size: cover;
  background-position: center;

  > div > div {
    width: 100%;
  }

  ${down("xl")} {
    padding-bottom: 25rem;
  }

  ${down("md")} {
    padding-top: ${spacing6};
    > div > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 610px;
  transform: translateY(-20%);
  margin-bottom: ${spacing8};

  ${down("lg")} {
    transform: none;
    padding: ${spacing4};
    margin-bottom: ${spacing32};
  }
`;

const Title = styled(BlueTitle)`
  text-transform: none;
  text-align: left;
  font-weight: ${FontWeight.Bold};
  margin-bottom: ${spacing4};
`;

const DesktopMarkerWrapper = styled.div`
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 220px;
  height: 16rem;
  position: absolute;
  transform: none;
  bottom: 250px;
  right: 0;

  ${up("lg")} {
    transform: translateY(4rem);
  }

  ${down("md")} {
    display: none;
  }
`;

const AYCM = styled.a`
  color: white;
  margin-left: ${spacing2};
  font-size: 24px;
  letter-spacing: 4px;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
    color: #2c9289;
  }
`;

const MobileAYCMWrapper = styled.div`
  ${up("md")} {
    display: none;
  }

  margin-top: ${spacing4};
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const MobileAYCM = styled(AYCM)`
  ${up("md")} {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  padding-top: ${spacing4};
  flex-shrink: 1;
  min-width: 0;
`;

const Name = styled.div``;

const Text = styled.div`
  margin-left: ${spacing3};
`;

const Date = styled.div`
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: ${spacing8};
  font-weight: ${FontWeight.Regular};
`;

const DialogueWrapper = styled.div`
  padding-left: ${spacing12};
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: ${spacing3} ${spacing10};
  font-weight: ${FontWeight.SemiBold};
  ${montserrat};

  ${down("md")} {
    padding: ${spacing6};
  }
`;

const DialogueRow = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr;
  margin-bottom: ${spacing4};
`;

const Footer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgb(0, 0, 0, 0.8);
  left: 0;
  bottom: 0;

  ${down("sm")} {
  }
`;

const StyledContainer = styled(Container)`
  ${down("md")} {
    padding: ${spacing4};
  }

  display: flex;
  flex-direction: row;
  padding-bottom: 0;
`;

const StyledLogo = styled(Logo)`
  max-width: ${spacing72};

  ${down("md")} {
    max-width: ${spacing48};
  }

  max-height: 100%;
  object-fit: contain;
`;

const StyledLine = styled.div`
  border-top: 3px solid white;
  padding-top: ${spacing4};
`;

const LineSVG = styled.img`
  width: 22rem;
  max-width: 100%;
`;
