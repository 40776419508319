import { MoonshotModal, Title } from "../../login-modal";
import styled from "styled-components";
import { spacing4, HookForm, showErrorToast } from "@litbase/alexandria";
import { InputField } from "../../../form/input-field";
import { SubmitButton } from "../../../../styles/common-styles";
import { object, string } from "yup";
import { getCurrentUser } from "../../../../hooks/use-user";

export function DeleteProfileModal() {
  return (
    <MoonshotModal>
      <Title>Profil törlése</Title>
      <P>
        Biztosan törölni szeretnéd a profilodat? Ezt az akciót nem lehet
        visszavonni
      </P>
      <HookForm
        defaultValues={{}}
        yupSchema={schema}
        onSubmit={async () => {
          await deleteProfile();
        }}
      >
        <InputField
          name={"email"}
          label={
            "Írd be az e-mail címedet hogy megerősítsd a profilod törlését"
          }
        />
        <StyledSubmitButton>Profil törlése</StyledSubmitButton>
      </HookForm>
    </MoonshotModal>
  );
}

async function deleteProfile() {
  showErrorToast(
    "Hiba",
    "A backenden még nem lett implementálva a profil törlése."
  );
}

const schema = object().shape({
  email: string().test(
    "passwords_match",
    "Nem egyezik a profilhoz megadott e-mail címmel",
    (value) => {
      return value === getCurrentUser().mail;
    }
  ),
});

const P = styled.p`
  text-align: start;
  margin-top: ${spacing4};
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${spacing4};
`;
