import { ThemeProvider } from "styled-components";
import { BrowserRouter, Navigate, Outlet, Route } from "react-router-dom";
import { LandingPage } from "./components/pages/landing-page";
import { Paths } from "./utils/paths";
import { GlobalStyle, theme } from "./styles/theme-types";
import { FallingStarStyle, StarStyle } from "./styles/common-styles";
import { PolicyPage } from "./components/pages/policy-page";
import {
  ModalProvider,
  RestrictedProvider,
  RestrictedRoute,
  RestrictedRoutes,
  ToastContainer,
} from "@litbase/alexandria";
import { StatsPage } from "./components/pages/stats/stats-page";
import { AppCol, AppRow } from "./app-col";
import { Navbar } from "./components/general/navbar";
import { MyProfilePage } from "./components/pages/profile/my-profile-page";
import { Dashboard } from "./components/pages/profile/dashboard";
import { EditProfileForm } from "./components/pages/profile/profile-settings/edit-profile-form";
import { MyEvents } from "./components/pages/profile/my-events/my-events";
import { MyTeam } from "./components/pages/profile/my-team/my-team";
import { ReactNode } from "react";
import { currentUser$, isUserEmpty, isUserGuest } from "./hooks/use-user";
import { Leaderboard } from "./components/pages/profile/leaderboard/leaderboard";
import { EventSignupPage } from "./components/pages/event-signup/event-signup-page";
import { Success } from "./components/pages/success";
import "react-toastify/dist/ReactToastify.css";
import { TopScroller } from "./components/general/top-scroller";
import { StravaPage } from "./components/pages/profile/strava/strava-page";
import { ArszPage, StravaPolicyPage } from "./components/pages/arsz-page";
import { FaqPage } from "./components/pages/profile/faq/faq-page";
import { loadTranslations } from "./utils/translations";

loadTranslations();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StarStyle />
      <FallingStarStyle />
      <BrowserRouter>
        <TopScroller />
        <MoonshotRestrictedProvider>
          <ModalProvider>
            <ToastContainer />
            <AppRow>
              <AppCol>
                <RestrictedRoutes>
                  <Route
                    path={"/"}
                    element={
                      <>
                        <Navbar />
                        <Outlet />
                      </>
                    }
                  >
                    <Route
                      path={"/fizetes-feldolgozasa"}
                      element={<Success />}
                    />
                    <Route path={"/siker"} element={<Success />} />
                    <Route path={Paths.MAIN} element={<LandingPage />} />
                    <Route
                      path={Paths.MAIN + "/user/registrationpassword/*"}
                      element={<LandingPage />}
                    />
                    <Route
                      path={Paths.MAIN + "/user/reset/*"}
                      element={<LandingPage />}
                    />
                    <Route
                      path={Paths.MAIN + ":route"}
                      element={<LandingPage />}
                    />
                    <Route path={Paths.POLICY} element={<PolicyPage />} />
                    <Route path={Paths.STATS} element={<StatsPage />} />
                    <Route path={Paths.ARSZ} element={<ArszPage />} />
                    <Route
                      path={Paths.STRAVA_POLICY}
                      element={<StravaPolicyPage />}
                    />

                    <RestrictedRoute
                      path={Paths.EVENT_SIGNUP + "/:id"}
                      element={<EventSignupPage />}
                    />
                    <RestrictedRoute
                      path={Paths.MAIN + "/user/login/strava/*"}
                      element={
                        <MyProfilePage>
                          <Dashboard />
                        </MyProfilePage>
                      }
                    />
                    <RestrictedRoute
                      path={Paths.PROFILE}
                      element={
                        <MyProfilePage>
                          <Outlet />
                        </MyProfilePage>
                      }
                    >
                      <Route index element={<Dashboard />} />
                      <Route
                        path={"szerkesztes"}
                        element={<EditProfileForm />}
                      />
                      <Route path={"versenyeim/*"} element={<MyEvents />} />
                      <Route path={"csapatom"} element={<MyTeam />} />
                      <Route path={"ranglista"} element={<Leaderboard />} />
                      <Route path={"strava"} element={<StravaPage />} />
                      <Route path={"gyik"} element={<FaqPage />} />
                    </RestrictedRoute>
                    <Route path="*" element={<Navigate to={Paths.MAIN} />} />
                  </Route>
                </RestrictedRoutes>
              </AppCol>
            </AppRow>
          </ModalProvider>
        </MoonshotRestrictedProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

function MoonshotRestrictedProvider({ children }: { children: ReactNode }) {
  return (
    <RestrictedProvider
      user$={currentUser$}
      defaultUser={currentUser$.getValue()}
      isEmptyUser={isUserEmpty}
      isGuestUser={isUserGuest}
    >
      {children}
    </RestrictedProvider>
  );
}

export default App;
