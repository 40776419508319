import {
  down,
  HookForm,
  spacing4,
  spacing6,
  T,
  useLanguage,
  useModal,
  useTranslate,
} from "@litbase/alexandria";
import styled from "styled-components";
import { MoonshotModal, StyledHookInputField, Title } from "./login-modal";
import { useEffect, useState } from "react";
import { boolean, object, string } from "yup";
import {
  Button,
  SubmitButton,
  SubmitButtonWrapper,
} from "../../styles/common-styles";
import { Line, StepCircle } from "../general/steps";
import { hackilySubmitForm, sendBackendRequest } from "../../services/backend";
import { refreshLoggedInUserData } from "../../hooks/use-user";
import { PasswordField } from "../form/password-field";
import { FormErrorDisplay } from "../form/form-error-display";
import { HTTPError } from "ky";
import { StyledHookCheckboxField } from "../form/checkbox-field";
import { useNavigate } from "react-router-dom";
import { switchValue } from "../../utils/switch-value";
import {
  LargeModalLoadingIcon,
  ModalParagraph,
  ModalStepWrapper,
  ModalSubtitle,
} from "../../styles/modal-styles";
import {
  BirthdayField,
  birthdayFieldValueToDate,
} from "../form/birthday-field";
import reszveteli from "../../assets/reszveteli.pdf";

interface RegisterFormData {
  name: string;
  email: string;
  password: string;
  dateOfBirth: string;
  acceptTos: boolean;
  acceptNewsLetter: boolean;
}

export function RegistrationModal({ pathname }: { pathname?: string }) {
  const [stepNumber, setStepNumber] = useState<number>(pathname ? 3 : 1);

  return (
    <MoonshotModal>
      <Title>
        <T>Regisztráció</T>
      </Title>
      <ModalStepWrapper>
        <StepCircle $isActive>1</StepCircle>
        <Line />
        <StepCircle $isActive={1 < stepNumber}>2</StepCircle>
        <Line />
        <StepCircle $isActive={2 < stepNumber}>3</StepCircle>
      </ModalStepWrapper>
      {switchValue(stepNumber, [
        [1, <RegistrationStepOne onNext={() => setStepNumber(2)} />],
        [2, <RegistrationStepTwo />],
        [3, <RegistrationStepThree pathname={pathname} />],
      ])}
    </MoonshotModal>
  );
}

function RegistrationStepOne({ onNext }: { onNext: () => void }) {
  const translate = useTranslate();
  const lang = useLanguage();
  return (
    <HookForm<RegisterFormData>
      defaultValues={{ email: "", password: "" }}
      onSubmit={(values) => onSubmit(values, onNext)}
      yupSchema={registrationSchema}
    >
      <Grid>
        <div>
          <FormErrorDisplay />
        </div>
        <StyledHookInputField
          name="email"
          type="email"
          label={<T>Email cím</T>}
        />
        <StyledHookInputField name="name" type="text" label={<T>Név</T>} />
        <StyledBirthdayField name="dateOfBirth" label={<T>Születési idő</T>} />
        <PasswordField
          name="password"
          label={<T>Jelszó</T>}
          placeholder="●●●●●●●●"
        />
      </Grid>
      <StyledHookCheckboxField
        name="acceptTos"
        checkboxLabel={
          <FlexDiv>
            <T>Elfogadom az</T>{" "}
            <BlueLink target="_blank" href={translate("privacyPolicyLink")}>
              {" "}
              <T>Adatkezelési Tájékoztatót</T>{" "}
            </BlueLink>{" "}
            <T>és az</T>
            <BlueLink
              target="_blank"
              // TODO: Betenni ha meglesz az angol részvételi
              href={lang === "hu" ? reszveteli : reszveteli}
            >
              <T>Általános Részvételi Szabályzatot</T>
            </BlueLink>
          </FlexDiv>
        }
      />
      <SubmitButtonWrapper>
        <SubmitButton>
          <T>Tovább</T>
        </SubmitButton>
      </SubmitButtonWrapper>
    </HookForm>
  );
}

function RegistrationStepTwo() {
  const { dismissModal } = useModal();

  return (
    <div>
      <ModalParagraph>
        <ModalSubtitle>
          <T>Megerősítő email elküldve</T>
        </ModalSubtitle>
        <div>
          <T>register1</T>
        </div>
      </ModalParagraph>
      <SubmitButtonWrapper>
        <Button onClick={() => dismissModal()}>
          <T>Bezárás</T>
        </Button>
      </SubmitButtonWrapper>
    </div>
  );
}

function RegistrationStepThree({ pathname }: { pathname?: string }) {
  const navigate = useNavigate();
  const { dismissModal } = useModal();
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function handleStepThree() {
      try {
        await processRegistration(pathname);
        // Close modal after 5 seconds
        setTimeout(() => dismissModal(), 5000);
      } catch (error) {
        setError(error as Error);
      } finally {
        // Clear the registration code from the url
        navigate("/", { replace: true });
        setIsProcessing(false);
      }
    }

    handleStepThree();
  }, []);

  if (isProcessing) {
    return (
      <div>
        <ModalParagraph $align="center">
          <T>A regisztrációd feldolgozás alatt áll</T>...
        </ModalParagraph>
        <LargeModalLoadingIcon />
      </div>
    );
  }

  return (
    <div>
      {!error ? (
        <ModalParagraph>
          <ModalSubtitle>
            <T>Sikeres aktiváció</T>!
          </ModalSubtitle>
          <div>
            <T>successfulRegister</T>
          </div>
        </ModalParagraph>
      ) : (
        <ModalParagraph>
          <ModalSubtitle>
            <T>Hiba történt a regisztrációd során</T>
          </ModalSubtitle>
          <div>{error.message || String(error)}</div>
        </ModalParagraph>
      )}

      <SubmitButtonWrapper>
        <Button onClick={() => dismissModal()}>
          <T>Bezárás</T>
        </Button>
      </SubmitButtonWrapper>
    </div>
  );
}

async function onSubmit(
  { name, email, password, dateOfBirth, acceptNewsLetter }: RegisterFormData,
  onNext: () => void
) {
  try {
    await hackilySubmitForm(
      "/user/register",
      {
        "field_name[0][value]": name,
        mail: email,
        "pass[pass1]": password,
        "pass[pass2]": password,
        "field_date_of_birth[0][value][date]": `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`,
        "field_hirlevel[0][value]": String(acceptNewsLetter ? 1 : 0),
        op: "Új fiók létrehozása",
      },
      {
        formSelector: "form.user-register-form",
        postOptions: {
          headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      }
    );

    await refreshLoggedInUserData();

    onNext();
  } catch (error) {
    let errorMessage: string;

    if (error instanceof HTTPError) {
      const errorResponse = await error.response.json();

      errorMessage = errorResponse?.message || String(errorResponse);
    } else {
      errorMessage = String(error);
    }

    if (errorMessage.match(/The email address.*is already taken/)) {
      throw new Error("A megadott email címmel már regisztráltak");
    }

    throw new Error(errorMessage);
  }
}

const registrationSchema = object().shape({
  email: string()
    .required("E-mail cím megadása kötelező")
    .email("E-mail cím formátuma nem megfelelő"),
  name: string().required("Név megadása kötelező"),
  dateOfBirth: object().test(
    "test_date_of_birth",
    "Adj meg érvényes dátumot!",
    (value) => {
      console.log(value);
      return !!birthdayFieldValueToDate(value);
    }
  ),
  password: string()
    .required("Jelszó megadása kötelező")
    .matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "A jelszónak minimum 8 karakter hosszúnak kell lennie. Tartalmaznia kell legalább 1 nagybetűt és legalább 1 számot."
    ),
  acceptTos: boolean().oneOf([true], "Elfogadás kötelező"),
});

async function processRegistration(pathname?: string) {
  if (!pathname) {
    throw new Error("Ismeretlen hiba.");
  }

  const response = await sendBackendRequest(pathname).text();

  if (
    response.includes(
      "Az egyszeri bel\\u00e9ptet\\u0151 hivatkoz\\u00e1s m\\u00e1r fel lett haszn\\u00e1lva"
    )
  ) {
    throw new Error(
      "Az egyszeri beléptető hivatkozás már fel lett használva, vagy érvényessége lejárt."
    );
  }

  await refreshLoggedInUserData();
}

const StyledBirthdayField = styled(BirthdayField)`
  margin-top: ${spacing6};
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0;
`;

const FlexDiv = styled.div`
  ${down("md")} {
    display: inherit;
  }
`;

const BlueLink = styled.a`
  color: ${({ theme }) => theme.lightBlue};
`;

const MandatoryCitation = styled.div`
  margin-top: ${spacing4};
`;
