// Returns the first ancestor (or the element itself), which matches the given predicate
export function findClosest(
  element: HTMLElement,
  predicate: (element: HTMLElement) => boolean
) {
  let current: HTMLElement | null = element;

  while (current && !predicate(current)) {
    current = current.parentElement;
  }

  return current;
}
