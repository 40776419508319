import { useMeasure } from "@litbase/alexandria";
import { createContext, ReactNode, useRef, useState } from "react";
import styled from "styled-components";

export const SvgContext = createContext<{ top: number }>({ top: 0 });

export function ConnectionContainer({ children }: { children: ReactNode }) {
  const ref = useRef<SVGSVGElement>(null);
  const [bounds, setBounds] = useState({ top: 0, left: 0 });
  useMeasure((bounds) => {
    const rect = ref.current?.getBoundingClientRect();
    setBounds({
      ...rect,
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  }, ref);

  return (
    <SvgContext.Provider value={bounds}>
      <Svg ref={ref}>
        <defs>
          <Marker />
        </defs>
        {children}
      </Svg>
    </SvgContext.Provider>
  );
}

function Marker() {
  const width = 5;
  const height = 8;
  const padding = 1;
  return (
    <marker
      id="arrowhead"
      markerWidth={width}
      markerHeight={height}
      refX={2}
      refY={height / 2}
      orient="auto"
      stroke={"white"}
      fill={"none"}
    >
      <path
        strokeWidth={1}
        d={`m ${padding} ${padding} L ${width - padding} ${
          (height - padding + 1) / 2
        } L ${padding} ${height - padding}`}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      />
    </marker>
  );
}

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
