import styled from "styled-components";
import { BlockBody, Button, SubmitButton } from "../../../styles/common-styles";
import { Card, CardTitle, Title } from "../profile/common-styles";
import {
  down,
  HookForm,
  spacing16,
  spacing32,
  spacing4,
  spacing6,
  T,
} from "@litbase/alexandria";
import { InputField } from "../../form/input-field";
import { SelectField } from "../../form/select-field";
import { Col, Row, Subtitle } from "./common-styles";
import { ContactField } from "./contact-field";
import { PaymentForm } from "./payment-form";
import { useParams } from "react-router-dom";
import backgroundImg from "./assets/event-signup-bg.png";
import {
  refreshLoggedInUserData,
  User,
  useUser,
} from "../../../hooks/use-user";
import { EntityType } from "../../../types/entity";
import { getNewCsrfToken, sendBackendRequest } from "../../../services/backend";
import { Line, StepCircle, StepWrapper } from "../../general/steps";
import { Container, Page } from "../../container";
import { object, string } from "yup";
import { useFormState } from "react-hook-form";
import { useState } from "react";

export function EventSignupPage() {
  const user = useUser();
  const [step, setStep] = useState(0);
  const [teamCode, setTeamCode] = useState("");
  return (
    <Body>
      <Container>
        <PageTitle>
          <T>Versenyjelentkezés</T>
        </PageTitle>
        <FormCard>
          {step === 1 ? (
            <PaymentForm csapat_kod={teamCode} onBack={() => setStep(0)} />
          ) : (
            <UserDataForm
              onSubmit={(values) => {
                setTeamCode(values.csapat_kod);
                setStep(1);
              }}
            />
          )}
        </FormCard>
      </Container>
    </Body>
  );
}

const PageTitle = styled(Title)`
  margin: ${spacing6} auto;
  ${down("md")} {
    margin-top: ${spacing16};
    margin-bottom: ${spacing16};
    font-size: 28px;
  }
`;

const FormCard = styled(Card)`
  border: none;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
`;

const Body = styled(Page)`
  background-image: url("${backgroundImg}");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    > div {
      width: 100%;
    }
    ${down("md")} {
      padding: ${spacing4};
    }
  }
`;

const genderOptions = [
  { value: "Férfi", label: <T>Férfi</T> },
  { value: "Nő", label: <T>Nő</T> },
];
const hearOptions = [
  { value: "Facebook", label: "Facebook" },
  { value: "Hírlevél", label: <T>Hírlevél</T> },
  { value: "Instagram", label: "Instagram" },
  { value: "Google", label: <T>Google keresés</T> },
  { value: "Kollégák", label: <T>Kollégák, céges kommunikáció</T> },
  { value: "AYCM", label: <T>AYCM oldal</T> },
  { value: "Barátok", label: <T>Barátok, egyetemi csoport</T> },
  { value: "Egyéb", label: <T>Egyéb</T> },
];

function UserDataForm({
  onSubmit,
}: {
  onSubmit: (values: Record<string, unknown>) => void;
}) {
  const user = useUser();
  return (
    <>
      <HookForm
        yupSchema={yupSchema}
        defaultValues={{
          ...user,
          field_gender: user.field_gender
            ? {
                value: user.field_gender,
                label: user.field_gender,
              }
            : undefined,
          field_country: user.field_country
            ? {
                value: user.field_country,
                label: user.field_country,
              }
            : undefined,
          field_kozterulet_jellege: user.field_kozterulet_jellege
            ? {
                value: user.field_kozterulet_jellege,
                label: user.field_kozterulet_jellege,
              }
            : undefined,
          field_source: user.field_source?.value
            ? {
                value: user.field_source?.value,
                label: user.field_source?.value,
              }
            : undefined,
        }}
        onSubmit={async (values: User) => {
          const {
            field_nationality,
            field_gender,
            field_source,
            field_education,
            field_company,
            field_country,
            field_iranyitoszam,
            field_telepules,
            field_hazszam,
            field_phone,
            field_kozterulet_jellege,
            field_kozterulet_neve,
            field_szamlazasi_nev,
          } = values;
          await saveUserData(
            {
              field_nationality,
              field_gender,
              field_source,
              field_education,
              field_company,
              field_country,
              field_iranyitoszam,
              field_telepules,
              field_hazszam,
              field_phone,
              field_kozterulet_jellege,
              field_kozterulet_neve,
              field_szamlazasi_nev,
            },
            user
          );
          await refreshLoggedInUserData();
          onSubmit(values);
        }}
      >
        <ErrorLogger />
        <Col>
          <CardTitle>
            <T>Versenyző adatai</T>
          </CardTitle>
          <StepWrapper>
            <StepCircle $isActive>1</StepCircle>
            <Line />
            <StepCircle>2</StepCircle>
          </StepWrapper>
          <Subtitle>
            <T>Személyes adatok *</T>
          </Subtitle>
          <Row>
            <SelectField
              name={"field_gender"}
              label={<T>Nem</T>}
              options={genderOptions}
            />
          </Row>
          <Subtitle>
            <T>Elérhetőség*</T>
          </Subtitle>
          <ContactField />
          <Subtitle>
            <T>Egyéb adatok</T>
          </Subtitle>
          <Row>
            <SelectField
              name={"field_source"}
              label={<T>Honnan hallottál a MOONSHOT versenyről?*</T>}
              options={hearOptions}
            />
            <InputField
              name={"field_company"}
              label={<T>Melyik cégtől/intézménytől jelentkezel?</T>}
            />
          </Row>
          <Row>
            <InputField name={"csapat_kod"} label={<T>Csapatkód</T>} />
          </Row>
          <Row>
            <NextButton type={"submit"}>
              <T>Tovább</T>
            </NextButton>
          </Row>
        </Col>
      </HookForm>
    </>
  );
}

function ErrorLogger() {
  const formState = useFormState();
  return null;
}

const yupSchema = object().shape({
  field_gender: object()
    .typeError("Ez a mező kötelező")
    .shape({
      value: string().required("Ez a mező kötelező"),
    }),
  field_country: object()
    .typeError("Ez a mező kötelező")
    .shape({
      value: string().required("Ez a mező kötelező"),
    }),
  field_iranyitoszam: string().required("Ez a mező kötelező!"),
  field_telepules: string().required("Ez a mező kötelező!"),
  field_kozterulet_neve: string().required("Ez a mező kötelező!"),
  field_kozterulet_jellege: object()
    .typeError("Ez a mező kötelező")
    .shape({
      value: string().required("Ez a mező kötelező"),
    }),
  field_hazszam: string().required("Ez a mező kötelező!"),
  field_phone: string()
    .matches(
      /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
      "Adj meg érvényes telefonszámot! (+36 kezdettel)"
    )
    .required("Ez a mező kötelező!"),
  field_source: object().shape({
    value: string().required("Ez a mező kötelező"),
  }),
});

async function saveUserData(data: Record<string, any>, user: User) {
  const csrfToken = await getNewCsrfToken();

  return sendBackendRequest(`/${EntityType.user}/${user.id}`, {
    method: "PATCH",
    json: {
      data: {
        type: "user--user",
        id: user.id,
        attributes: Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value?.value ? value.value : value,
          ])
        ),
      },
    },
    headers: {
      "Content-Type": "application/vnd.api+json",
      "X-CSRF-Token": csrfToken,
    },
  });
}

const NextButton = styled(SubmitButton)`
  margin-left: auto;
  margin-right: 0;
`;
