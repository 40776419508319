import backgroundImg from "./assets/block-3-bg.png";
import strava from "../../../assets/lottie/strava_light_purple_20.json";
import run from "../../../assets/lottie/run_light_purple_20.json";
import callSign from "../../../assets/lottie/felkialltojel_light_purple.json";
import distance from "../../../assets/lottie/distance_light_purple_20.json";
import styled, { css } from "styled-components";
import { Container } from "../../container";
import {
  down,
  RichTextDisplay,
  spacing12,
  spacing16,
  spacing4,
  spacing5,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import { BlockBody, BlueTitle, Subtitle } from "../../../styles/common-styles";
import Lottie from "lottie-react";
import { useVisibleBlock } from "../../general/block-indicator";
import { useEffect, useRef, useState } from "react";
import { BlockData, BlockType } from "../../../types/block";
import { verticalDown } from "../../../styles/theme-types";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { Interweave } from "interweave";

export function HowItWorksBlock({
  blockIndex,
  block,
}: {
  blockIndex: number;
  block: BlockData<BlockType.howItWorks>;
}) {
  const [activeBlock] = useVisibleBlock();
  const isSmall = useBreakpoint(down("xl"));
  return (
    <Body
      blockIndex={blockIndex}
      backgroundImage={backgroundImg}
      hasFallingStars
    >
      <Container>
        <StyledBlueTitle>
          {block.attributes.field_how_it_works_title}
        </StyledBlueTitle>
        <Subtitle>{block.attributes.field_how_it_works_subtitle}</Subtitle>
        <Grid>
          <Div1>
            <Station
              icon={distance}
              start={activeBlock === blockIndex || isSmall}
              title={block.attributes.field_map_title}
              description={block.attributes.field_map_content}
            />
          </Div1>
          <Div2>
            <Station
              icon={run}
              start={activeBlock === blockIndex}
              title={block.attributes.field_run_title}
              description={block.attributes.field_run_content}
            />
          </Div2>
          <Div3>
            <Station
              icon={strava}
              start={activeBlock === blockIndex}
              title={block.attributes.field_strava_title}
              description={block.attributes.field_strava_content}
            />
          </Div3>
          <Div4>
            <Station
              icon={callSign}
              start={activeBlock === blockIndex}
              title={block.attributes.field_warning_title}
              description={block.attributes.field_warning_content}
            />
          </Div4>
        </Grid>
      </Container>
    </Body>
  );
}

function Station({
  icon,
  title,
  description,
  start,
  highlighted = false,
}: {
  icon: string;
  title: string;
  description: string;
  start: boolean;
  highlighted?: boolean;
}) {
  const ref = useRef();

  const [shouldPlay, setShouldPlay] = useState(start);

  useEffect(() => {
    if (start) {
      setShouldPlay(true);
    }
  }, [start]);

  useEffect(() => {
    if (shouldPlay) {
      ref.current?.play();
      // For some reason the animations always played twice, so I had to hack around it
      setTimeout(() => {
        ref.current.goToAndStop(ref.current?.getDuration(true) - 1, true);
      }, ref.current?.getDuration() * 1000 - 150);
    }
  }, [shouldPlay]);

  return (
    <StationWrapper $highlighted={highlighted}>
      <Icon
        lottieRef={ref}
        autoplay={false}
        animationData={icon}
        loop={false}
      />
      <StationTitle>{title}</StationTitle>

      <StationDescription>
        <Interweave content={description} />
      </StationDescription>
    </StationWrapper>
  );
}

const StyledBlueTitle = styled(BlueTitle)`
  margin-top: ${spacing16};
`;

const Body = styled(BlockBody)`
  color: white;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
`;

const StationWrapper = styled.div<{ $highlighted: boolean }>`
  text-align: center;
  align-items: center;
  ${({ theme, $highlighted }) =>
    $highlighted &&
    css`
      > *:nth-child(2) {
        font-size: 24px;
      }
      font-weight: bold;
    `};
`;

const Icon = styled(Lottie)`
  margin: auto;
  width: 70px;
  margin-bottom: ${spacing8};
  ${verticalDown("xl")} {
    width: 40px;
    margin-bottom: ${spacing4};
  }
`;

const StationTitle = styled.div`
  margin-bottom: ${spacing5};
  font-size: 18px;
  ${verticalDown("xl")} {
    font-size: 16px;
  }
`;
const StationDescription = styled.div`
  ${verticalDown("xl")} {
    font-size: 14px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  ${down("lg")} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: ${spacing12};
  }
  ${down("md")} {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
    padding: ${spacing4};
  }
`;

const Div1 = styled.div``;
const Div2 = styled.div``;
const Div3 = styled.div``;
const Div4 = styled.div`
  grid-column: 1 / -1;
  ${down("lg")} {
    grid-column: span 1;
    > * {
      max-width: 100%;
    }
  }
  > * {
    max-width: 33%;
    ${down("lg")} {
      max-width: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;
