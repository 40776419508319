import {
  down,
  spacing12,
  spacing16,
  spacing6,
  useModal,
  Restricted,
  T,
} from "@litbase/alexandria";
import styled from "styled-components";
import {
  BlockBody,
  BlockBodyContent,
  FixedBlockBody,
  SignUpButton,
} from "../../../styles/common-styles";
import { animated, config, useSpring } from "react-spring";
import moonPng from "../../../assets/policy-moon-3.png";
import { useVisibleBlock } from "../../general/block-indicator";
import { Container } from "../../container";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { BlockData, BlockType } from "../../../types/block";
import { Interweave } from "interweave";
import { verticalDown } from "../../../styles/theme-types";
import { RegistrationModal } from "../registration-modal";

export function VirtualCompetitionBlock({
  blockIndex,
  block,
}: {
  blockIndex: number;
  block: BlockData<BlockType.virtualCompetition>;
}) {
  const { showModal } = useModal();
  const [activeBlock] = useVisibleBlock();
  const styles = useSpring({
    top: getMoonTop({ activeIndex: activeBlock, blockIndex }),
    transform: "translateY(-50%)",
    config: { ...config.slow },
  });
  const isSmall = useBreakpoint(down("xl"));
  return (
    <Body
      blockIndex={blockIndex}
      staticContent={
        <>
          <AnimContainer style={isSmall ? undefined : styles}>
            <MoonImage src={moonPng} />
          </AnimContainer>
        </>
      }
    >
      <DarkCard>
        <StyledCardTitle>
          {block.attributes.field_virtu_comp_title}
        </StyledCardTitle>
        {block.attributes.field_virtu_comp_content?.map((elem) => (
          <P key={elem.value}>
            <Interweave noHtml content={elem.value} />
          </P>
        ))}
        <Restricted>
          <StyledSignupButton
            onClick={() =>
              document.querySelector("#versenyek")?.scrollIntoView()
            }
          >
            <T>JELENTKEZZ</T>
          </StyledSignupButton>
        </Restricted>
        <Restricted guest>
          <StyledSignupButton onClick={() => showModal(<RegistrationModal />)}>
            <T>JELENTKEZZ</T>
          </StyledSignupButton>
        </Restricted>
      </DarkCard>
    </Body>
  );
}

const StyledSignupButton = styled(SignUpButton)`
  padding: 7px 20px;
  font-size: 18px;
`;

function getMoonTop({
  activeIndex,
  blockIndex,
}: {
  activeIndex: number;
  blockIndex: number;
}) {
  const diff = blockIndex - activeIndex;

  switch (diff) {
    case 1:
      return "130%";
    case 0:
      return "50%";
    case -1:
      return "-100%";
    default:
      return diff < -1 ? "-100%" : "175%";
  }
}

const AnimContainer = styled(animated(Container))`
  position: fixed;
  ${verticalDown("lg")} {
    display: none;
  }
  ${down("md")} {
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    max-width: 100%;
    position: absolute;
    display: none;
  }
`;

const MoonImage = styled(animated.img)`
  width: 100%;
  object-fit: contain;
`;

const P = styled.p`
  font-size: 16px;
  ${down("md")} {
    text-align: start;
  }
`;

const CardTitle = styled.h2`
  color: white;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  font-size: 24px;
`;

const DarkCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: ${spacing12};
  max-width: 600px;
  > * + * {
    margin-top: ${spacing16};
  }
  ${down("md")} {
    padding: 0;
    > * + * {
      margin-top: ${spacing6};
    }
  }
`;

const Body = styled(BlockBody)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${FixedBlockBody} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${down("md")} {
      position: relative;
      padding: ${spacing6};
    }
  }

  ${BlockBodyContent} {
    align-items: center;
  }
`;

const StyledCardTitle = styled(CardTitle)`
  color: ${({ theme }) => theme.lightPurple};
`;
