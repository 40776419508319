import { RefObject, useEffect, useRef } from "react";
import { delay } from "@litbase/alexandria";
import { useObservable } from "@litbase/use-observable";
import { fromEvent } from "rxjs";
import { map, startWith, throttleTime } from "rxjs/operators";

export function useFallingStars(
  parentRef: RefObject<HTMLElement>,
  enabled: boolean = true
) {
  const [{ width, height }] = useObservable(
    () =>
      fromEvent(window, "resize").pipe(
        startWith(null),
        throttleTime(250, undefined, { leading: true, trailing: true }),
        map(() => ({ width: window.innerWidth, height: window.innerHeight }))
      ),
    { width: 0, height: 0 },
    []
  );

  const starPool = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    // 120 stars on a 1280x720 display
    const targetCount = (width * height) / 7680;

    parentRef.current?.replaceChildren();

    if (enabled) {
      for (let i = 0; i < targetCount; i++) {
        starBlink(getRandomX(width), getRandomY(height), parentRef.current);
      }
    }

    return () => parentRef.current?.replaceChildren();
  }, [parentRef.current, enabled, width, height]);

  useEffect(() => {
    let isUnmounted = false;

    async function startFallingStars() {
      while (!isUnmounted) {
        fallingStar(
          getRandomLeft(),
          getRandomTop(),
          parentRef.current,
          starPool.current
        );
        await delay(1500 + Math.random() * 4500);
      }
    }
    if (enabled) {
      startFallingStars();
    }

    return () => {
      isUnmounted = true;
    };
  }, [parentRef.current, enabled]);

  useEffect(() => () => cleanupPool(starPool.current), []);
}

function fallingStar(
  left: string,
  top: string,
  parent: HTMLElement | null,
  starPool: HTMLDivElement[]
) {
  if (!parent) {
    return;
  }

  const animationDurationMs = 4000;

  const div = getStarFromPool(starPool);

  const timeoutId = setTimeout(() => {
    returnStarToPool(div, starPool);
  }, animationDurationMs + 1000);

  div.className = "fallingStar";
  div.style.top = top + "px";
  div.style.left = 0 + "px";
  div.style.animationDuration = `${animationDurationMs}ms`;
  div.addEventListener(
    "animationend",
    () => {
      clearTimeout(timeoutId);
      returnStarToPool(div, starPool);
    },
    {
      once: true,
    }
  );

  parent.appendChild(div);
}

function starBlink(x: string, y: string, parent: HTMLElement | null) {
  if (!parent) {
    return;
  }

  const div = document.createElement("div");

  const blinkInterval = 3000 + Math.random() * 3000;
  const blinkDelay = 1000 + Math.random() * 5000;

  div.className = "star";
  div.style.top = y + "px";
  div.style.left = x + "px";
  div.style.animationDelay = blinkDelay + "ms";
  div.style.animationDuration = blinkInterval + "ms";
  div.style.animationIterationCount = "infinite";

  parent.appendChild(div);
}

function returnStarToPool(star: HTMLDivElement, pool: HTMLDivElement[]) {
  // Star has already been recycled, avoid double recycling
  if (star.className === "recycled") {
    return;
  }

  // Reset class
  star.className = "recycled";
  // Reset inline styles
  star.removeAttribute("style");
  // Hide the star
  star.style.display = "none";
  pool.push(star);
}

function getStarFromPool(pool: HTMLDivElement[]) {
  let star = pool.pop();

  if (!star) {
    star = document.createElement("div");
  }

  // Reset attributes
  star.removeAttribute("class");
  star.removeAttribute("style");
  return star;
}

function cleanupPool(pool: HTMLDivElement[]) {
  for (const entry of pool) {
    entry.className = "recycled";
    entry.remove();
  }

  pool.length = 0;
}

function getRandomX(fullWidth: number) {
  return Math.floor(Math.random() * Math.floor(fullWidth)).toString();
}

// Used for stationary stars
function getRandomY(maxHeight: number = window.innerHeight) {
  return Math.floor(Math.random() * Math.floor(maxHeight)).toString();
}
function getRandomLeft() {
  return Math.floor(
    Math.random() * Math.floor(window.innerWidth / 2)
  ).toString();
}

// Used for falling stars
function getRandomTop() {
  return Math.floor(
    Math.random() * Math.floor(window.innerHeight / 2)
  ).toString();
}
