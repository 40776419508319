export function jumpToElement(path: string) {
  const match = path.match(/#([a-zA-Z0-9-]+)/);

  if (!match) {
    window.scroll({ top: 0, behavior: "auto" });
    return;
  }

  const [, p1] = match;

  document.getElementById(p1)?.scrollIntoView({ behavior: "smooth" });
}
