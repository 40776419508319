import styled from "styled-components";
import { Card, CardTitle } from "./common-styles";
import { spacing2, spacing4 } from "@litbase/alexandria";
import { verticalDown } from "../../../styles/theme-types";

export const BasicStatsCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-row-gap: ${spacing4};
  ${CardTitle} {
    grid-column: span 2;
    ${verticalDown("lg")} {
      margin-bottom: 0;
    }
  }
  ${verticalDown("lg")} {
    grid-row-gap: ${spacing2};
  }
`;

export const StatValue = styled.span`
  font-size: 18px;
  color: white;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  font-weight: 200;
  ${verticalDown("lg")} {
    font-size: 16px;
  }
`;

export const StatLabel = styled.span`
  font-size: 24px;
  color: white;
  font-weight: bold;
  font-family: ${({ theme }) => theme.headingsFontFamily};
  justify-self: end;
  white-space: nowrap;
  ${verticalDown("lg")} {
    font-size: 18px;
  }
`;
