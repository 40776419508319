import styled from "styled-components";
import { Page } from "../../../container";
import { Card, CardTitle, Title } from "../common-styles";
import {
  spacing1,
  spacing2,
  spacing4,
  spacing40,
  spacing6,
  T,
} from "@litbase/alexandria";
import { Button } from "../../../../styles/common-styles";
import { switchValue } from "../../../../utils/switch-value";
import { useStravaButton } from "../strava-confirmation-modal";
import { sendBackendRequest } from "../../../../services/backend";
import { Link } from "react-router-dom";
import paymentTermsDoc from "../../../../assets/fizetesi_tajekoztato_moonshot.pdf";
import stravaTajekoztato from "../../../../assets/STRAVA Felhasználási tájékoztató.pdf";

export function StravaPage() {
  const stravaStatus = useStravaButton();
  console.log({ stravaStatus });
  return (
    <>
      <Title>Strava</Title>
      <StyledCard>
        <CardTitle>
          <T>Kapcsold össze Strava fiókodat a Moonshot fiókoddal</T>
        </CardTitle>
        <Subtitle hidden>
          <T>
            Kapcsold össze Strava fiókodat a Moonshot fiókoddal és élvezd az
            alábbi szuper új funkciókat:
          </T>
        </Subtitle>
        <ul hidden>
          <li>Stravas edzéseid automatikusan szinkronizálásra kerülnek</li>
          <li>További szuper funkció</li>
          <li>
            Még több újdonság, ez a szöveg teljesen testreszabható Stravas
            edzéseid automatikusan szinkronizálásra kerülnek
          </li>
          <li>További szuper funkció</li>
          <li>Még tött újdonság, ez a szöveg teljesen testreszabható</li>
        </ul>
        <Subtitle>
          <T>A Strava összekötése egyszerű, kövesd az alábbi lépéseket:</T>
        </Subtitle>
        <ol>
          <li>
            <T>strava1</T>
          </li>
          <li>
            <T>strava2</T>
          </li>
          <li>
            <T>strava3</T>
          </li>
          <li>
            <T>strava4</T>
          </li>
          <li>
            <T>strava5</T>
          </li>
        </ol>
        <Row>
          <p>
            <T>stravaBottom</T>{" "}
            <a target={"_blank"} href={stravaTajekoztato}>
              <T>ide</T>{" "}
            </a>
            .
          </p>
          <StravaButtonWrapper>
            {switchValue(stravaStatus?.connected, [
              [
                true,
                <div>
                  <T>Összekötve Strava-val</T>
                </div>,
              ],
              [
                false,
                <OrangeButton onClick={connectWithStrava}>
                  <T>Összekötés Strava-val</T>
                </OrangeButton>,
              ],
              [null, <></>],
            ])}
          </StravaButtonWrapper>
        </Row>
      </StyledCard>
    </>
  );
}

const StyledCard = styled(Card)`
  li {
    font-weight: 400;
    font-size: 16px;
    font-family: ${({ theme }) => theme.headingsFontFamily};
  }
  ol {
    margin-left: 0;
    padding-left: ${spacing4};
  }
  p {
    text-align: start;
  }
`;

const Subtitle = styled.p`
  text-align: start;
  font-weight: bold;
  font-family: ${({ theme }) => theme.headingsFontFamily};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: ${({ theme }) => theme.headingsFontFamily};
`;

const OrangeButton = styled(Button)`
  justify-content: center;
  background-color: ${({ theme }) => theme.orange};
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  margin: ${spacing2};
  min-width: ${spacing40};
  border: 2px solid transparent;
  padding: ${spacing1} ${spacing6};

  &:hover {
    color: ${({ theme }) => theme.orange}!important;
    background-color: ${({ theme }) => theme.white};
  }
`;

const StravaButtonWrapper = styled.div`
  color: white;
`;

async function connectWithStrava() {
  const response = await sendBackendRequest("/user/login/strava", {
    method: "GET",
  }).text();

  // const responseDocument = parseHtml(response);
  // const scriptElements = [...responseDocument.querySelectorAll("script[type=\"application/vnd.drupal-ajax\"]")]
  //   .map(el => el.textContent)
  //   .filter(c => c)
  //   .map(JSON.parse);

  let link = response.match(
    /href=\\u0022(https:\\\/\\\/www.strava.com.+)\\u0022\\/
  )?.[1];

  if (!link) {
    alert("Ismeretlen hiba, hibás Strava link");
    return;
  }

  link = JSON.parse(`"${link}"`) || "";
  link = link.replace(
    /http(s?)%3A\/\/(admin\.)?moonshot(race)?(\.mbsm)?.hu/,
    location.protocol + "//" + location.host
  );

  location.href = link;
}
