import { Container } from "../container";
import styled from "styled-components";
import backgroundImage from "../../assets/policybackground.png";
import {
  Button,
  down,
  roundedLg,
  spacing10,
  spacing12,
  spacing16,
  spacing2,
  spacing24,
  spacing28,
  spacing32,
  spacing4,
  spacing40,
  spacing48,
  spacing5,
  spacing56,
  spacing6,
  spacing8,
  useBreakpoint,
  useModal,
  Restricted,
  T,
} from "@litbase/alexandria";
import { montserrat } from "../../styles/theme-types";
import moon1 from "../../assets/policy-moon-1.png";
import moon2 from "../../assets/policy-moon-2.png";
import moon3 from "../../assets/policy-moon-3.png";
import exclamationMark from "../../assets/felkialtojel.svg";

import { useInView } from "react-intersection-observer";
import { ReactNode, useMemo, useRef } from "react";
import { ConnectionContainer } from "./rules/connection-container";
import { DivConnection } from "./rules/div-connection";
import { jumpToElement } from "../../links";
import { Logo } from "../general/logo";
import { RegistrationModal } from "./registration-modal";
import { getImageUrl } from "../../utils/get-image-url";

export function PolicyPage() {
  const { showModal } = useModal();

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const ref5 = useRef<HTMLDivElement>(null);
  const ref6 = useRef<HTMLDivElement>(null);
  const ref7 = useRef<HTMLDivElement>(null);
  const ref8 = useRef<HTMLDivElement>(null);
  const ref9 = useRef<HTMLDivElement>(null);
  const ref10 = useRef<HTMLDivElement>(null);
  const ref11 = useRef<HTMLDivElement>(null);
  const ref12 = useRef<HTMLDivElement>(null);
  const ref13 = useRef<HTMLDivElement>(null);

  const currentBreakpoint = useBreakpoint();
  const isDesktop = useMemo(() => {
    return !["xs", "sm", "md"].find((item) => item === currentBreakpoint);
  }, [currentBreakpoint]);

  return (
    <Body>
      <Container>
        <Title>
          <T>Versenyleírás</T>
        </Title>
        <Grid>
          <div>
            <ImportantTitle>
              <T>Fontos tudnivalók</T>
            </ImportantTitle>
            <StyledOl>
              <StyledLi
                onClick={(e) => {
                  e.preventDefault();
                  jumpToElement("#firstBlock");
                }}
              >
                <T>Hogyan tudok részt venni a versenyen?</T>
              </StyledLi>
              <StyledLi
                onClick={(e) => {
                  e.preventDefault();
                  jumpToElement("#secondBlock");
                }}
              >
                <T>Csapatos jelentkezés tudnivalók!</T>
              </StyledLi>
              <StyledLi
                onClick={(e) => {
                  e.preventDefault();
                  jumpToElement("#thirdBlock");
                }}
              >
                <T>MOONSHOT és STRAVA csatlakoztatása</T>
              </StyledLi>
              <StyledLi
                onClick={(e) => {
                  e.preventDefault();
                  jumpToElement("#fourthBlock");
                }}
              >
                <T>Hogy zajlik a verseny?</T>
              </StyledLi>
            </StyledOl>
          </div>
          <Card>
            <FlexBox>
              <Paragraph>
                <T>comp1</T>
              </Paragraph>
              <ExclamationMark src={exclamationMark} />
            </FlexBox>

            <Paragraph>
              <b>
                <T>comp2</T>
              </b>{" "}
              <T>comp3</T>{" "}
              <b>
                <T>STRAVA menüpontra lépve a “STRAVA APP összekapcsolása”</T>
              </b>{" "}
              <T>gombra kattintasz</T>
            </Paragraph>

            <Paragraph>
              <T>comp4</T>
            </Paragraph>
          </Card>
        </Grid>
        <HelpDiv>
          <T>comp5</T>{" "}
          <StyledLink
            href="https://support.strava.com/hc/en-us"
            target="_blank"
          >
            https://support.strava.com/hc/en-us
          </StyledLink>
        </HelpDiv>
        <Subtitle id="firstBlock">
          <T>Hogyan tudok részt venni a versenyen?</T>
        </Subtitle>
        <Grid>
          <MoonBlock src={moon1}>
            <Card $marginBottom={spacing32} ref={ref1}>
              <T>comp6</T>
            </Card>
            <SmallScreenCard $marginTop={spacing16} $marginBottom={spacing56}>
              <TitleParagraph>
                <T>A versenyre 2 módon tudsz jelentkezni:</T>
              </TitleParagraph>{" "}
              <Paragraph>
                <T>comp_7</T>
              </Paragraph>
              <Paragraph>
                <T>comp7</T>
              </Paragraph>
            </SmallScreenCard>
            <Card ref={ref3}>
              <T>comp8</T>
            </Card>
          </MoonBlock>
          <div>
            <LargeScreenCard
              $marginTop={spacing16}
              $marginBottom={spacing56}
              ref={ref2}
            >
              <TitleParagraph>
                <T>A versenyre 2 módon tudsz jelentkezni:</T>
              </TitleParagraph>{" "}
              <Paragraph>
                <T>comp_7</T>
              </Paragraph>
              <Paragraph>
                <T>comp7</T>
              </Paragraph>
            </LargeScreenCard>
            <Card $fontSize={"14px"} $marginBottom={spacing4}>
              <FlexBox>
                <div>
                  <Strong>
                    <T>comp9</T>
                  </Strong>
                  <br />
                  <T>comp10</T>
                </div>
                <ExclamationMark src={exclamationMark} />
              </FlexBox>
            </Card>
            <Card $marginBottom={spacing16} ref={ref4}>
              <T>comp11</T>
            </Card>
          </div>
        </Grid>
        <Subtitle id="secondBlock">
          <T>Csapatos jelentkezés tudnivalók!</T>
        </Subtitle>
        <Grid>
          <div>
            <Card ref={ref5}>
              <FlexBox>
                {" "}
                <TitleParagraph>
                  <T>comp12</T>
                </TitleParagraph>
                <ExclamationMark src={exclamationMark} />
              </FlexBox>{" "}
              <Paragraph>
                <T>Két lehetőséged van:</T>
              </Paragraph>
              <Paragraph>
                1.{" "}
                <Strong>
                  <T>Csapat létrehozása:</T>{" "}
                </Strong>
                <T>comp13</T>
              </Paragraph>
              <Paragraph>
                2.{" "}
                <Strong>
                  <T>Csatlakozás csapathoz:</T>
                </Strong>{" "}
                <T>comp14</T>
              </Paragraph>
            </Card>
          </div>
          <MoonBlock2
            src={moon2}
            afterChildren={
              <Card $marginTop="20rem" $marginBottom={spacing16} ref={ref6}>
                <Paragraph>
                  <T>comp15</T>
                </Paragraph>
                <Paragraph>
                  <Strong>
                    <T>comp16</T>
                  </Strong>
                </Paragraph>
              </Card>
            }
          />
        </Grid>
        <Subtitle id="thirdBlock">
          <T>comp17</T>
        </Subtitle>
        <Grid>
          <div>
            <Card $marginBottom={spacing40} ref={ref7}>
              <TitleParagraph>
                {" "}
                <T>comp18</T>
              </TitleParagraph>
              <ButtonWrapper>
                <Restricted>
                  <OrangeButton to={"/profilom/strava"}>
                    Connect with STRAVA
                  </OrangeButton>
                </Restricted>
                <Restricted guest>
                  <OrangeButton
                    onClick={() => showModal(<RegistrationModal />)}
                  >
                    Connect with STRAVA
                  </OrangeButton>
                </Restricted>
              </ButtonWrapper>
            </Card>
            <SmallScreenCard $marginTop={spacing28} $marginBottom={spacing32}>
              <Paragraph>
                <T>comp19</T>{" "}
                <Strong>
                  <T>comp20</T>
                </Strong>
              </Paragraph>
              <Paragraph>
                <T>comp21</T>
              </Paragraph>
            </SmallScreenCard>
            <Card $marginBottom={spacing32} ref={ref9}>
              <T>comp22</T>
            </Card>
            <Card ref={ref10}>
              <T>comp23</T>
              <Strong>
                <T>telefonod vagy okosórád mindig Veled legyen</T>
              </Strong>
              <T>comp24</T>
            </Card>
          </div>
          <MoonBlock3 src={moon3}>
            <LargeScreenCard
              $marginTop={spacing28}
              $marginBottom={spacing32}
              ref={ref8}
            >
              <Paragraph>
                <T>comp19</T>{" "}
                <Strong>
                  <T>comp20</T>
                </Strong>
              </Paragraph>
              <Paragraph>
                <T>comp21</T>
              </Paragraph>
            </LargeScreenCard>
          </MoonBlock3>
        </Grid>

        <ReverseGrid>
          <div>
            <LargeScreenCard
              $marginBottom={spacing32}
              $marginTop={spacing48}
              ref={ref12}
            >
              <Paragraph>
                <T>comp25</T>
              </Paragraph>
              <Paragraph>
                <T>comp26</T>
              </Paragraph>
            </LargeScreenCard>
            <ImageWrapper>
              <StyledImg />
            </ImageWrapper>
          </div>

          <div>
            <Subtitle id="fourthBlock">
              <T>Hogy zajlik a verseny?</T>
            </Subtitle>
            <Card $marginBottom={spacing32} ref={ref11}>
              <Strong>
                <T>
                  Mindig ellenőrizd le, hogy elindítottad-e a STRAVA alkalmazást
                </T>
              </Strong>{" "}
              <T>comp27</T>
            </Card>
            <SmallScreenCard $marginBottom={spacing32} $marginTop={spacing48}>
              <Paragraph>
                <T>comp25</T>
              </Paragraph>
              <Paragraph>
                <T>comp26</T>
              </Paragraph>
            </SmallScreenCard>
            <Card $marginBottom={spacing32} ref={ref13}>
              <TitleParagraph>
                <T>comp28</T>
              </TitleParagraph>
              <OrangeText>
                <T>
                  Jó versenyzést kíván a Moonshot - the Virtual Race csapata!
                </T>
              </OrangeText>
            </Card>
          </div>
        </ReverseGrid>
        {isDesktop && (
          <ConnectionContainer>
            <DivConnection fromDiv={ref1} toDiv={ref2} curvyness={1} />
            <DivConnection
              fromDiv={ref2}
              toDiv={ref3}
              curvyness={-1}
              fromX={0}
              fromY={0.7}
              toX={0.9}
              toY={-0.1}
            />
            <DivConnection
              fromDiv={ref3}
              toDiv={ref4}
              curvyness={-1}
              fromY={1}
              fromX={0.8}
            />
            <DivConnection
              fromDiv={ref4}
              toDiv={ref5}
              curvyness={1}
              fromY={1}
              fromX={0.5}
              toX={1.1}
              toY={0.1}
            />
            <DivConnection
              fromDiv={ref5}
              toDiv={ref6}
              curvyness={1}
              fromY={0.6}
              toY={-0.1}
              toX={0.2}
            />
            <DivConnection
              fromDiv={ref6}
              toDiv={ref7}
              curvyness={1}
              fromX={0.9}
              fromY={1}
              toX={1.1}
              toY={0.3}
            />
            <DivConnection
              fromDiv={ref7}
              toDiv={ref8}
              curvyness={-1}
              fromY={1}
              fromX={0.8}
              toY={0.5}
              toX={0}
            />
            <DivConnection
              fromDiv={ref8}
              toDiv={ref9}
              curvyness={1}
              fromY={1}
              fromX={0.1}
              toX={1.1}
              toY={0.8}
            />
            <DivConnection
              fromDiv={ref9}
              toDiv={ref10}
              curvyness={0.75}
              fromX={1}
              fromY={1}
              toX={1.1}
              toY={0}
            />
            <DivConnection
              fromDiv={ref10}
              toDiv={ref11}
              curvyness={-1}
              fromY={1}
              fromX={0.8}
            />
            <DivConnection
              fromDiv={ref11}
              toDiv={ref12}
              curvyness={-1}
              fromX={0}
              fromY={0.6}
              toX={1}
              toY={-0.1}
            />
            <DivConnection
              fromDiv={ref12}
              toDiv={ref13}
              curvyness={-1}
              fromY={1}
              fromX={0.8}
              toY={0.3}
              toX={0}
            />
          </ConnectionContainer>
        )}
      </Container>
    </Body>
  );
}

function MoonBlock({
  children,
  afterChildren,
  src,
  className,
}: {
  children: ReactNode;
  afterChildren?: ReactNode;
  src: string;
  className?: string;
}) {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div ref={ref}>
      {children}
      <MoonWrapper className={inView ? className : ""}>
        <MoonImg width={250} height={250} src={src} />
      </MoonWrapper>
      {afterChildren}
    </div>
  );
}

const bg = getImageUrl({ src: backgroundImage, width: 1920, height: 1080 });

const Body = styled.div`
  color: white;
  align-items: center;
  min-height: 100vh;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  overflow: auto;
  z-index: 1;

  ${Container} {
    z-index: 2;
  }

  &:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: url(${bg});
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  ${down("lg")} {
    padding-right: ${spacing4};
    padding-left: ${spacing4};
  }
`;

const Title = styled.div`
  margin-top: ${spacing24};
  text-align: center;
  font-size: 48px;
`;
const Subtitle = styled.span`
  font-size: 24px;
`;
const FlexBox = styled.div`
  display: flex;
`;
const ImportantTitle = styled.div`
  ${montserrat};
  font-size: 24px;
  font-weight: 700;
`;

const Strong = styled.span`
  font-weight: bold;
`;

const OrangeText = styled.span`
  color: ${({ theme }) => theme.orange};
  font-weight: bold;
`;

const TitleParagraph = styled.div`
  padding-bottom: ${spacing5};
  font-weight: bold;
`;
const Paragraph = styled.div``;
const OpacityDiv = styled.div`
  opacity: 0.8;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.orange};
`;

const HelpDiv = styled.div`
  ${montserrat};
  background: rgb(0, 0, 0, 0.6);
  border-radius: ${roundedLg};
  padding: ${spacing5};
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  margin-top: ${spacing2};
  margin-bottom: ${spacing28};
`;

const Card = styled.div<{
  $fontSize?: string;
  $marginTop?: string;
  $marginBottom?: string;
  $needWidth?: boolean;
}>`
  ${montserrat};
  background: rgb(0, 0, 0, 0.6);
  border-radius: ${roundedLg};
  padding: ${spacing5};
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "18px")};
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : spacing4)};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : spacing4};
  ${({ $needWidth }) => ($needWidth ? "" : "width: 400px")};

  letter-spacing: 2px;

  div + div {
    margin-top: 1rem;
  }
  ${down("lg")} {
    width: 100%;
    margin-top: ${spacing8};
    margin-bottom: ${spacing8};
  }
`;

const SmallScreenCard = styled(Card)`
  display: none;
  ${down("lg")} {
    display: block;
  }
`;

const LargeScreenCard = styled(Card)`
  ${down("lg")} {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 0px;
  justify-items: center;
  margin-top: ${spacing16};

  ${down("lg")} {
    display: flex;
    flex-direction: column;
    margin-top: ${spacing8};
  }
`;

const StyledOl = styled.ol`
  ${montserrat};
  font-size: 24px;
  width: 360px;
  margin-top: ${spacing12};
  font-weight: 600;
  padding: 0;

  li {
    list-style-position: inside;
  }
  li + li {
    margin-top: ${spacing6};
  }
`;

const ReverseGrid = styled(Grid)`
  ${down("lg")} {
    flex-direction: column-reverse;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const ImageWrapper = styled.div`
  text-align: center;
  ${down("lg")} {
    margin-bottom: ${spacing12};
  }
`;

const OrangeButton = styled(Button)`
  justify-content: center;
  background-color: ${({ theme }) => theme.orange};
  border: none;
  color: ${({ theme }) => theme.white};
  font-weight: bold;
  margin: ${spacing2};
  min-width: ${spacing40};

  &:hover {
    color: ${({ theme }) => theme.orange}!important;
    background-color: ${({ theme }) => theme.white};
  }
`;

//Pictures design

const StyledImg = styled(Logo)`
  max-width: 360px;
  ${down("lg")} {
    max-width: 250px;
  }
`;

const ExclamationMark = styled.img`
  margin-left: ${spacing4};
`;

const MoonWrapper = styled.div`
  text-align: center;
`;

const MoonImg = styled.img`
  max-width: 250px;
  margin-top: ${spacing24};
  opacity: 0.9;
`;

const MoonBlock2 = styled(MoonBlock)``;

const MoonBlock3 = styled(MoonBlock)`
  > img {
    max-width: 250px;
    opacity: 0.8;
  }
`;

const StyledLi = styled.li`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary400};
  }
`;
