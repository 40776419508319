import { animated, useSpring } from "react-spring";
import { ReactNode } from "react";
import { cardStyles } from "../common-styles";
import styled from "styled-components";
import { tabSpacing } from "./common-styles";

export function EventTab({
  $isActive,
  children,
  ...props
}: {
  $isActive?: boolean;
  children: ReactNode;
}) {
  const styles = useSpring({
    marginBottom: $isActive ? "0rem" : "1rem",
    opacity: $isActive ? 1 : 0,
    borderBottom: $isActive
      ? "0px solid rgba(255,255,255,0)"
      : "1px solid rgba(255,255,255,1)",
  });
  return (
    <TabContainer
      style={{
        marginBottom: styles.marginBottom,
        borderBottom: styles.borderBottom,
      }}
    >
      <CornerContainer style={{ opacity: styles.opacity }}>
        <Corner />
      </CornerContainer>
      <TabCard {...props} $isActive={$isActive}>
        {children}
      </TabCard>
      <RightCornerContainer style={{ opacity: styles.opacity }}>
        <RightCorner />
      </RightCornerContainer>
    </TabContainer>
  );
}

const TabCard = styled.div<{ $isActive?: boolean }>`
  ${cardStyles};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: none;

  ${({ $isActive }) =>
    $isActive &&
    `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `};

  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;

const CornerContainer = styled(animated.div)`
  position: absolute;
  width: calc(1 * ${({ theme }) => theme.roundedLg});
  height: calc(1 * ${({ theme }) => theme.roundedLg});
  bottom: -1px;
  left: calc(1px - ${({ theme }) => theme.roundedLg});
  background: black;
  z-index: 10;
`;

const RightCornerContainer = styled(animated.div)`
  position: absolute;
  width: calc(1 * ${({ theme }) => theme.roundedLg});
  height: calc(1 * ${({ theme }) => theme.roundedLg});
  bottom: -1px;
  background: black;
  z-index: 10;
  right: calc(1px - ${({ theme }) => theme.roundedLg});
`;

const Corner = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: ${({ theme }) => theme.roundedLg};
`;

const RightCorner = styled(Corner)`
  border-right: none !important;
  border-left: 1px solid white;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: ${({ theme }) => theme.roundedLg};
`;

const TabContainer = styled(animated.div)`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.roundedLg};
  & + & {
    margin-left: ${tabSpacing};
  }
  &:first-child {
    ${CornerContainer} {
      display: none;
    }
  }
  &:last-child {
    ${RightCornerContainer} {
      display: none;
    }
  }
  &:hover {
    text-decoration: none;
  }
`;
