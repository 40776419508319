export const kozteruletTypeOptions = [
  { value: "utca", label: "utca" },
  { value: "ut", label: "út" },
  { value: "ter", label: "tér" },
  { value: "sor", label: "sor" },
  { value: "korut", label: "körút" },
  { value: "ltp", label: "ltp." },
  { value: "kert", label: "kert" },
  { value: "koz", label: "köz" },
  { value: "park", label: "park" },
  { value: "sugarut", label: "sugárút" },
  { value: "setany", label: "sétány" },
  { value: "udvar", label: "udvar" },
  { value: "lejto", label: "lejtő" },
  { value: "fasor", label: "fasor" },
  { value: "egyéb", label: "egyéb" },
];
