import styled from "styled-components";
import { User, useUser } from "../../../../hooks/use-user";
import { Card, CardTitle } from "../common-styles";
import { spacing3, T } from "@litbase/alexandria";
import { Button } from "../../../../styles/common-styles";
import { useResource, useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { Team } from "../../../../types/team";
import { useMemo } from "react";
import { formatKilometer } from "../../../../utils/format-kilometer";
import { Competition } from "../../../../types/competition";

export function useMyTeammates() {
  const user = useUser();
  const [team] = useResource<Team>(
    EntityType.team,
    (user.team?.id || "") + "?include=field_csapatkapitany,field_csapattagok"
  );

  return user.team
    ? team?.included.filter((elem) => elem.type === "user--user") || []
    : [];
}

export function MyTeamCard() {
  const user = useUser();

  const [team] = useResource<Team>(
    EntityType.team,
    user.team?.id + "?include=field_csapatkapitany,field_csapattagok"
  );
  const teamMates = useMyTeammates();

  const orderedMates = useMemo(() => {
    return [...teamMates].sort(
      (a, b) =>
        b.attributes.field_osszes_megtett_tavolsag_ar -
        a.attributes.field_osszes_megtett_tavolsag_ar
    );
  }, [teamMates]);
  const placement = useMyTeamsPlacement();

  if (!team || !team.attributes) return null;

  return (
    <Body>
      <CardTitle>
        <T>Csapat információk</T>
      </CardTitle>
      <Label>
        <T>Csapatnév</T>
      </Label>
      <Value>{team.attributes.title}</Value>
      <Label>
        <T>Csapatkapitány</T>
      </Label>
      <Value>{team?.included?.[0]?.attributes.field_name}</Value>
      <Label>
        <T>Csapatkód</T>
      </Label>
      <Value>{team.attributes.field_csapat_kod}</Value>
      <Label>
        <T>Összes teljesített kilométer</T>
      </Label>
      <Value>
        {formatKilometer(
          team.attributes.field_osszes_megtett_tavolsag_ar
        ).toLocaleString("hu")}{" "}
        KM
      </Value>
      <Label>
        <T>Csapat rangja a kategóriájában</T>
      </Label>
      <Value>{placement}.</Value>
      <Label>
        <T>Legszorgalmasabb csapattag</T>
      </Label>
      <Value>
        {orderedMates?.[0] ? (
          <>
            {orderedMates?.[0]?.attributes.field_name}:{" "}
            {formatKilometer(
              orderedMates?.[0]?.attributes.field_osszes_megtett_tavolsag_ar
            )}{" "}
          </>
        ) : null}
        KM
      </Value>
    </Body>
  );
}

export function useMyTeamsPlacement(event?: Competition, team?: Team) {
  const user = useUser();

  const [teamsInCategory] = useResources(EntityType.team, {
    filters: {
      sort: "-field_osszes_megtett_tavolsag_ar",
      "[field_csapat_kategoria]":
        user?.team?.attributes?.field_csapat_kategoria,
      ...(event
        ? {
            "[field_verseny.drupal_internal__nid]":
              event.attributes.drupal_internal__nid,
          }
        : {}),
    },
  });
  return useMemo(() => {
    return (
      teamsInCategory.findIndex((elem) => elem.id === (team || user.team).id) +
      1
    );
  }, [teamsInCategory, user.team, team]);
}

const Body = styled(Card)`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const Label = styled.label`
  font-size: 18px;
  margin-top: auto;
  margin-bottom: 0;
`;

const Value = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-bottom: ${spacing3};
`;
