(function () {
  // polyfill for replaceChildren
  if (Element.prototype.replaceChildren === undefined) {
    Element.prototype.replaceChildren = function (...addNodes) {
      while (this.lastChild) {
        this.removeChild(this.lastChild);
      }

      if (addNodes && addNodes.length) {
        this.append(...addNodes);
      }
    };
  }
})();

export {};
