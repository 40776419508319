import { intervalToDuration } from "date-fns";
import { UseTranslateFunction } from "@litbase/alexandria";

export function formatInterval(date: Date, translate: UseTranslateFunction) {
  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(date),
  });
  return [
    duration.months &&
      duration.months + translate("hónap", {}, { disableHtml: true }),
    duration.weeks &&
      duration.weeks + translate("hét", {}, { disableHtml: true }),
    duration.days &&
      duration.days + translate("napok", {}, { disableHtml: true }),
    duration.hours &&
      duration.hours + translate("óra", {}, { disableHtml: true }),
  ].filter((elem) => !!elem)[0];
}
