import styled from "styled-components";
import { Card, CardTitle, Title } from "../common-styles";
import {
  Collapsible,
  CollapsibleContent,
  down,
  FontWeight,
  spacing2,
  spacing4,
  useCollapsible,
} from "@litbase/alexandria";
import React, { useMemo } from "react";
import { groupBy } from "lodash-es";
import { ChevronUp } from "@styled-icons/boxicons-regular";
import { useResources } from "../../../../hooks/use-resources";
import { EntityType } from "../../../../types/entity";
import { Interweave } from "interweave";
import { Faq } from "../../../../types/faq";

export function FaqPage() {
  const [items] = useResources<Faq>(EntityType.faq, {
    filters: { sort: "-field_kategoria" },
  });

  // Group by category
  const itemsByCategory = useMemo(
    () => groupBy(items, "attributes.field_kategoria"),
    [items]
  );

  return (
    <>
      <Title>Gyakori kérdések</Title>
      <StyledCard>
        <CardTitle>Tájékozódj a versenyről</CardTitle>
        <FaqGrid>
          {Object.keys(itemsByCategory).map((category) => (
            <FaqItemBlock title={category} items={itemsByCategory[category]} />
          ))}
        </FaqGrid>
      </StyledCard>
    </>
  );
}

export function FaqItemBlock({
  title,
  items,
}: {
  title: string;
  items: Faq[];
}) {
  return (
    <FaqItemBlockWrapper>
      <FaqBlockTitle>{title}</FaqBlockTitle>
      {items.map((item) => (
        <FaqItemRow
          title={item.attributes.field_kerdes}
          text={item.attributes.field_valasz.value}
        />
      ))}
    </FaqItemBlockWrapper>
  );
}

const FaqItemBlockWrapper = styled.div`
  margin-bottom: ${spacing2};
`;

export function FaqItemRow({ title, text }: { title: string; text: string }) {
  return (
    <StyledFaqItemRow>
      <Collapsible>
        <FaqItemTitle title={title} />
        <CollapsibleContent>
          <FaqItemText content={text} />
        </CollapsibleContent>
      </Collapsible>
    </StyledFaqItemRow>
  );
}

export function FaqItemTitle({ title }: { title: string }) {
  const { isOpen, setIsOpen } = useCollapsible();

  return (
    <StyledFaqItemTitle onClick={() => setIsOpen(!isOpen)}>
      {title}
      <FaqItemExpandArrowWrapper>
        <FaqItemExpandArrow expanded={isOpen} />
      </FaqItemExpandArrowWrapper>
    </StyledFaqItemTitle>
  );
}

const FaqGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing4};

  ${down("md")} {
    grid-template-columns: 1fr;
  }
`;

const FaqBlockTitle = styled.div`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${spacing2};
`;

const StyledFaqItemTitle = styled.div`
  font-weight: ${FontWeight.SemiBold};
  display: flex;
  align-items: center;
`;

const FaqItemExpandArrow = styled(ChevronUp)<{ expanded: boolean }>`
  transition: transform 250ms ease-in-out;
  transform: rotateX(0deg);

  height: 2rem !important;

  ${({ expanded }) =>
    expanded &&
    `
    transform: rotateX(180deg);  
  `}
`;

const FaqItemExpandArrowWrapper = styled.span`
  flex: 1;
  display: inline-flex;
  justify-content: flex-end;
`;

const FaqItemText = styled(Interweave)`
  padding-top: ${spacing2};
`;

const StyledCard = styled(Card)`
  li {
    font-weight: 400;
    font-size: 16px;
    font-family: ${({ theme }) => theme.headingsFontFamily};
  }
  ol {
    margin-left: 0;
    padding-left: ${spacing4};
  }
  p {
    text-align: start;
    margin-top: ${spacing2};
  }

  padding: ${spacing2};
`;

const StyledFaqItemRow = styled(StyledCard)`
  & + & {
    margin-top: ${spacing4};
  }
`;
