import { down } from "@litbase/alexandria";
import styled from "styled-components";

export function Slider({ percent }: { percent: number }) {
  return (
    <>
      <Body style={{ width: percent + "%" }}>
        <Circle />
      </Body>
    </>
  );
}

const Circle = styled.div`
  position: absolute;
  right: 0;
  background: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const Body = styled.div`
  background: white;
  width: 100%;
  height: 3px;
  position: relative;
  display: flex;
  align-items: center;
  ${down("md")} {
    grid-column: span 2;
  }
`;
