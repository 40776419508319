import { down, spacing6, T } from "@litbase/alexandria";
import { Card, CardTitle, Title } from "../common-styles";
import styled from "styled-components";
import { NewTeamCard } from "./new-team-card";
import { useUser } from "../../../../hooks/use-user";
import { MyTeamCard } from "./my-team-card";
import { MyTeammatesCard } from "./my-teammates-card";
import { maxBy } from "lodash-es";
import { getEndsAt } from "../../../../types/competition";
import { Team } from "../../../../types/team";
import { Button } from "../../../../styles/common-styles";

export function MyTeam() {
  const user = useUser();
  const lastTeam: Team | null = user.allTeams
    ? maxBy(user.allTeams, (team) => getEndsAt(team.competition))
    : null;

  const showCarryOverTeamOption =
    !user.team &&
    !!lastTeam &&
    lastTeam.relationships.field_csapatkapitany.data.id === user.id;

  return (
    <Body>
      <Title>
        <T>Csapatom</T>
      </Title>
      {!user.team ? (
        <>
          <NewTeamCard />
        </>
      ) : (
        <>
          <MyTeamCard />
          <MyTeammatesCard />
        </>
      )}
      {showCarryOverTeamOption && <CarryOverTeamCard />}
    </Body>
  );
}

function CarryOverTeamCard() {
  return (
    <Card style={{ height: "fit-content" }}>
      <CardTitle>
        <T>Korábbi csapat megtartása</T>
      </CardTitle>
      <P>
        <T>
          Korábbi csapatodat megtarthatod a további versenyek folyamán, így év
          végén egységesítve láthatod a csapatod által elért eredményt.
        </T>
      </P>
      <P>
        {" "}
        <T>A csapat továbbvitelének díja</T>{" "}
        <span style={{ color: "white" }}>1.000 Ft</span>.
      </P>
      <P>
        {" "}
        <T>
          Ezt az opciót választva csak csapatod neve és csapatkódja marad meg, a
          csapat korábbi tagjainak újra csatlakozniuk kell a csapatkód
          megadásával.
        </T>
      </P>
      <Button style={{ margin: "0 auto" }}>
        <T>Csapat megtartása</T>
      </Button>
    </Card>
  );
}

const P = styled.p`
  text-align: start;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
  grid-column-gap: ${spacing6};
  height: 100%;
  ${Title} {
    grid-column: span 2;
  }
  > *:nth-child(2):first-child {
    width: 100%;
  }
  ${down("md")} {
    display: flex;
    flex-direction: column;
    > * + * {
      margin-top: ${spacing6};
    }
  }
`;
