import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { spacing4 } from "@litbase/alexandria";

export function FormErrorDisplay() {
  return (
    <ErrorMessage
      name="submit"
      render={({ message }) => (
        <StyledErrorMessage>{message}</StyledErrorMessage>
      )}
    />
  );
}

const StyledErrorMessage = styled.div`
  margin: 0 0 ${spacing4};
  color: ${({ theme }) => theme.orange};
`;
