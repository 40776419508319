import styled from "styled-components";
import { down, spacing6, up } from "@litbase/alexandria";
import { verticalDown } from "../styles/theme-types";

export const xxlWidth = "1120px";
export const xlWidth = "1020px";
export const lgWidth = "840px";
export const mdWidth = "600px";
export const smWidth = "480px";
export const xsWidth = "180px";

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin: auto auto;
  display: flex;
  flex-direction: column;

  ${down("sm")} {
    width: 100%;
  }

  ${up("md")} {
    width: ${mdWidth};
    padding: ${spacing6};
  }

  ${up("lg")} {
    width: ${lgWidth};
    padding: ${spacing6};
  }

  ${up("xl")} {
    width: ${xlWidth};
  }
  ${up("xxl")} {
    width: ${xxlWidth};
  }
`;

export const navbarHeight = "75px";

export const Page = styled.div`
  padding-top: ${navbarHeight};
  ${verticalDown("xl")} {
    padding-top: 75px;
  }
`;
