import { ComponentProps, useEffect, useRef } from "react";
import autoAnimate from "@formkit/auto-animate";

export function AutoAnimatedDiv(props: ComponentProps<"div">) {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current && autoAnimate(ref.current);
  }, [ref]);
  return <div ref={ref} {...props} />;
}
